import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

type TBody = {
  isPickupPoint: boolean;
};

function setIsPickupPoint(isPickupPoint: boolean, shoppingCartId: string): Promise<void> {
  return HttpClient.put(`shoppingcart/${shoppingCartId}/pickuppoint`, { isPickupPointSelected: isPickupPoint });
}

export function useSetIsPickupPoint() {
  const queryClient = useQueryClient();
  const { shoppingCartId } = useCustomerContext();

  return useMutation<void, ApiError, TBody>(
    'pickupPointShipping',
    body => setIsPickupPoint(body?.isPickupPoint, shoppingCartId),
    {
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);
      },
    },
  );
}

import { createContext, FC, ReactNode, useContext, useEffect } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useStoreType } from '@hooks';
import { useHistory, useLocation } from 'react-router-dom';

import { useCurrentCompany } from '../_hooks';
import { TCompany, TCompanyDetails } from '../_models';
import { useGetCompanies, useGetCompanyDetails } from '../_queries';

type TCompanyContext = {
  companies: TCompany[];
  hasFetchedCompanies: boolean;
  isGetCompaniesLoading: boolean;
  isGetSelectedCompanyLoading: boolean;
  selectedCompany?: TCompanyDetails;
  setCurrentCompany: (id: string) => void;
};

export const CompanyContext = createContext<TCompanyContext>({
  companies: [],
  hasFetchedCompanies: false,
  isGetCompaniesLoading: false,
  isGetSelectedCompanyLoading: false,
  setCurrentCompany: () => {},
});

export const useCompanyContext = () => useContext(CompanyContext);

type TProps = {
  children: ReactNode;
};

const whiteListedPathNames = ['register-company', 'pick-company'];

export const CompanyContextProvider: FC<TProps> = ({ children }) => {
  const { isB2B } = useStoreType();
  const { oidcUser } = useReactOidc();
  const { data: companies, isLoading: isGetCompaniesLoading, isFetched: hasFetchedCompanies } = useGetCompanies();
  const { companyId, setCurrentCompany } = useCurrentCompany();
  const { data: selectedCompany, isLoading: isGetSelectedCompanyLoading } = useGetCompanyDetails(companyId?.id);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isB2B) return;
    if (!oidcUser) return;
    if (!companies) return;
    if (!hasFetchedCompanies) return;
    if (whiteListedPathNames.some(whiteListedPathName => pathname.includes(whiteListedPathName))) return;

    switch (true) {
      case companies.length === 1:
        setCurrentCompany(companies[0].id);
        break;

      case companies.length > 1 && !companyId:
        history.push('/pick-company');
        break;
    }
  }, [isB2B, hasFetchedCompanies, companies]);

  return (
    <CompanyContext.Provider
      value={{
        companies,
        hasFetchedCompanies,
        isGetCompaniesLoading,
        isGetSelectedCompanyLoading,
        selectedCompany,
        setCurrentCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { TCompanyPayload, TRegisterCompanyResponse } from '../_models';

type TApiError = Omit<ApiError, 'error'> & { error: Record<string, string> };

function registerCompany(body: TCompanyPayload) {
  return HttpClient.post<TRegisterCompanyResponse>(`b2b/companies/register/contact-person`, body);
}

export function useRegisterCompanyQuery(onSuccess?: (response) => void) {
  const queryClient = useQueryClient();

  return useMutation<TRegisterCompanyResponse, TApiError, TCompanyPayload>('registerCompany', body => registerCompany(body), {
    onSuccess: (data: TRegisterCompanyResponse) => {
      onSuccess?.(data);
      queryClient.invalidateQueries('companies');
    },
  });
}

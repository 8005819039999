import { FC } from 'react';

import { Button } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './shoppingCartLine.scss';

type TProps = {
  description?: string;
  emptyLabel?: string;
  handleDelete?: () => void;
  highlight?: boolean;
  label: string;
  value: number;
  withMinus?: boolean;
};

const ShoppingCartLine: FC<TProps> = ({ label, value, emptyLabel, highlight, description, withMinus, handleDelete }) => {
  const { t } = useTranslation();

  if (!value && !emptyLabel) {
    return null;
  }

  return (
    <div className={classnames('shopping-cart-line', { highlight })}>
      <div>
        <span className="shopping-cart-line__label">{label}</span>
        {description && <span className="shopping-cart-line__description">{description}</span>}
        {handleDelete ? (
          <Button
            hideLabel
            icon="Trash"
            onClick={() => {
              handleDelete();
            }}
            theme="plain-link"
          >
            {t('SHARED.BUTTONS.DELETE')}
          </Button>
        ) : null}
      </div>
      <span className="shopping-cart-line__value">
        {value ? (withMinus ? '- ' + formatPrice(value) : formatPrice(value)) : emptyLabel}
      </span>
    </div>
  );
};

export default ShoppingCartLine;

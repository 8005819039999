import { FC } from 'react';

import { Spinner } from '@shared';
import { convertUtcToLocal, formatISOString } from '@utils/dateHelpers';
import { formatPrice } from '@utils/formatHelpers';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { TOrder, getPaymentMethodLabel } from '../../_models/order';
import { getOrderStatusColor, getOrderStatusLabel } from '../../_util/orderHelpers';

import OrderStatusIndicator from './OrderStatusIndicator';

import './mobileOrdersTable.scss';

type TProps = {
  isLoading?: boolean;
  orders: TOrder[];
};

const MobileOrdersOverview: FC<TProps> = ({ orders, isLoading }) => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const convertDate = (dateString: string) => {
    const localDate = convertUtcToLocal(dateString);
    return formatISOString(localDate.toISOString());
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      {Array.isArray(orders) && orders?.length > 0 ? (
        <ul className="mobile-orders-list">
          {orders.map(order => (
            <li key={order.id}>
              <Link to={`${path}/${order.id}`}>
                <div className="mobile-orders-list__order-number">{order?.orderNumber}</div>
                <span className="mobile-orders-list__date">{convertDate(order?.dateOfOrder)}</span>
                <span>{formatPrice(order?.price)}</span>
                <span>{order.customerName}</span>
                <span>{getPaymentMethodLabel(order.paymentMethod)}</span>
                <span className="mobile-orders-list__status">
                  <OrderStatusIndicator className="mobile-orders-list__status-icon" color={getOrderStatusColor(order?.status)} />
                  {getOrderStatusLabel(order?.status)}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="mobile-orders-list">
          <li className="mobile-orders-list__empty">
            <div>{t('CUSTOMER_PORTAL.ORDERS.EMPTY')}</div>
          </li>
        </ul>
      )}
    </>
  );
};

export default MobileOrdersOverview;

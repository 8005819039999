import { useCallback } from 'react';

import { useStoreType } from '@hooks';
import { getBaseUrl } from '@utils/linkHelpers';
import { renameProperty } from '@utils/objectHelpers';
import { useTranslation } from 'react-i18next';

import { TB2CInvoiceDetails, TInvoiceDetails } from '../../checkout/_models/invoice';
import { TCustomer } from '../../general/_models/customer';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { TPayment } from '../_models/payment';
import { getBrowserInfo } from '../_util/getBrowserInfo';

import useCheckoutUser from './useCheckoutUser';

const usePaymentData = () => {
  const { isB2B, businessPathName } = useStoreType();
  const { isGuest, containsPhysicalProducts, containsDigitalProducts, shoppingCartId } = useCustomerContext();
  const { customer, invoiceDetails, selectedCompany } = useCheckoutUser();
  const { i18n } = useTranslation();
  const { state } = useCheckoutContext();

  /* Since the backend expects a `companyName` for both B2C and B2B, we manually add the data to the object.
      This is not ideal, so should be refactored when possible */
  const addCompanyName = useCallback(
    (invoiceDetails: TInvoiceDetails) => {
      if ((invoiceDetails as TB2CInvoiceDetails)?.companyName) return invoiceDetails;

      return {
        ...invoiceDetails,
        companyName: selectedCompany.name,
      };
    },
    [selectedCompany],
  );

  const getPayment = useCallback(
    (data): TPayment => {
      const paymentRequest: TPayment = {
        adyen: { paymentMethod: JSON.stringify(data.paymentMethod), ...getBrowserInfo() },
        orderInformation: {
          companyId: selectedCompany?.id || null,
          contactDetailInformation: isGuest ? state.details : undefined,
          languageCode: i18n.language,
          paymentMethod: data.paymentMethod.brand ?? data.paymentMethod.type,
          poNumber: state?.poNumber,
          returnUrl: `${getBaseUrl()}${businessPathName}/checkout/callback`,

          // physical shipping
          ...(containsPhysicalProducts
            ? customer
              ? { physicalShippingAddressId: state.physicalAddress?.id }
              : { physicalShippingInformation: renameProperty('country', 'countryIsoCode', state.physicalAddress) }
            : {}),

          // digital shipping
          ...(containsDigitalProducts
            ? customer
              ? { digitalShippingAddressId: state.digitalAddress?.id }
              : { digitalShippingAddress: state.digitalAddress.email }
            : {}),

          // Invoice details
          ...(state.wantInvoice ||
          (state.invoiceDetails as TB2CInvoiceDetails).wantInvoice ||
          (customer as TCustomer)?.invoiceDetails?.wantInvoice ||
          isB2B
            ? isGuest
              ? {
                  invoiceDetails: renameProperty('countryIsoCode', 'country', state.invoiceDetails),
                  wantInvoice: (state.invoiceDetails as TB2CInvoiceDetails).wantInvoice || state.wantInvoice || false,
                }
              : {
                  invoiceDetails: {
                    vat: selectedCompany ? selectedCompany.enterpriseNumber || selectedCompany.vatNumber : invoiceDetails.vat,
                    ...renameProperty('countryIsoCode', 'country', addCompanyName(invoiceDetails)),
                  },
                  wantInvoice: isB2B ? true : (customer as TCustomer)?.invoiceDetails?.wantInvoice ?? false,
                }
            : {}),
        },
        shoppingCartId,
      };

      return paymentRequest;
    },
    [
      addCompanyName,
      businessPathName,
      containsDigitalProducts,
      containsPhysicalProducts,
      customer,
      i18n.language,
      invoiceDetails,
      isB2B,
      isGuest,
      selectedCompany,
      shoppingCartId,
      state,
    ],
  );

  return { getPayment };
};

export default usePaymentData;

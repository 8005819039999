import { FC } from 'react';

import Markdown from '@shared/markdown/markdown';
type TProps = {
  description: string;
};

const ProductDescription: FC<TProps> = ({ description }) => {
  if (!description) return null;

  return (
    <section className="product__description">
      <Markdown>{description}</Markdown>
    </section>
  );
};

export default ProductDescription;

import { ApiError } from '@http';
import { useQuery } from 'react-query';

type TTokenResponse = {
  '.expires': string;
  '.issued': string;
  access_token: string;
  expires_in: number;
  fullName: string;
  locale: string;
  token_type: string;
  userID: string;
  userName: string;
};

const getBMEToken = async (): Promise<TTokenResponse> => {
  const body = new URLSearchParams({
    grant_type: 'password',
    password: 'if9MSX7lB',
    username: 'KinepolisAPI',
  });

  const response = await fetch('https://production.sb.hf62.bme.be/auth/token', {
    body: body,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });

  const data = await response?.json();
  return data;
};

export function useGetBMEToken() {
  return useQuery<TTokenResponse, ApiError>(['pickupPointToken'], getBMEToken);
}

import { FC, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useResponsive } from '@hooks';
import { Button, CheckboxGroup, Radio, Spinner } from '@shared';
import { useTranslation } from 'react-i18next';

import { useCompanyContext } from '../../company/_context/CompanyContext';
import RegisterCompany from '../../company/registerCompany/RegisterCompany';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

import usePickCompanyForm from './usePickCompanyForm';

import './pickCompany.scss';

const PickCompany: FC = () => {
  const { t } = useTranslation();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const { isGuest } = useCustomerContext();
  const { login } = useReactOidc();
  const {
    values,
    setAttribute,
    submit,
    hasValidationErrors,
    validationErrors,
    isDirty,
    isLoading: isPickCompanyLoading,
  } = usePickCompanyForm();
  const { companies, isGetCompaniesLoading, hasFetchedCompanies } = useCompanyContext();

  function handleLogin() {
    setLoginLoading(true);
    login();
  }

  if (isGuest)
    return (
      <div className="guest">
        <Button
          className="guest__login-button"
          hideLabel={isMobile}
          icon="Person"
          loading={loginLoading}
          onClick={handleLogin}
          theme="secondary"
        >
          {t('SHARED.GENERAL.LOGIN')}
        </Button>
      </div>
    );
  if (!hasFetchedCompanies || isGetCompaniesLoading || isPickCompanyLoading)
    return (
      <div className="pick-company-loading">
        <Spinner />
      </div>
    );

  if (companies?.length == 0 || companies?.length == undefined) return <RegisterCompany />;

  return (
    <div className="pick-company">
      <div className="pick-company__content">
        <h1>{t('PICK_COMPANY.TITLE')}</h1>

        {companies?.length === 1 ? (
          <p>{t('PICK_COMPANY.SINGLE_LINKED')}</p>
        ) : (
          <>
            <p>{t('PICK_COMPANY.MULTIPLE_LINKED')}</p>
            <p>{t('PICK_COMPANY.BUYING_TODAY')}</p>
          </>
        )}
        <form onSubmit={submit}>
          <CheckboxGroup className="pick-company-list" name="companyId" validation={validationErrors?.companyId}>
            {companies?.map(company => (
              <Radio
                checked={company?.id === values?.companyId}
                className="pick-company-list-item"
                key={company?.id}
                name="companyId"
                onChange={(_, name) => {
                  setAttribute(company?.id, name);
                }}
              >
                <span className="pick-company-list-item__name">{company?.name}</span>
                <span className="pick-company-list-item__admin">
                  {`(${company?.isAdmin ? t('SHARED.GENERAL.IS_ADMIN') : t('SHARED.GENERAL.IS_NOT_ADMIN')})`}
                </span>
              </Radio>
            ))}
          </CheckboxGroup>
          <Button disabled={!isDirty && hasValidationErrors} type="submit">
            {t('SHARED.BUTTONS.CONTINUE')}
          </Button>
        </form>
        <hr />
        <p className="pick-company__content__new-company">{t('PICK_COMPANY.NEW_COMPANY')}</p>
        <Button href="register-company" theme="secondary">
          {t('PICK_COMPANY.REGISTER')}
        </Button>
      </div>
    </div>
  );
};

export default PickCompany;

import { FC } from 'react';

import { Button } from '@shared';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TLanguage } from '../../general/_models/general';
import './languagePicker.scss';

type TProps = {
  languages: TLanguage[];
};

const LanguagePicker: FC<TProps> = ({ languages }) => {
  const { t } = useTranslation();

  if (!languages) return null;

  return (
    <div className="language-picker">
      <h2 className="visually-hidden">{t('FOOTER.LANGUAGE.TITLE')}</h2>
      <ul>
        {languages.map(language => (
          <li key={language.code}>
            <Button
              onClick={() => {
                i18next.changeLanguage(language.code);
                localStorage.setItem('preferredLanguage', language.code);
              }}
              theme="plain-link"
            >
              {language.name}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default LanguagePicker;

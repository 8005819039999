import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useStoreType } from '@hooks';
import { ApiError, HttpClient } from '@http';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TCompany } from '../_models';

function getCompanies(): Promise<TCompany[]> {
  return HttpClient.get(`b2b/companies`);
}

export function useGetCompanies() {
  const history = useHistory();
  const { isB2B } = useStoreType();
  const { oidcUser } = useReactOidc();

  return useQuery<TCompany[], ApiError>(['companies'], () => getCompanies(), {
    enabled: isB2B && !!oidcUser,
    onError: error => {
      if (error?.statusCode === 403) return;
      if (error?.statusCode === 404) {
        history.push('/register-company');
        return;
      }
    },
  });
}

import { useRef, useEffect } from 'react';

const createRootElement = id => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  rootContainer.classList.add(id);
  return rootContainer;
};

const addRootElement = rootElem => {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
};

const usePortal = (id: string) => {
  const rootElemRef = useRef<HTMLElement>();

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`);
      const parentElem = existingParent || createRootElement(id);

      if (!existingParent) {
        addRootElement(parentElem);
      }

      parentElem.appendChild(rootElemRef.current);

      return function removeElement() {
        rootElemRef.current.remove();
        if (!parentElem.childElementCount) {
          parentElem.remove();
        }
      };
    },
    [id],
  );

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
};

export default usePortal;

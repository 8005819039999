import { FC, useEffect, useId } from 'react';

import { useInputError, useResponsive } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Dropdown, InputField } from '@shared';
import { countries } from '@utils/countries';
import { useTranslation } from 'react-i18next';

import { TPhoneNumber } from '../../../checkout/_models/address';
import InputWrapper from '../InputWrapper';
import './Phone.scss';

type TProps = {
  countryIsoCode?: string;
  isMobilePhoneNumber?: boolean;
  required?: boolean;
  setAttribute: (value: unknown, name: string) => void;
  validation?: FormValidationErrors<TPhoneNumber>;
  values: TPhoneNumber;
};

const Phone: FC<TProps> = ({
  countryIsoCode,
  isMobilePhoneNumber = false,
  required = false,
  validation,
  setAttribute,
  values,
}) => {
  const { setDirty, showError } = useInputError(isMobilePhoneNumber ? validation?.mobilePhoneNumber : validation?.phoneNumber);
  const { t } = useTranslation();
  const phoneId = useId();
  const { isSmallerThan } = useResponsive();
  const isTablet = isSmallerThan('tablet');

  const getNumberType = isMobilePhoneNumber ? 'mobilePhoneNumber' : 'phoneNumber';

  const getValidation = validation?.[getNumberType];
  const getValuePrefix = values?.[`${getNumberType}Prefix`];
  const getValue = values?.[getNumberType];

  const prefillPhonePrefix = () => {
    if (countryIsoCode && countryIsoCode.trim() !== '') {
      const foundCountry = countries.find(country => country.value === countryIsoCode);
      if (foundCountry && foundCountry.phonePrefix !== values?.[getValuePrefix]) {
        setAttribute(foundCountry.phonePrefix, `${getNumberType}Prefix`);
      }
    }
  };

  useEffect(() => {
    prefillPhonePrefix();
  }, [countryIsoCode]);

  return (
    <InputWrapper
      className="input--phone"
      id={phoneId}
      label={t(isMobilePhoneNumber ? 'CHECKOUT.ADDRESS.MOBILE_PHONE_NUMBER' : 'CHECKOUT.ADDRESS.PHONE_NUMBER')}
      name="phone"
      required={required}
      showError={showError}
      validation={required && getValidation}
    >
      <div className="input--phone__wrapper">
        <Dropdown
          className="input--phone__prefix"
          hasNoEmtyOption
          name={`${getNumberType}Prefix`}
          onChange={setAttribute}
          options={countries.map(country => ({ label: country.phonePrefix, value: country.phonePrefix }))}
          value={getValuePrefix}
        />
        <InputField
          autoComplete={getNumberType == 'phoneNumber' ? 'tel' : 'tel-mobile'}
          className={isTablet ? 'input--phone__number' : 'input--phone__number half-size'}
          id={phoneId}
          name={getNumberType}
          normalize={value => (value.startsWith('0') ? value.substring(1) : value.replace(/[^\d]/, ''))}
          onChange={(...props) => {
            setDirty();
            setAttribute(...props);
          }}
          pattern="[0-9]+"
          value={getValue}
        />
      </div>
    </InputWrapper>
  );
};

export default Phone;

import { FC } from 'react';

import { Icon, Image } from '@shared';
import { getAdyenIcons } from '@utils/iconHelpers';
import { useTranslation } from 'react-i18next';

import { useGetPaymentMethods } from '../../../../checkout/_queries';

const PaymentMethods: FC = () => {
  const { t } = useTranslation();
  const { data: paymentMethods } = useGetPaymentMethods();
  const icons = getAdyenIcons(paymentMethods);

  return (
    <div className="footer__payment-methods">
      <h2 className="h5">
        <Icon name="Lock" size={2.4} />
        {t('FOOTER.PAYMENT.TITLE')}
      </h2>
      {icons && (
        <ul>
          {icons?.reverse()?.map(({ src, label }) => (
            <li key={`footer-icon-${src}`}>
              <Image
                alt={t(label)}
                className={`footer__payment-methods__icon ${src.toLocaleLowerCase().includes('googlepay') ? 'googlepay' : ''}`}
                src={src}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PaymentMethods;

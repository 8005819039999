import { TPaymentMethodResponse } from 'checkout/_models/payment';

import { env } from '../env';

type TPaymentIcon = {
  /** alt text */
  label: string;
  /** absolute path to the image */
  src: string;
};

export function getAdyenIcons(response: TPaymentMethodResponse): TPaymentIcon[] {
  if (!response) return;

  const iconBase = env.REACT_APP_ICON_URL;
  const icons = response.paymentMethods.reduce((icons, paymentMethod) => {
    if (!paymentMethod?.brands) {
      return [...icons, paymentMethod.type];
    }

    return [...icons, ...paymentMethod.brands];
  }, [] as string[]);

  return icons
    .filter((icon, index) => icons.indexOf(icon) === index)
    .map((icon): TPaymentIcon => {
      let translationIdentifier = '';
      switch (icon.toLowerCase()) {
        case 'bcmc':
          translationIdentifier = 'Bancontact';
          break;

        case 'visa':
          translationIdentifier = 'Visa';
          break;

        case 'mc':
          translationIdentifier = 'Mastercard';
          break;

        case 'bcmc_mobile':
          translationIdentifier = 'Payconiq';
          break;

        case 'ideal':
          translationIdentifier = 'Ideal';
          break;

        case '"googlepay"':
          translationIdentifier = 'GooglePay';
          break;
      }

      return {
        label: translationIdentifier ? `FOOTER.PAYMENT.${translationIdentifier.toUpperCase()}` : '',
        src: `${iconBase}/${icon}.svg`,
      };
    })
    .filter(icon => !!icon);
}

import { FC } from 'react';

import { useForm, useResponsive } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, InputField } from '@shared';
import { formValidator } from '@utils/formValidation';
import { useTranslation } from 'react-i18next';

import { useCheckoutContext } from '../_context/CheckoutContext';
import { setDetails, setShippingDetails } from '../_context/checkoutActions';
import { TDetails } from '../_models/details';

import './details.scss';

type TProps = {
  onSubmit: () => void;
};

const Details: FC<TProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const { dispatch, state } = useCheckoutContext();
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');

  function validateForm(values: TDetails): FormValidationErrors<TDetails> {
    return {
      email: validator.email(values.email),
      firstName: validator.required(values.firstName),
      lastName: validator.required(values.lastName),
    };
  }

  const submitForm = (values: TDetails) => {
    dispatch(
      setShippingDetails({
        digitalAddress: { ...state?.digitalAddress, email: values?.email || '' },
        invoiceDetails: {
          ...state?.invoiceDetails,
          companyName: values?.firstName + ' ' + values?.lastName || '',
          email: values?.email || '',
        },
        physicalAddress: {
          ...state?.physicalAddress,
          name: values?.firstName + ' ' + values?.lastName || '',
        },
      }),
    );
    dispatch(setDetails(values));
    onSubmit();
  };

  const { submit, values, setAttribute, validationErrors } = useForm({
    initialForm: state.details,
    submitForm,
    validateForm,
  });

  return (
    <div className="order-details">
      <form className="order-details__form" onSubmit={submit}>
        <fieldset>
          <InputField
            autoComplete="given-name"
            label={t('CHECKOUT.YOUR_DETAILS.FIRST_NAME')}
            name="firstName"
            onChange={setAttribute}
            required
            validation={validationErrors.firstName}
            value={values.firstName}
          />
          <InputField
            autoComplete="family-name"
            label={t('CHECKOUT.YOUR_DETAILS.LAST_NAME')}
            name="lastName"
            onChange={setAttribute}
            required
            validation={validationErrors.lastName}
            value={values.lastName}
          />
          <InputField
            autoComplete="email"
            label={t('CHECKOUT.YOUR_DETAILS.EMAIL_ADDRESS')}
            name="email"
            onChange={setAttribute}
            required
            validation={validationErrors.email}
            value={values.email}
          />
        </fieldset>

        <div className={isMobile ? 'actions actions--stacked' : 'actions'}>
          <Button className="action__confirm" icon="ChevronRight" iconPosition="right" type="submit">
            {t('SHARED.BUTTONS.CONTINUE')}
          </Button>
          <Button className="action__back" href="../cart" icon="ChevronLeft" theme="light-transparent">
            {t('CHECKOUT.EDIT_SHOPPING_CART')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Details;

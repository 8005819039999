import { TDataLayerObject } from 'googleAnalytics/dataLayer';

declare global {
  interface Window {
    //Used for Google analytics
    dataLayer: TDataLayerObject[];
    env: Record<keyof EnvType, string>;
    initGoogleMaps: () => void;
  }
}

type EnvType = {
  NODE_ENV: string;
  REACT_APP_ADYEN_ENVIRONMENT: string;
  REACT_APP_ADYEN_KEY: string;
  REACT_APP_API_HOST: string;
  REACT_APP_AUTHORITY: string;
  REACT_APP_BRAND_NAME: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_COOKIE_CONSENT: string;
  REACT_APP_FLAG_ENABLE_CUSTOMER_PORTAL?: string;
  REACT_APP_GOOGLE_PLACES_API_KEY: string;
  REACT_APP_HOTJAR_SITE_ID: string;
  REACT_APP_ICON_URL: string;
  REACT_APP_INVOICES_API: string;
  REACT_APP_RESET_PASSWORD: string;
  REACT_APP_SCOPE: string;
  REACT_APP_SELIGENT_DEFAULT_SCRIPT_URL: string;
  REACT_APP_SELIGENT_TRACKING_SCRIPT_URL: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_STORE_URL: string;
  REACT_APP_VOUCHERS_API: string;
};
export const env: EnvType = { ...process.env, ...window.env };

import { useState, useEffect, Dispatch, SetStateAction, FormEvent } from 'react';

import { trimObjectValues } from '@utils/objectHelpers';
import { useTranslation } from 'react-i18next';

import { ApiError, ValidationError } from '../_http';
import { TValidatorResponse } from '../_utils/formValidation';
import { isEmptyObject } from '../_utils/objectHelpers';

import useToggle from './useToggle';

/**
 * FormValidationErrors type explanation:
 * 1. We check to see if the value of property Key is a primitive, if it is, we just require a validator response (IValidatorResponse).
 * 2. We check if the value of property Key is an array, if it is, we proceed to 3, else to 5
 * 3. We check if the Type of the element of the array, using infer, is a Primitive.
 *    If the value is not a Primitive, proceed to 4, otherwise, we just require a list of validator responses (IValidatorResponse[]).
 * 4. If the Array is not a primitive, we use the type we extracted with infer and require an array of FormValidationErrors<InferredArrayType>.
 * 5. If the array is not a primitive, and not an array, it's an object, so we just recursively use FormValidationErrors with the given type.
 */
type Primitive = string | number | boolean;
export type FormValidationErrors<TForm = Record<string, unknown>> = {
  [Key in keyof TForm]?: TForm[Key] extends Primitive // 1.
    ? TValidatorResponse
    : TForm[Key] extends Array<infer TArray> // 2.
    ? TArray extends Primitive // 3.
      ? TValidatorResponse[]
      : Array<FormValidationErrors<TArray>> // 4
    : FormValidationErrors<TForm[Key]>; // 5
};

export type SubmitFormFunction<TForm> = (values: TForm, setFormValues: (values: TForm) => void) => void | Promise<void>;
type ValidateFormFunction<TForm, TFormErrors> = (
  values: TForm,
) => FormValidationErrors<TFormErrors> | Promise<FormValidationErrors<TFormErrors>>;

type Params<TForm, TFormErrors> = {
  error?: ApiError;
  initialForm: TForm;
  isInitialFormFilled?: boolean;
  submitForm: SubmitFormFunction<TForm>;
  validateForm: ValidateFormFunction<TForm, TFormErrors>;
};

type Response<TForm, TFormErrors> = {
  clearValues: () => void;
  hasValidationErrors: boolean;
  isDirty: boolean;
  isLoading: boolean;
  setAttribute: (value: unknown, name: string) => void;
  setFormValues: Dispatch<SetStateAction<TForm>>;
  setIsDirty: (nextValue?: unknown) => void;
  setValidationErrorsManually: (errors: FormValidationErrors<TFormErrors>) => void;
  submit: (event: FormEvent) => Promise<boolean>;
  submitWithParams: (event: FormEvent, params: Partial<Params<TForm, TFormErrors>>) => Promise<boolean>;
  validationErrors: FormValidationErrors<TFormErrors>;
  values: TForm;
};

export type TFormHook<TForm, TFormErrors = TForm> = Response<TForm, TFormErrors>;

function mapToFormValidationErrors<TForm>(error: ApiError): FormValidationErrors<TForm> {
  const { t } = useTranslation();

  const mapError = (validationError: ValidationError) => {
    if (validationError.children.length > 0) {
      return validationError.children.reduce((acc, child) => ({ ...acc, [child.property]: { ...mapError(child) } }), {});
    }
    let message: string = t('ERRORS.VALIDATION.INVALID');
    if (validationError.constraints?.isNotEmpty) message = t('ERRORS.VALIDATION.REQUIRED');
    return { isValid: false, message };
  };
  return Object.keys(error.validationErrors).reduce((acc, key) => {
    return { ...acc, [key]: { ...mapError(error.validationErrors[key]) } };
  }, {});
}

function isValidatorResponse(object: unknown): object is TValidatorResponse {
  return Object.keys(object).includes('isValid');
}

export function hasValidationErrors(errors: FormValidationErrors): boolean {
  if (isEmptyObject(errors)) return false;
  if (Array.isArray(errors)) return errors.some(hasValidationErrors);
  if (typeof errors === 'object') {
    if (isValidatorResponse(errors)) return !errors.isValid;
    return Object.keys(errors).some(key => hasValidationErrors(errors[key]));
  }
  return false;
}

function useForm<TForm, TFormErrors = TForm>(params: Params<TForm, TFormErrors>): Response<TForm, TFormErrors> {
  const { error, initialForm, submitForm, validateForm, isInitialFormFilled = true } = params;
  const [values, setFormValues] = useState<TForm>(initialForm);
  const [validationErrors, setValidationErrors] = useState<FormValidationErrors<TFormErrors>>({});
  const [isDirty, setIsDirty] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);

  useEffect(() => {
    setFormValues(initialForm);
  }, []);

  const submit = async (
    event: FormEvent,
    submitFunction: SubmitFormFunction<TForm> = submitForm,
    validateFunction: ValidateFormFunction<TForm, TFormErrors> = validateForm,
  ): Promise<boolean> => {
    setIsLoading(true);
    event.preventDefault();

    const trimmedValues = trimObjectValues(values);
    const errors = await validateFunction(trimmedValues);
    const hasErrors = hasValidationErrors(errors);

    if (!hasErrors) {
      await submitFunction(trimmedValues, setFormValues);
      setIsDirty(false);
    }

    setValidationErrors(errors);
    setIsLoading(false);
    return !hasErrors;
  };

  /**
   * In some cases, you want to use a different submit / validate function than the default one.
   */
  const submitWithParams = async (event: FormEvent, params: Partial<Params<TForm, TFormErrors>>): Promise<boolean> =>
    submit(event, params.submitForm, params.validateForm);

  /**
   * Use this function if the (simple) name of the field matches the name within the form.
   * Do not use it when the field is an array or (part of) a nested object. Use 'setValues' instead.
   *
   * The name of the input field should be equal to the simple property name within the form.
   * E.g. By using this function with '<Input name='title' />', the new value will be set on 'values.title'.
   */
  const setAttribute = (value: unknown, name: string) => {
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    setIsDirty(true);
  };

  const clearValues = () => {
    setFormValues(initialForm);
    setIsDirty(false);
    setValidationErrors({});
  };

  const setValidationErrorsManually = (errors: FormValidationErrors<TFormErrors>): void => {
    setValidationErrors(errors);
  };

  // Map server errors to form validation errors
  useEffect(() => {
    if (error?.validationErrors) {
      setValidationErrors(mapToFormValidationErrors(error));
    }
  }, [error]);

  useEffect(() => {
    setFormValues(initialForm);
    setIsDirty(false);
    // Clear all if the component unmounts
    return () => {
      clearValues();
    };
  }, [isInitialFormFilled]);

  return {
    clearValues,
    hasValidationErrors: hasValidationErrors(validationErrors),
    isDirty,
    isLoading,
    setAttribute,
    setFormValues,
    setIsDirty,
    setValidationErrorsManually,
    submit,
    submitWithParams,
    validationErrors,
    values,
  };
}

export default useForm;

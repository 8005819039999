import { FC } from 'react';

import { formatPrice } from '@utils/formatHelpers';
import { formatNumberToDotNotation } from '@utils/numberHelpers';
import { TBulkPricingItem } from 'product/_models/products';
import { useTranslation } from 'react-i18next';

import './productBulkPricing.scss';

type TProps = {
  minimumOrderAmmount: number;
  pricing?: TBulkPricingItem[];
};

const ProductBulkPricing: FC<TProps> = ({ minimumOrderAmmount, pricing }) => {
  const byMinimum = (a: TBulkPricingItem, b: TBulkPricingItem) => (a.minimumAmount || 0) - (b.minimumAmount || 0);
  const { t } = useTranslation();

  const uniquePriceRanges = pricing.filter(
    (v, i, a) =>
      a.findIndex(
        t => t.minimumAmount === v.minimumAmount && t.maximumAmount === v.maximumAmount && t.unitPrice === v.unitPrice,
      ) === i,
  );

  return (
    <section className="product--detail__bulk-pricing bulk-pricing">
      <table>
        <thead>
          <tr>
            <th className="bulk-pricing__label">{t('PRODUCT.AMOUNT')}</th>
            <th className="bulk-pricing__unit-price">{t('PRODUCT.UNIT_PRICE')}</th>
          </tr>
        </thead>
        <tbody>
          {uniquePriceRanges.sort(byMinimum).map(({ minimumAmount, maximumAmount, unitPrice }, index) => (
            <tr key={`${minimumAmount}_${maximumAmount}_${unitPrice}_${index}`}>
              <td className="bulk-pricing__label">
                {minimumAmount !== undefined && maximumAmount !== undefined
                  ? `${formatNumberToDotNotation(
                      minimumOrderAmmount > minimumAmount ? minimumOrderAmmount : minimumAmount,
                    )} - ${formatNumberToDotNotation(maximumAmount)}`
                  : maximumAmount !== undefined
                  ? `${formatNumberToDotNotation(maximumAmount)}`
                  : `${
                      formatNumberToDotNotation(minimumOrderAmmount > minimumAmount ? minimumOrderAmmount : minimumAmount) || ''
                    }`}
              </td>
              <td className="bulk-pricing__unit-price">{formatPrice(unitPrice)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ProductBulkPricing;

import { FC } from 'react';

import { Dropdown } from '@shared';
import i18n from 'i18next';

import { TLanguage } from '../../../../general/_models/general';
import { useGetLanguage } from '../../../../general/_queries/useGetLanguage';

import './languagePickerDropdown.scss';

type TProps = {
  languages: TLanguage[];
};

const LanguagePickerDropdown: FC<TProps> = ({ languages }) => {
  if (!languages) return null;

  const { refetch: refetchTranslations } = useGetLanguage();

  return (
    <Dropdown
      className="header__languagepicker"
      hasNoEmtyOption
      isSearchable={false}
      name="Language"
      onChange={(value: string) => {
        i18n.changeLanguage(value);
        localStorage.setItem('preferredLanguage', value);
        refetchTranslations();
      }}
      options={languages.map(language => ({ label: language.code.split('_')[0].toUpperCase(), value: language.code }))}
      value={i18n.language}
    />
  );
};

export default LanguagePickerDropdown;

import i18next from 'i18next';

import { OrderStatus } from '../_models';

export const getOrderStatusColor = (status: OrderStatus) => {
  const colorMap = {
    '--accent': '#3782a3',
    '--cancelled': '#df1f12',
    '--in-progress': '#f59e0b',
    '--primary-text-dark': '#d9d9d9',
    '--success': '#10b981',
  };

  if (status == OrderStatus.OrderCompleted) return colorMap['--success'];
  if (status == OrderStatus.PhysicalOrderCompleted) return colorMap['--success'];

  if (status == OrderStatus.Cancelled) return colorMap['--cancelled'];
  if (status == OrderStatus.DigitalOrderFailed) return colorMap['--cancelled'];
  if (status == OrderStatus.BMECancelled) return colorMap['--cancelled'];
  if (status == OrderStatus.BMEValidationError) return colorMap['--cancelled'];

  if (status == OrderStatus.DigitalOrderSent) return colorMap['--in-progress'];
  if (status == OrderStatus.Ordered) return colorMap['--in-progress'];
  if (status == OrderStatus.Processing) return colorMap['--in-progress'];

  return colorMap['--primary-text-dark'];
};

export const getOrderStatusLabel = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.OrderCompleted:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_COMPLETED');
    case OrderStatus.PhysicalOrderCompleted:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_PHYSICAL_COMPLETED');

    case OrderStatus.Cancelled:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_CANCELLED');
    case OrderStatus.DigitalOrderFailed:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_FAILED');
    case OrderStatus.BMECancelled:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_BME_CANCELLED');
    case OrderStatus.BMEValidationError:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_BME_VALIDATION_ERROR');

    case OrderStatus.DigitalOrderSent:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDER_SENT');
    case OrderStatus.Ordered:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.ORDERED');
    case OrderStatus.Processing:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.STATUS.PROCESSING');
    default:
      return status;
  }
};

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TPayment, TPaymentResponse } from '../_models/payment';

function submitPayment(body: TPayment): Promise<{ orderId: string }> {
  return HttpClient.post(`payments/create`, body);
}

export function useSubmitPayment() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { shoppingCartId } = useCustomerContext();

  return useMutation<TPaymentResponse, ApiError, TPayment>('createPayment', body => submitPayment(body), {
    onError: () => {
      toast.error(t('CHECKOUT.PAYMENT.PAYMENT_ERROR'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);
    },
  });
}

import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Image } from '@shared';
import { useTranslation } from 'react-i18next';

import { TProductCategory } from '../../../../general/_models/general';
import { TCategoryName } from '../../../_models/categories';

import './crossSell.scss';

type TProps = {
  category: TCategoryName;
  refetchProducts: () => void;
  setSelectedCategory: Dispatch<SetStateAction<TProductCategory>>;
};

const CrossSell: FC<TProps> = ({ category, refetchProducts }) => {
  const { t } = useTranslation();
  const translationPath = category === 'movie-boxes' ? 'DISCOUNT_CARDS' : 'MOVIE_BOXES';

  return (
    <section className="product__cross-selling fill-width">
      <h2>{t('PRODUCT.CROSS_SELL.TITLE')}</h2>
      <p>{t(`PRODUCT.CROSS_SELL.${translationPath}.DESCRIPTION`)}</p>

      <div className="banner">
        <a
          href={t(`PRODUCT.CROSS_SELL.${category !== 'movie-boxes' ? 'DISCOUNT_CARDS' : 'MOVIE_BOXES'}.BUTTON_LINK`)}
          rel="noreferrer"
          target="_blank"
        >
          <Image src={t(`PRODUCT.CROSS_SELL.${category !== 'movie-boxes' ? 'DISCOUNT_CARDS' : 'MOVIE_BOXES'}.IMAGE_URL`)} />
        </a>
      </div>
      <footer>
        <Button
          href={t(`PRODUCT.CROSS_SELL.${category !== 'movie-boxes' ? 'DISCOUNT_CARDS' : 'MOVIE_BOXES'}.BUTTON_LINK`)}
          icon="ChevronRight"
          iconPosition="right"
          onClick={() => refetchProducts()}
          theme="light-transparent"
        >
          {t(`PRODUCT.CROSS_SELL.${translationPath}.CTA`)}
        </Button>
      </footer>
    </section>
  );
};

export default CrossSell;

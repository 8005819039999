import { FC } from 'react';

import { Route, Switch } from 'react-router';

import Checkout from '../../checkout/Checkout';
import PaymentCallback from '../../checkout/PaymentCallback';
import { CheckoutContextProvider } from '../../checkout/_context/CheckoutContext';
import LogInOrGuest from '../../checkout/account/LogInOrGuest';
import { AccountInfo, OrderDetail, OrderOverview } from '../../customerPortal';
import HomePage from '../../general/homePage/HomePage';
import { ProductOverview, ProductDetail } from '../../product';
import ShoppingCartOverview from '../../shoppingCart/overview/ShoppingCartOverview';
import NotFound from '../layouts/screens/NotFound';

const B2CRoutes: FC = () => (
  <CheckoutContextProvider>
    <main id="main-content">
      <Switch>
        <Route component={HomePage} exact path="/" />
        <Route component={ProductOverview} exact path="/products" />
        <Route component={ProductOverview} exact path="/products/:category(movie-boxes|discount-cards)" />
        <Route component={ProductDetail} path="/products/:category(movie-boxes|discount-cards)/:id" />
        <Route component={ShoppingCartOverview} exact path="/cart" />
        <Route component={LogInOrGuest} exact path="/logInOrGuest" />
        <Route component={PaymentCallback} path="/checkout/callback/:orderId" />
        <Route component={Checkout} path="/checkout" />
        <Route component={AccountInfo} exact path="/account" />
        <Route component={OrderOverview} exact path="/account/orders" />
        <Route component={OrderDetail} exact path="/account/orders/:orderId" />
        <Route component={NotFound} />
      </Switch>
    </main>
  </CheckoutContextProvider>
);

export default B2CRoutes;

import { FC } from 'react';

import { Icon } from '@shared';
import { TIconType } from '@shared/icon/Icon';
import { TCompositionItem } from 'product/_models/products';
import { useTranslation } from 'react-i18next';
import './productContents.scss';

type TProps = {
  contents?: TCompositionItem[];
};

const ProductContents: FC<TProps> = ({ contents = [] }) => {
  const { t } = useTranslation();

  const filteredContents = contents.filter(({ title }) => title?.length);
  if (filteredContents.length === 0) return null;

  return (
    <section className="product__contents product-details__contents">
      <h2>{t('PRODUCT.WHATS_INSIDE')}</h2>
      <ul className="product__contents__products">
        {filteredContents.map(({ content, icon, title }, index) => (
          <li key={`${title}_${index}`}>
            <Icon name={`Ci${icon}` as TIconType} size={3.2} />
            <div>
              <h3>{title}</h3>
              <p>{content}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProductContents;

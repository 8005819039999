import { FC, useRef } from 'react';

import Icon, { TIconType } from '@shared/icon/Icon';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { Instance as TippyInstance } from 'tippy.js';

import './tooltip.scss';

export type TooltipProps = {
  icon?: TIconType;
  /**Size translates to rem*/
  iconSize?: number;
  text: string;
  title?: string;
};

const Tooltip: FC<TooltipProps> = ({ title, text, icon = 'Question', iconSize = 1.6 }) => {
  const instance = useRef<TippyInstance>();
  const { t } = useTranslation();

  const content = title ? (
    <>
      <h2>{title}</h2>
      <p>{text}</p>
    </>
  ) : (
    <p>{text}</p>
  );

  return (
    <Tippy
      appendTo="parent"
      className="tooltip"
      content={content}
      duration={500}
      interactive
      onCreate={tippy => {
        instance.current = tippy;
      }}
    >
      <button className="tooltip__trigger" type="button">
        <Icon label={t('SHARED.BUTTONS.TOOLTIP')} name={icon} size={iconSize} />
      </button>
    </Tippy>
  );
};

export default Tooltip;

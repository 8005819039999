import React, { FC, useEffect, useState } from 'react';

import { useDebounce } from '@hooks';
import { Button, Icon, InputField, Spinner } from '@shared';
import { TPhysicalAddress } from 'checkout/_models/address';
import { useTranslation } from 'react-i18next';

import Radio from './../../_shared/input/radio/Radio';
import { useGetGeneralInfo } from './../../general/_queries/useGetGeneralInfo';
import { TPickupPoint, useGetBMEToken, useGetPickupPoints } from './../_queries';

import './selectPickupPoint.scss';

type TPickupPointProps = {
  onPickupPointSelect: (pickupPoint: TPickupPoint) => void;
  prefillAddress?: TPhysicalAddress;
};

const SelectPickupPoint: FC<TPickupPointProps> = ({ onPickupPointSelect, prefillAddress }) => {
  const { t } = useTranslation();
  const { data: generalInfo } = useGetGeneralInfo();
  const [searchValue, setSearchValue] = useState<string>(prefillAddress?.postalCode || '');
  const [selectedPickupPoint, setSelectedPickupPoint] = useState<TPickupPoint>(undefined);
  const { refetch: refetchToken, data: BMEToken, isLoading: isTokenLoading } = useGetBMEToken();
  const {
    data: pickupPoints,
    mutate: getPickupPoints,
    isLoading: isGetPickupPointsLoading,
    isError: pickupPointError,
  } = useGetPickupPoints();

  const debouncedSearchValue = useDebounce(searchValue, 600);

  const getLocaleBasedOnDomain = (): string => {
    return window.location.hostname.slice(-2);
  };

  useEffect(() => {
    if (!BMEToken?.access_token) refetchToken();
  }, []);

  useEffect(() => {
    const search = debouncedSearchValue?.trim()?.toLowerCase();
    if (BMEToken?.access_token) {
      getPickupPoints({
        access_token: BMEToken?.access_token,
        body: {
          carrier: generalInfo?.carrier,
          countryCode: getLocaleBasedOnDomain().toUpperCase(),
          zipCode: search,
        },
      });
    }
  }, [debouncedSearchValue, prefillAddress?.isPickupPoint]);

  useEffect(() => {
    if (selectedPickupPoint !== undefined) {
      setSelectedPickupPoint(selectedPickupPoint);
      onPickupPointSelect(selectedPickupPoint);
    }
  }, [selectedPickupPoint]);

  useEffect(() => {
    if (prefillAddress && pickupPoints && selectedPickupPoint == undefined) {
      const { city, street, postalCode, number } = prefillAddress;

      const previouslySelectefPickupPoint = pickupPoints?.find(
        pickupPoint =>
          pickupPoint?.Address?.city == city &&
          pickupPoint?.Address?.number == number &&
          pickupPoint?.Address?.street == street &&
          pickupPoint?.Address?.zipcode == postalCode,
      );
      if (previouslySelectefPickupPoint !== undefined) setSelectedPickupPoint(previouslySelectefPickupPoint);
    }
  }, [pickupPoints]);

  if (isTokenLoading)
    return (
      <div className="loading">
        <Spinner />
      </div>
    );

  if (!BMEToken?.access_token)
    return <div className="loading">{t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_SELECTION_UNAVAILABLE')}</div>;

  return (
    <div className="pickup-point-wrapper">
      <div className="search-wrapper">
        <InputField
          autoComplete="off"
          className="search"
          hideAsterisk
          icon={<Icon className="search-icon" name="Search" />}
          iconPosition="start"
          label={t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_POSTALCODE_LOOKUP')}
          name="search"
          onChange={value => {
            setSearchValue(value?.toString());
          }}
          placeholder={t('CHECKOUT.ADDRESS.POSTAL_CODE')}
          type="text"
          value={searchValue}
        />
      </div>
      {isGetPickupPointsLoading ? (
        <div className="centered-content">
          <Spinner />
        </div>
      ) : (
        <ul className="pickup-point-list">
          {pickupPoints?.length == 0 || pickupPointError ? (
            <li className="pickup-point-list__item no-results">
              <span>{t('CHECKOUT.PICKUP_POINT.NO_PICKUP_POINTS_FOUND')}</span>
            </li>
          ) : (
            pickupPoints?.map((pickupPoint, index) => {
              return (
                <li
                  className={`pickup-point-list__item ${selectedPickupPoint?.name === pickupPoint?.name ? 'selected' : ''}`}
                  key={index}
                >
                  <Radio
                    checked={selectedPickupPoint === pickupPoint}
                    name={'Pickup point'}
                    onChange={() => setSelectedPickupPoint(pickupPoints[index])}
                  >
                    <div className="pickup-point-list__address">
                      <p>
                        <span className="pickup-point-list__address__name">{pickupPoint?.name}</span>
                      </p>
                      <p>
                        <span className="pickup-point-list__address__street-and-number">
                          {pickupPoint?.Address?.street} &nbsp;
                          {pickupPoint?.Address?.number}
                        </span>
                      </p>
                      <p>
                        <span className="pickup-point-list__address__city-and-postalcode">
                          {pickupPoint?.Address?.zipcode} &nbsp;
                          {pickupPoint?.Address?.city}
                        </span>
                      </p>
                    </div>
                  </Radio>
                  <Button
                    href={`https://maps.google.com/?q= ${pickupPoint?.name}, ${pickupPoint?.Address?.street} ${pickupPoint?.Address?.number}, ${pickupPoint?.Address?.city} ${pickupPoint?.Address?.zipcode} ${pickupPoint?.Address?.extension}`}
                    icon="CiMap"
                    theme="plain-link"
                  >
                    {t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_MAPS_LABEL')}
                  </Button>
                </li>
              );
            })
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectPickupPoint;

import { FC, ReactElement, ReactNode } from 'react';

import classnames from 'classnames';

import { Icon } from '..';
import { SortFunctions } from '../../_hooks/useTableSort';
import Spinner from '../spinner/Spinner';
import './table.scss';

export type TableColumn = {
  className?: string;
  label?: string;
  name: string;
  sortable?: boolean;
};

type TProps = {
  className?: string;
  columns: TableColumn[];
  data?: Record<string, unknown>[];
  emptyLabel: string;
  isLoading: boolean;
  renderRow: (item: unknown) => ReactElement;
  sortFunctions?: SortFunctions;
};

const Row: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <tr className={className}>{children}</tr>
);
const Cell: FC<{ children: ReactNode; className?: string; title?: string }> = ({ children, className, title }) => (
  <td className={className} title={title}>
    {children}
  </td>
);

const Table: FC<TProps> = ({ columns, renderRow, data = [], isLoading, emptyLabel, sortFunctions, className }) => {
  function renderHeaderCell(column: TableColumn) {
    const sortDirection = column.sortable ? sortFunctions.getSortDirectionForColumn(column.name)?.toLowerCase() : '';
    return (
      <th
        className={classnames(
          column.className || '',
          column.sortable ? sortFunctions.getSortDirectionForColumn(column.name)?.toLowerCase() : '',
          {
            sortable: column.sortable,
          },
        )}
        key={column.name}
      >
        {column.sortable ? (
          <button onClick={column.sortable ? () => sortFunctions.onChangeSortColumn(column.name) : null}>
            <span>{!!column.label && column.label}</span>
            {sortDirection && <Icon className={`sort-arrow ${sortDirection}`} name="Sort" size={1.2} />}
          </button>
        ) : (
          <span>{!!column.label && column.label}</span>
        )}
      </th>
    );
  }

  function renderBody() {
    if (!isLoading && !data?.length) {
      return (
        <tr>
          <td className="table-empty text-align--center" colSpan={columns.length}>
            {emptyLabel}
          </td>
        </tr>
      );
    }
    return data.map(renderRow);
  }

  return (
    <div className="table-wrapper">
      <table className={classnames('table', { [className]: !!className, sortable: columns.some(col => col.sortable) })}>
        <thead>
          <tr>{columns.map(column => renderHeaderCell(column))}</tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="row-spinner">
              <td className="text-align--center" colSpan={columns.length}>
                <Spinner />
              </td>
            </tr>
          ) : (
            renderBody()
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Object.assign(Table, { Cell, Row });

import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react';

import { countries } from '@utils/countries';

import { useGetConfiguration } from '../../general/_queries/useGetConfiguration';
import { DEFAULT_COUNTRY_CODE, DEFAULT_PHONE_PREFIX } from '../_util/constants';

import { TCheckoutActions } from './checkoutActions';
import { checkoutReducer, TCheckoutState } from './checkoutReducer';

export function getInitialCheckoutValues(countryCode?: string): TCheckoutState {
  return {
    details: {
      email: '',
      firstName: '',
      lastName: '',
    },
    digitalAddress: {
      default: false,
      email: '',
    },
    invoiceDetails: {
      box: '',
      city: '',
      companyName: '',
      countryIsoCode: countryCode ?? DEFAULT_COUNTRY_CODE,
      email: '',
      number: '',
      postalCode: '',
      street: '',
      vat: '',
      wantInvoice: false,
    },
    paymentMethod: '',
    physicalAddress: {
      attn: '',
      box: '',
      city: '',
      country: countryCode ?? DEFAULT_COUNTRY_CODE,
      default: false,
      isPickupPoint: false,
      mobilePhoneNumber: '',
      mobilePhoneNumberPrefix: countries.find(country => country.value === countryCode)?.phonePrefix ?? DEFAULT_PHONE_PREFIX,
      name: '',
      number: '',
      phoneNumber: '',
      phoneNumberPrefix: countries.find(country => country.value === countryCode)?.phonePrefix ?? DEFAULT_PHONE_PREFIX,
      pickupPointId: '',
      postalCode: '',
      street: '',
    },
    poNumber: '',
    step: 0,
    timeout: undefined,
    wantInvoice: false,
  };
}

type TContext = {
  dispatch: Dispatch<TCheckoutActions>;
  state: TCheckoutState;
};

export const CheckoutContext = createContext<TContext>({
  dispatch: () => {},
  state: getInitialCheckoutValues(),
});

export const useCheckoutContext = () => useContext(CheckoutContext);

type TProps = {
  children: ReactNode;
};

export const CheckoutContextProvider: FC<TProps> = ({ children }) => {
  const { data: config } = useGetConfiguration();
  const [state, dispatch] = useReducer(checkoutReducer, getInitialCheckoutValues(config?.countryCode));

  return <CheckoutContext.Provider value={{ dispatch, state }}>{children}</CheckoutContext.Provider>;
};

import { useForm, useStoreType } from '@hooks';
import { FormValidationErrors, SubmitFormFunction } from '@hooks/useForm';
import { countries, getCountryCodeFromDomain } from '@utils/countries';
import { formValidator } from '@utils/formValidation';
import { trimObjectValues } from '@utils/objectHelpers';
import { TCompanyDetails } from 'company/_models';

import { useCheckoutContext } from '../_context/CheckoutContext';
import { setShippingDetails } from '../_context/checkoutActions';
import { TInvoiceDetails } from '../_models/invoice';
import { useUpdateInvoiceAddress } from '../_queries';
import { DEFAULT_PHONE_PREFIX } from '../_util/constants';

import { useValidateVatAndEnterpriseNumber } from './../../company/_queries/useValidateVatAndEnterpriseNumber';
import { useCustomerContext } from './../../shoppingCart/_context/CustomerContext';

type TProps = {
  invoiceDetails: TInvoiceDetails;
  onSubmitSuccess: () => void;
  selectedCompany: TCompanyDetails;
};

const useInvoiceForm = ({ invoiceDetails, onSubmitSuccess: onSubmitSuccessGuest, selectedCompany }: TProps) => {
  const validator = formValidator();
  const { dispatch } = useCheckoutContext();
  const { isGuest } = useCustomerContext();
  const { isB2B } = useStoreType();
  const { mutateAsync: updateInvoiceAddress, isLoading: isUpdatingInvoiceAddress, isSuccess } = useUpdateInvoiceAddress();
  const { mutateAsync: validateVatAndEnterprise } = useValidateVatAndEnterpriseNumber();
  const DEFAULT_COUNTRY = getCountryCodeFromDomain().toUpperCase();

  const initialForm: TInvoiceDetails = {
    box: invoiceDetails?.box || '',
    city: invoiceDetails?.city || '',
    companyName: invoiceDetails?.companyName || selectedCompany?.name || '',
    countryIsoCode: DEFAULT_COUNTRY,
    domainName: invoiceDetails?.domainName || '',
    email: invoiceDetails?.email || '',
    enterpriseNumber: invoiceDetails?.enterpriseNumber || selectedCompany?.enterpriseNumber || '',
    extraInfo: invoiceDetails?.extraInfo || '',
    mobilePhoneNumber: invoiceDetails?.mobilePhoneNumber,
    mobilePhoneNumberPrefix:
      (invoiceDetails?.mobilePhoneNumberPrefix || countries.find(country => country.value === DEFAULT_COUNTRY)?.phonePrefix) ??
      DEFAULT_PHONE_PREFIX,
    number: invoiceDetails?.number || '',
    phoneNumber: invoiceDetails?.phoneNumber || '',
    phoneNumberPrefix:
      (invoiceDetails?.phoneNumberPrefix || countries.find(country => country.value === DEFAULT_COUNTRY)?.phonePrefix) ??
      DEFAULT_PHONE_PREFIX,
    postalCode: invoiceDetails?.postalCode || '',
    street: invoiceDetails?.street || '',
    vat: invoiceDetails?.vat || selectedCompany?.vatNumber || '',
    wantInvoice: true,
  };

  function isVatOrEnterpriseRequired(enterpriseNumber: string, vat: string) {
    const isEnterpriseFilled = Boolean(enterpriseNumber);
    const isVatFilled = Boolean(vat);

    return {
      enterpriseRequired: isEnterpriseFilled && isVatFilled,
      vatRequired: isVatFilled || !isEnterpriseFilled,
    };
  }

  async function validateForm(values: TInvoiceDetails): Promise<FormValidationErrors<TInvoiceDetails>> {
    const { enterpriseValidation, vatValidation } = await validateVatAndEnterprise({
      countryCode: values?.countryIsoCode,
      enterpriseNumber: values?.enterpriseNumber,
      vatNumber: values?.vat,
    });

    const { enterpriseRequired, vatRequired } = isVatOrEnterpriseRequired(values?.enterpriseNumber, values?.vat);

    return {
      box: validator.box(values?.street, values?.box, values?.number),
      city: validator.city(values?.city),
      companyName: validator.companyName(values?.companyName),
      countryIsoCode: validator.required(values?.countryIsoCode),
      email: validator.email(values.email),
      enterpriseNumber: enterpriseRequired ? enterpriseValidation : undefined,
      extraInfo: validator.maxLength(values?.extraInfo, 30),
      mobilePhoneNumber: validator.phoneNumber(values?.mobilePhoneNumber, values?.mobilePhoneNumberPrefix),
      number: validator.streetNumber(values?.street, values?.box, values?.number),
      phoneNumber: validator.phoneNumber(values?.phoneNumber, values?.phoneNumberPrefix, isB2B),
      postalCode: validator.postalCode(values?.postalCode),
      street: validator.street(values?.street, values?.box, values?.number),
      vat: vatRequired ? vatValidation : undefined,
    };
  }

  const submitForm: SubmitFormFunction<TInvoiceDetails> = values => {
    const trimmedValues = trimObjectValues({ ...values });

    if (!isGuest) {
      void updateInvoiceAddress(trimmedValues);
    }

    dispatch(
      setShippingDetails({
        invoiceDetails: trimmedValues,
      }),
    );

    if (isGuest) {
      onSubmitSuccessGuest?.();
    }
  };

  const { isLoading, submit, values, setAttribute, validationErrors, setValidationErrorsManually, setFormValues } =
    useForm<TInvoiceDetails>({
      initialForm,
      submitForm,
      validateForm,
    });

  return {
    isLoading,
    isSuccess,
    isUpdatingInvoiceAddress,
    setAttribute,
    setFormValues,
    setValidationErrorsManually,
    submit,
    validationErrors,
    values,
  };
};

export default useInvoiceForm;

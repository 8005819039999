import { FC, ReactNode } from 'react';

import { Tooltip } from '@shared/tooltip';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TValidatorResponse } from '../../_utils/formValidation';
import ErrorMessage from '../errorMessage/ErrorMessage';
import Icon, { TIconType } from '../icon/Icon';

import './inputWrapper.scss';
/**
 * This component serves as a wrapper around the specific input components. It contains some common input logic:
 *  - Show input label and icon
 *  - Show error message if needed
 *  - Show whether a field is required
 */

export type InputWrapperProps = {
  className?: string;
  disabled?: boolean;
  helpText?: string;
  helpTextTitle?: string;
  hideAsterisk?: boolean;
  id?: string;
  label?: string;
  labelIcon?: TIconType;
  name: string;
  required?: boolean;
  validation?: TValidatorResponse;
};

const InputWrapper: FC<InputWrapperProps & { children: ReactNode; showError?: boolean }> = ({
  children,
  className = '',
  disabled,
  validation,
  label,
  labelIcon,
  hideAsterisk = false,
  id,
  required,
  showError,
  helpText,
  helpTextTitle = label,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('input-wrapper', { disabled, error: showError, required }, className)}>
      {!!label && (
        <div className="input-wrapper-label">
          <label htmlFor={id}>
            {!!labelIcon && <Icon name={labelIcon} />}
            <span className="label">
              {label} {!hideAsterisk && required ? '*' : ''}
            </span>
            {required
              ? !hideAsterisk && (
                  <span className="input-wrapper-label__required visually-hidden">{t('SHARED.FORM.REQUIRED')}</span>
                )
              : !hideAsterisk && (
                  <span aria-hidden="true" className="input-wrapper-label__optional">
                    {t('SHARED.FORM.OPTIONAL')}
                  </span>
                )}
          </label>

          {helpText && <Tooltip text={helpText} title={helpTextTitle} />}
        </div>
      )}
      {children}
      <ErrorMessage isVisible={showError}>{validation?.message}</ErrorMessage>
    </div>
  );
};
export default InputWrapper;

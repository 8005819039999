import { FC } from 'react';

import { useHistory } from 'react-router';

import Button from '../button/Button';

type TProps = {
  label: string;
};

const GoBackLink: FC<TProps> = ({ label }) => {
  const { goBack } = useHistory();

  return (
    <Button className="go-back" icon="ChevronLeft" iconSize={2} isNavLink onClick={() => goBack()} theme="plain-link">
      {label}
    </Button>
  );
};
export default GoBackLink;

import { FC } from 'react';

import { useLocalStorage } from '@hooks';
import { Redirect } from 'react-router-dom';

const HomePage: FC = () => {
    const [preferredStore, setPreferredStore] = useLocalStorage('StorePreference', undefined);
    const clientType = window.location.pathname.substring(1, 4);

    if (preferredStore === undefined && (clientType === 'B2B' || clientType === 'B2C')) {
        const webshopHost = `https://${window.location.host}/${clientType}`;

        setPreferredStore(webshopHost);
    }

    return <Redirect to="/products" />
};

export default HomePage;

import { FC, useId } from 'react';

import { useResponsive } from '@hooks';
import { Button } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import { formatNumberToDotNotation } from '@utils/numberHelpers';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import './quantityInput.scss';

type TProps = {
  disabled?: boolean;
  hasButtons?: boolean;
  max?: number;
  min?: number;
  name?: string;
  onBlur?: (value: string | number) => void;
  onChange: (value: string | number) => void;
  priceId?: string;
  step?: number;
  theme?: 'light' | 'dark' | 'cart';
  unitPrice?: number;
  value?: string | number;
};

const QuantityInput: FC<TProps> = ({
  disabled = false,
  value,
  onChange,
  onBlur,
  name = 'quantity',
  max = 99,
  min = 1,
  step = 1,
  theme = 'dark',
  hasButtons = true,
  priceId,
  unitPrice,
}) => {
  const { t } = useTranslation();
  const amountId = useId();
  const { isSmallerThan } = useResponsive();
  const Wrapper = theme === 'cart' ? 'div' : 'fieldset';
  const Description = theme === 'cart' ? 'p' : 'legend';

  return (
    <Wrapper
      className={classnames('quantity-input', `quantity-input--${theme}`, {
        'quantity-input--buttons': hasButtons,
        'quantity-input--no-buttons': !hasButtons,
      })}
    >
      <Description className="visually-hidden">{t('SHARED.QUANTITY_INPUT.LEGEND')}</Description>
      {hasButtons && (
        <Button
          className="quantity-input__btn subtract-btn"
          disabled={disabled}
          hideLabel
          icon="Minus"
          iconSize={0.8}
          onClick={() => {
            const numericValue = parseInt(value as string, 10);
            if (numericValue - step >= min) {
              onChange(numericValue - step);
            } else {
              toast.info(t('SHARED.QUANTITY_INPUT.MIN', { amount: formatNumberToDotNotation(min) }));
            }
          }}
          theme="accent"
        >
          {t('SHARED.QUANTITY_INPUT.SUBTRACT')}
        </Button>
      )}
      <label className="quantity-input__quantity">
        <span className={classnames((hasButtons || isSmallerThan('phone') || theme === 'cart') && 'visually-hidden')}>
          {t('PRODUCT.AMOUNT')}:
        </span>
        <input
          disabled={disabled}
          max={max}
          min={min}
          name={name}
          onBlur={() => {
            if (!value) {
              onChange(min);
              onBlur?.(min);
            } else {
              onChange(Math.max(min, Math.min(max, parseInt(value as string, 10))));
              onBlur?.(Math.max(min, Math.min(max, parseInt(value as string, 10))));
              if (Number(value) > max) {
                toast.info(t('SHARED.QUANTITY_INPUT.MAX', { amount: formatNumberToDotNotation(max) }));
              }
              if (Number(value) < min) {
                toast.info(t('SHARED.QUANTITY_INPUT.MIN', { amount: formatNumberToDotNotation(min) }));
              }
            }
          }}
          onChange={event => {
            const quantity =
              event.currentTarget.value.length === 0 || Number.isNaN(event.currentTarget.value)
                ? ''
                : parseInt(event.currentTarget.value, 10);
            onChange(quantity);
          }}
          pattern="[0-9]+"
          step={step}
          type="number"
          value={value}
        />
      </label>
      {hasButtons && (
        <Button
          className="quantity-input__btn add-btn"
          disabled={disabled}
          hideLabel
          icon="Plus"
          iconSize={0.8}
          onClick={() => {
            const numericValue = parseInt(value as string, 10);
            if (numericValue + step <= max) {
              onChange(numericValue + step);
            } else {
              toast.info(t('SHARED.QUANTITY_INPUT.MAX', { amount: formatNumberToDotNotation(max) }));
            }
          }}
          theme="accent"
        >
          {t('SHARED.QUANTITY_INPUT.ADD')}
        </Button>
      )}
      {!hasButtons && theme !== 'cart' && (
        <label className="quantity-input__output">
          <span className={isSmallerThan('phone') ? 'visually-hidden' : undefined}>{t('CART.TOTAL')}:</span>
          <output htmlFor={`${priceId} ${amountId}`}>
            {formatPrice(unitPrice * Math.max(Math.min(value as number, max), min))}
          </output>
        </label>
      )}
    </Wrapper>
  );
};

export default QuantityInput;

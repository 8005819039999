import { useQuery } from 'react-query';

import { ApiError, HttpClient } from '../../_http';

function getBase64Vochers(orderId: string, countryIsoCode: string, languageCode: string): Promise<string> {
  return HttpClient.get<string>(
    `Eshop/${countryIsoCode}/${languageCode}/DownloadVouchers?orderId=${orderId}`,
    {},
    {},
    'vouchers',
  );
}

export function useGetBase64Vouchers(orderId: string, countryIsoCode: string, languageCode: string, enabled = false) {
  return useQuery<string, ApiError>(['all-vouchers', orderId], () => getBase64Vochers(orderId, countryIsoCode, languageCode), {
    enabled,
    onError() {
      //do nothing
    },
  });
}

import { FC, useMemo, useState } from 'react';

import { useStoreType } from '@hooks';
import { Button, Image, QuantityInput } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import { useGetProduct } from '../../product/_queries/useGetProduct';
import { TShoppingCartItem } from '../_models';
import { useAddToShoppingCart, useRemoveFromShoppingCart } from '../_queries';

import EditPersonalizationText from './EditPersonalizationText';
import './shoppingCartItem.scss';

type TProps = {
  item: TShoppingCartItem;
};

const ShoppingCartItem: FC<TProps> = ({ item }) => {
  const { t } = useTranslation();
  const { mutateAsync: addToCart, isLoading: isAddToShoppingCartLoading } = useAddToShoppingCart({
    addToExistingAmount: false,
    isToastHidden: true,
  });
  const { mutate: removeFromCart, isLoading: isRemoveFromShoppingCartLoading } = useRemoveFromShoppingCart();
  const [amount, setAmount] = useState<string | number>(item.amount);
  const { data: product } = useGetProduct(item.productPackageId);

  const { isB2C, isB2B } = useStoreType();
  const updateAmount = useMemo(() => debounce(addToCart, 400), [addToCart]);

  return (
    <div className={classnames('shopping-cart-item', { 'shopping-cart-item--b2b': isB2B })}>
      <Image height={80} src={item.picture} width={80} />
      <div className="shopping-cart-item__content">
        <h2>{item.name}</h2>
        <span className="price">{formatPrice(item.pricePerItem)}</span>
        {isB2B && item.personalizationText && (
          <EditPersonalizationText
            onSubmit={value => addToCart({ amount: item.amount, personalizationText: value, product: item })}
            value={item.personalizationText}
          />
        )}
      </div>
      {isB2C && (
        <span className="delivery-info">{t(`PRODUCT.${item.isDigital ? 'DIGITAL' : 'PHYSICAL'}_PRODUCT_DISCLAIMER`)}</span>
      )}
      <div className="shopping-cart-item__actions">
        <div>
          <QuantityInput
            hasButtons
            max={product?.maximumOrderQuantity - 1 || 10}
            min={product?.minimumOrderQuantity || 1}
            onBlur={value => {
              addToCart({ amount: value as number, product: item });
            }}
            onChange={value => {
              setAmount(value);
              updateAmount({ amount: value as number, product: item });
            }}
            theme="cart"
            value={amount}
          />
          <Button
            className="shopping-cart-item__remove"
            hideLabel
            icon="Trash"
            iconSize={2.4}
            loading={isRemoveFromShoppingCartLoading || isAddToShoppingCartLoading}
            onClick={() => removeFromCart(item.productPackageId)}
            theme="plain-link"
          >
            {t('SHARED.BUTTONS.DELETE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartItem;

import { useMutation } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { TCallbackPaymentResult } from '../_models/payment';

function submitCallbackResult(body: TCallbackPaymentResult): Promise<null> {
  return HttpClient.post(`payments/result`, body);
}

export function useSubmitCallbackResult() {
  return useMutation<null, ApiError, TCallbackPaymentResult>('createPayment', body => submitCallbackResult(body));
}

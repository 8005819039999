import i18n from 'i18next';

import { TInvoiceDetails } from '../checkout/_models/invoice';

import { isAfterDate, isBeforeDate, formatDate, DEFAULT_DATE_STRING_FORMAT, DEFAULT_TIME_STRING_FORMAT } from './dateHelpers';

export function trim(value: string): string {
  return value.replace(/\s/g, '');
}

export function isEmptyString(value: string): boolean {
  return !value || trim(value) === '';
}

export type TValidatorResponse = {
  isValid: boolean;
  message?: string;
};

function isLengthBetweenOneAndThirtyFive(value: number): boolean {
  return value >= 1 && value <= 35;
}

export const formValidator = () => ({
  address: function (street: string, box: string, streetNumber: string): TValidatorResponse {
    const isValid = isLengthBetweenOneAndThirtyFive(street?.length + box?.length + streetNumber?.length);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_ADDRESS'),
    };
  },

  afterDate: function (value: Date, minDate: Date): TValidatorResponse {
    const isValid = isAfterDate(value, minDate);
    return {
      isValid,
      message: isValid
        ? null
        : i18n.t('ERRORS.VALIDATION.AFTER_DATE', {
            date: formatDate(minDate, `${DEFAULT_DATE_STRING_FORMAT} ${DEFAULT_TIME_STRING_FORMAT}`),
          }),
    };
  },

  attn: function (attn: string): TValidatorResponse {
    const isValid = attn?.length <= 30;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_ATTN'),
    };
  },

  beforeDate: function (value: Date, maxDate: Date): TValidatorResponse {
    const isValid = isBeforeDate(value, maxDate);
    return {
      isValid,
      message: isValid
        ? null
        : i18n.t('ERRORS.VALIDATION.BEFORE_DATE', {
            date: formatDate(maxDate, `${DEFAULT_DATE_STRING_FORMAT} ${DEFAULT_TIME_STRING_FORMAT}`),
          }),
    };
  },

  box: function (street: string, box: string, streetNumber: string): TValidatorResponse {
    const addressValidation = formValidator().address(street, box, streetNumber);
    const isPresent = !isEmptyString(`${box}`) && !!box;

    return {
      isValid: addressValidation.isValid,
      message: addressValidation.isValid
        ? null
        : isPresent
        ? box?.length > 10
          ? i18n.t('ERRORS.VALIDATION.INVALID_BOX')
          : ' '
        : null,
    };
  },

  city: function (city: string): TValidatorResponse {
    const isPresent = !isEmptyString(`${city}`) && !!city;
    const isValid = isPresent && city?.length <= 30;
    return {
      isValid,
      message: isValid ? null : isPresent ? i18n.t('ERRORS.VALIDATION.INVALID_CITY') : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  combine: function (...responses: TValidatorResponse[]) {
    return responses.find(response => !response.isValid);
  },

  companyEmail: function (email: string): TValidatorResponse {
    const isBMEValid = email?.length <= 50;
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email?.length > 0;
    const isValid = isBMEValid && isValidEmail;

    return {
      isValid,
      message: isValid
        ? null
        : isBMEValid
        ? i18n.t('ERRORS.VALIDATION.INVALID_EMAIL')
        : i18n.t('ERRORS.VALIDATION.INVALID_BME_EMAIL'),
    };
  },

  companyName: function (companyName: string): TValidatorResponse {
    const isPresent = !isEmptyString(`${companyName}`) && !!companyName;
    const isValid = isPresent && companyName?.length >= 1 && companyName?.length <= 30;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_COMPANY_NAME'),
    };
  },

  email: function (email: string): TValidatorResponse {
    const isBMEValid = email?.length <= 50;
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    const isValid = isBMEValid && isValidEmail;

    return {
      isValid,
      message: isValid
        ? null
        : isBMEValid
        ? i18n.t('ERRORS.VALIDATION.INVALID_EMAIL')
        : i18n.t('ERRORS.VALIDATION.INVALID_BME_EMAIL'),
    };
  },

  enterprise: function (enterpriseNumber: string, isRequired = false): TValidatorResponse {
    let isValid = false;
    const isValuePresent = !isEmptyString(`${enterpriseNumber}`) && !!enterpriseNumber;

    if (!isRequired && !isValuePresent) {
      isValid = true;
    }

    return {
      isValid,
      message: isValid
        ? null
        : isRequired && !isValuePresent
        ? i18n.t('ERRORS.VALIDATION.VAT_OR_ENTERPRISE_REQUIRED')
        : i18n.t('ERRORS.VALIDATION.INVALID_ENTERPRISE_NUMBER'),
    };
  },

  enumValue: function <T>(value: string, enumeration: T): TValidatorResponse {
    const isValid = Object.values(enumeration).includes(value);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID'),
    };
  },

  invoideDetailsValid: function (invoiceDetails: TInvoiceDetails): TValidatorResponse {
    let isValid = true;

    if (!invoiceDetails?.email) isValid = false;
    if (!invoiceDetails?.companyName) isValid = false;
    if (!invoiceDetails?.vat && !invoiceDetails?.enterpriseNumber) isValid = false;
    if (!invoiceDetails?.city) isValid = false;
    if (!invoiceDetails?.postalCode) isValid = false;
    if (!invoiceDetails?.street) isValid = false;
    if (!invoiceDetails?.number) isValid = false;

    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  isChecked: function (isChecked: boolean): TValidatorResponse {
    const isValid = isChecked;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  matchingPasswords: function (newPassword: string, repeatNewPassword: string): TValidatorResponse {
    const isNewPasswordValid = formValidator().password(newPassword).isValid;
    const isRepeatNewPasswordValid = formValidator().password(repeatNewPassword).isValid;

    const isValid = isNewPasswordValid && isRepeatNewPasswordValid && newPassword === repeatNewPassword;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_NEW_AND_REPEATED_PASSWORD'),
    };
  },

  maxLength: function (value: string, max: number): TValidatorResponse {
    const isValid = `${value}`.length <= max;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.MAX_LENGTH', { length: max }),
    };
  },

  minLength: function (value: string, min: number): TValidatorResponse {
    const isValid = `${value}`.length >= min;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.MIN_LENGTH', { length: min }),
    };
  },

  name: function (name: string): TValidatorResponse {
    const isPresent = !isEmptyString(`${name}`) && !!name;
    const isValid = isPresent && isLengthBetweenOneAndThirtyFive(name?.length);
    return {
      isValid,
      message: isValid ? null : isPresent ? i18n.t('ERRORS.VALIDATION.INVALID_NAME') : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  nameLength: function (name: string): TValidatorResponse {
    const isPresent = !isEmptyString(`${name}`) && !!name;
    const consistsOfTwoWords = new RegExp(/^\w+\s+\w+.*$/).test(name);
    const isValid = isPresent && consistsOfTwoWords && isLengthBetweenOneAndThirtyFive(name?.length);
    return {
      isValid,
      message: isValid
        ? null
        : !consistsOfTwoWords
        ? i18n.t('ERRORS.VALIDATION.INVALID_NAME_WORD_COUNT')
        : isPresent
        ? i18n.t('ERRORS.VALIDATION.INVALID_NAME')
        : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  notEmptyArray: function (array: unknown[]): TValidatorResponse {
    const isValid = array?.length > 0;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.EMPTY_ARRAY'),
    };
  },

  number: function (value: string): TValidatorResponse {
    const isValid = !Number.isNaN(parseFloat(value));
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.NOT_A_NUMBER'),
    };
  },

  password: function (password: string): TValidatorResponse {
    // Password requirements: min. 8 characters, at least one uppercase letter, one lowercase letter, and one number.
    const length = 8;
    let isValid = formValidator().minLength(password, length).isValid;
    if (!isValid) {
      return {
        isValid,
        message: i18n.t('ERRORS.VALIDATION.PASSWORD_TOO_SHORT'),
      };
    }
    isValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(password);
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.PASSWORD_UNSAFE'),
    };
  },

  phoneNumber: function (phoneNumber: string, phoneNumberPrefix: string, isRequired = false): TValidatorResponse {
    const phone = phoneNumberPrefix + phoneNumber;
    const isValid = !isRequired && phoneNumber?.length == 0 ? true : phone?.length >= 10 && phone?.length <= 15;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID_PHONE_NUMBER'),
    };
  },

  postalCode: function (postalCode: string): TValidatorResponse {
    const isPresent = !isEmptyString(`${postalCode}`) && !!postalCode;
    const isValid = isPresent && postalCode?.length <= 9;
    return {
      isValid,
      message: isValid
        ? null
        : isPresent
        ? i18n.t('ERRORS.VALIDATION.INVALID_POSTAL_CODE')
        : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  required: function (value: unknown): TValidatorResponse {
    const isValid = !isEmptyString(`${value}`) && !!value;
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  street: function (street: string, box: string, streetNumber: string): TValidatorResponse {
    const addressValidation = formValidator().address(street, box, streetNumber);
    const isPresent = !isEmptyString(`${street}`) && !!street;
    const isValid = isPresent && addressValidation.isValid;

    return {
      isValid,
      message: isValid
        ? null
        : isPresent
        ? streetNumber?.length > 35 || box?.length > 35
          ? i18n.t('ERRORS.VALIDATION.INVALID_ADDRESS')
          : null
        : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  streetNumber: function (street: string, box: string, streetNumber: string): TValidatorResponse {
    const addressValidation = formValidator().address(street, box, streetNumber);
    const isPresent = !isEmptyString(`${streetNumber}`) && !!streetNumber;
    const isBMEValid = streetNumber?.length <= 5;
    const isValid = isPresent && addressValidation.isValid && isBMEValid;

    return {
      isValid,
      message: isValid
        ? null
        : isPresent
        ? isBMEValid
          ? street?.length > 35 || box?.length > 35
            ? i18n.t('ERRORS.VALIDATION.INVALID_ADDRESS')
            : null
          : i18n.t('ERRORS.VALIDATION.INVALID_NUMBER')
        : i18n.t('ERRORS.VALIDATION.REQUIRED'),
    };
  },

  url: function (url: string): TValidatorResponse {
    const isValid =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i.test(
        url,
      );
    return {
      isValid,
      message: isValid ? null : i18n.t('ERRORS.VALIDATION.INVALID'),
    };
  },

  vat: function (vatNumber: string, isRequired = false): TValidatorResponse {
    let isValid = false;
    const isValuePresent = !isEmptyString(`${vatNumber}`) && !!vatNumber;

    if (!isRequired && !isValuePresent) {
      isValid = true;
    }

    return {
      isValid,
      message: isValid
        ? null
        : isRequired && !isValuePresent
        ? i18n.t('ERRORS.VALIDATION.VAT_OR_ENTERPRISE_REQUIRED')
        : i18n.t('ERRORS.VALIDATION.VAT'),
    };
  },
});

import i18next from 'i18next';

type CountrySpecification = {
  countryCompanyCode: string;
  countryName: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
  languageCodes: string[];
};

const DEFAULT_COUNTRY = 'BE'; // Is not the users country but the country from KP
const DEFAULT_LANGUAGE = 'NL';

const countrySpecifications: Record<string, CountrySpecification> = {
  be: {
    countryCompanyCode: 'BE',
    countryName: 'BELGIUM',
    isoCountryCode: 'BE',
    isoCurrencyCode: 'EUR',
    languageCodes: ['NL', 'FR'],
  },
  ch: {
    countryCompanyCode: 'CH',
    countryName: 'SWITZERLAND',
    isoCountryCode: 'CH',
    isoCurrencyCode: 'EUR',
    languageCodes: ['DE'],
  },
  es: {
    countryCompanyCode: 'ES',
    countryName: 'SPAIN',
    isoCountryCode: 'ES',
    isoCurrencyCode: 'EUR',
    languageCodes: ['ES', 'CA'],
  },
  fr: {
    countryCompanyCode: 'FR',
    countryName: 'FRANCE',
    isoCountryCode: 'FR',
    isoCurrencyCode: 'EUR',
    languageCodes: ['FR'],
  },
  lu: {
    countryCompanyCode: 'LU',
    countryName: 'LUXEMBOURG',
    isoCountryCode: 'LU',
    isoCurrencyCode: 'EUR',
    languageCodes: ['FR', 'EN'],
  },
  nl: {
    countryCompanyCode: 'NL',
    countryName: 'NETHERLANDS',
    isoCountryCode: 'NL',
    isoCurrencyCode: 'EUR',
    languageCodes: ['NL'],
  },
};

function selectCountryAndLanguageConfig(): CountrySpecification {
  const storeUrl = window.location.host;
  const possibleDomainExtensions = Object.keys(countrySpecifications).join('|');
  const regexpSize = new RegExp(`kinepolis.(${possibleDomainExtensions})`);
  const [, extension] = storeUrl.match(regexpSize) || [];
  if (extension) return countrySpecifications[extension];
  return countrySpecifications['be']; // default Belgium
}

export function selectLanguageAndCountry(): { isoCountryCode: string; possibleLanguage: string } {
  const { isoCountryCode: isoCode, languageCodes } = selectCountryAndLanguageConfig();
  const [language] = i18next.language.split('_');
  const uppercasedLanguage = language.toUpperCase();
  const possibleLanguage = languageCodes.find(l => l === uppercasedLanguage) || DEFAULT_LANGUAGE;
  const isoCountryCode = languageCodes.includes(possibleLanguage) ? isoCode : DEFAULT_COUNTRY;

  return { isoCountryCode, possibleLanguage };
}

import { ApiError, HttpClient } from '@http';
import { useQuery } from 'react-query';

import { TConfiguration } from '../_models/configuration';

class ConfigurationManager {
  public config: TConfiguration;
  private static _instance: ConfigurationManager;

  public static get instance(): ConfigurationManager {
    if (!ConfigurationManager._instance) {
      ConfigurationManager._instance = new ConfigurationManager();
    }
    return ConfigurationManager._instance;
  }
}

async function getConfiguration(): Promise<TConfiguration> {
  if (!ConfigurationManager.instance.config) {
    const result = await HttpClient.get<TConfiguration>('configuration');
    ConfigurationManager.instance.config = result;
    return result;
  }

  return ConfigurationManager.instance.config;
}

export function useGetConfiguration() {
  return useQuery<TConfiguration, ApiError>(['configuration'], () => getConfiguration());
}

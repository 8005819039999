import { FC, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useResponsive, useToggle } from '@hooks';
import { HttpMetadataPagingResponse, HttpMetadataQuery } from '@http';
import { Button, Icon, Pagination, Table } from '@shared';
import { TableColumn } from '@shared/table/Table';
import { convertUtcToLocal, formatISOString } from '@utils/dateHelpers';
import { formatAddress, formatPrice } from '@utils/formatHelpers';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import LoadingScreen from '../../_routing/layouts/screens/LoadingScreen';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { OrderPaymentMethod, OrderStatus, getPaymentMethodIcon, getPaymentMethodLabel } from '../_models/order';
import { useGetOrderDetail, useGetVouchers } from '../_queries';
import { getOrderStatusColor, getOrderStatusLabel } from '../_util/orderHelpers';

import DownloadInvoiceButton from './components/DownloadInvoiceButton';
import DownloadVouchersButton from './components/DownloadVouchersButton';
import MobileProductsTable from './components/MobileProductsTable';
import OrderDetailItem from './components/OrderDetailItem';
import OrderStatusIndicator from './components/OrderStatusIndicator';
import ProductsTable from './components/ProductsTable';
import VoucherTable from './components/VoucherTable';

import './orderDetail.scss';

const OrderDetail: FC = () => {
  const { t } = useTranslation();
  const { isSmallerThan } = useResponsive();
  const isTablet = isSmallerThan('tablet');
  const isMobile = isSmallerThan('phone');
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const { isGuest } = useCustomerContext();
  const { login } = useReactOidc();

  const [loading, setLoading] = useState<boolean>(false);
  function handleLogin() {
    setLoading(true);
    login();
  }

  const [isSummaryVisible, toggleSummaryVisibility] = useToggle(true);
  const [isContactInfoVisible, toggleContactInfoVisibility] = useToggle(false);
  const [areProductsVisible, toggleProductsVisibility] = useToggle(false);
  const [isStatusHistoryVisible, toggleStatusHistoryVisibility] = useToggle(false);
  const [areVouchersVisible, toggleVoucherVisibility] = useToggle(false);
  const [voucherPagination, setVoucherPagination] = useState<HttpMetadataQuery>({
    skip: 0,
    take: 10,
  });

  const { data: orderDetail, isLoading: isOrderDetailLoading } = useGetOrderDetail(orderId);
  const { data: vouchersResponse, isLoading: isVouchersLoading } = useGetVouchers({
    orderId,
    skip: voucherPagination.skip,
    take: voucherPagination.take,
  });
  const containsDigitalProducts = vouchersResponse?.totalCount >= 1;
  const localDate = convertUtcToLocal(orderDetail?.dateOfOrder);

  const metaDataResponse = {
    count: vouchersResponse?.count,
    skip: voucherPagination?.skip || 10,
    totalCount: vouchersResponse?.totalCount,
  } as HttpMetadataPagingResponse;

  const summary = {
    [t('CUSTOMER_PORTAL.ORDERS.ORDER_NUMBER')]: orderDetail?.orderNumber,
    [t('CUSTOMER_PORTAL.ORDERS.PRICE')]: formatPrice(orderDetail?.price) + ' ' + t('PRODUCT.VAT_INCLUDED'),
    [t('CUSTOMER_PORTAL.ORDERS.DATE_TIME')]: localDate && formatISOString(localDate.toISOString()),
    [t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.TITLE')]: (
      <div className="payment-method">
        {getPaymentMethodLabel(orderDetail?.paymentMethod)}
        {orderDetail?.paymentMethod === OrderPaymentMethod.PayByLink ? (
          <Icon className="payment-method__pay-by-link" name="PayByLink" />
        ) : (
          <img className="payment-method__icon" src={getPaymentMethodIcon(orderDetail?.paymentMethod)} />
        )}
      </div>
    ),

    [t('CUSTOMER_PORTAL.ORDERS.DETAIL.COMPANY_NAME')]:
      orderDetail?.invoiceDetails?.companyName || orderDetail?.contactDetails?.name,
    [t('CUSTOMER_PORTAL.ORDERS.STATUS.TITLE')]: getOrderStatusLabel(orderDetail?.status),
    ...(orderDetail?.physicalAddress?.trackingUrl
      ? {
          [t('CUSTOMER_PORTAL.ORDERS.TRACK_AND_TRACE_NUMBER')]: (
            <>
              <a
                className="track-and-trace"
                href={`${orderDetail?.physicalAddress?.trackingUrl}`}
                rel="noreferrer"
                target="_blank"
              >
                <Icon className="track-and-trace__icon" name="TrackAndTraceTruck" size={2.6} />
                {t('CUSTOMER_PORTAL.ORDERS.DETAIL.TRACK_AND_TRACE')}
              </a>
            </>
          ),
        }
      : {}),
  };

  const addresses = {
    [t('CUSTOMER_PORTAL.ORDERS.DETAIL.CONTACT_DETAILS')]: orderDetail?.contactDetails
      ? Object.values(orderDetail?.contactDetails).join(', ')
      : '',
    [t('CUSTOMER_PORTAL.ORDERS.DETAIL.PHYSICAL_ADDRESS')]:
      `${
        orderDetail?.physicalAddress?.isPickupPoint ? t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_TAB_TITLE') + ': ' : ''
      }${formatAddress(orderDetail?.physicalAddress)}` || t('CUSTOMER_PORTAL.ACCOUNT_INFO.NO_PHYSICAL_ADDRESSES'),
    [t('CUSTOMER_PORTAL.ORDERS.DETAIL.DIGITAL_ADDRESS')]:
      orderDetail?.digitalShippingAddress || t('CUSTOMER_PORTAL.ACCOUNT_INFO.NO_DIGITAL_ADDRESSES'),
  };

  if (isOrderDetailLoading) return <LoadingScreen />;

  if (isGuest)
    return (
      <div className="guest">
        <Button
          className="guest__login-button"
          hideLabel={isMobile}
          icon="Person"
          loading={loading}
          onClick={handleLogin}
          theme="secondary"
        >
          {t('SHARED.GENERAL.LOGIN')}
        </Button>
      </div>
    );

  const renderRow = ({ key, name, value }: { key: number; name: string; value: string }) => (
    <tr className={name == t('CUSTOMER_PORTAL.ORDERS.TRACK_AND_TRACE_NUMBER') ? 'track-and-trace-wrapper' : undefined} key={key}>
      <td className="name">
        <span>{name}</span>
      </td>
      <td className="value">
        <span>{value}</span>
      </td>
    </tr>
  );

  function handleSummaryData<T>(data: T) {
    return Object.entries(data).map(([key, value], index) => ({ key: index, name: key, value: value }));
  }

  const products = {
    appliedDiscountCodes: orderDetail?.discounts || [],
    discountAmmount: orderDetail?.discounts?.reduce((sum, discount) => sum + discount.amount, 0) || 0,
    items: orderDetail?.products || [],
    shippingCosts: orderDetail?.shippingCost || 0,
    subtotal:
      (orderDetail?.price || 0) -
      (orderDetail?.discounts?.reduce((sum, discount) => sum + discount.amount, 0) || 0) -
      (orderDetail?.shippingCost || 0),
    total: orderDetail?.price || 0,
  };

  const renderStatusHistory = ({ key, status, statusDate }: { key: number; status: string; statusDate: string }) => {
    return (
      <tr key={key}>
        <td className="name">
          <span>{statusDate}</span>
        </td>
        <td className="value">
          <span>
            <OrderStatusIndicator className="status-icon" color={getOrderStatusColor(status as OrderStatus)} />
            {getOrderStatusLabel(status as OrderStatus)}
          </span>
        </td>
      </tr>
    );
  };

  const statusHistoryData = orderDetail?.statusHistory
    ?.sort((a, b) => {
      return new Date(b.statusDate).toISOString().localeCompare(new Date(a.statusDate).toISOString());
    })
    .map((order, index) => {
      return {
        key: index,
        status: order.status,
        statusDate: localDate && formatISOString(localDate.toISOString()),
      };
    });

  return (
    <div className="order-detail">
      <Helmet>
        <title>{t('CUSTOMER_PORTAL.TITLE') + ' | ' + t('CUSTOMER_PORTAL.ORDERS.DETAIL.TITLE')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
      <div className="back-to-shop-wrapper">
        <Button icon="ChevronLeft" iconPosition="left" onClick={() => history.goBack()} theme="plain-link">
          {t('CUSTOMER_PORTAL.ORDERS.DETAIL.GO_BACK')}
        </Button>
      </div>
      <h1>{t('CUSTOMER_PORTAL.ORDERS.DETAIL.TITLE')}</h1>
      <div className="order-detail-wrapper">
        <OrderDetailItem
          className="order-detail__summary"
          isTablet={isTablet}
          isVisible={isSummaryVisible}
          title="CUSTOMER_PORTAL.ORDERS.DETAIL.OVERVIEW"
          toggleVisibility={toggleSummaryVisibility}
        >
          <div className="table-wrapper">
            <Table
              className="summary-table"
              columns={[] as TableColumn[]}
              data={handleSummaryData(summary)}
              emptyLabel={t('CUSTOMER_PORTAL.ORDERS.ORDER_STATUS_EMPTY')}
              isLoading={isOrderDetailLoading}
              renderRow={renderRow}
            />
            <DownloadInvoiceButton countryCode={orderDetail?.invoiceDetails?.country} orderNumber={orderDetail?.orderNumber} />
          </div>
        </OrderDetailItem>

        <OrderDetailItem
          className="order-detail__addresses"
          isTablet={isTablet}
          isVisible={isContactInfoVisible}
          title="CUSTOMER_PORTAL.ORDERS.DETAIL.CONTACT_INFO"
          toggleVisibility={toggleContactInfoVisibility}
        >
          <div className="table-wrapper">
            <Table
              className="summary-table"
              columns={[] as TableColumn[]}
              data={handleSummaryData(addresses)}
              emptyLabel={t('CUSTOMER_PORTAL.ORDERS.ORDER_STATUS_EMPTY')}
              isLoading={isOrderDetailLoading}
              renderRow={renderRow}
            />
          </div>
        </OrderDetailItem>
      </div>

      <OrderDetailItem
        className="order-detail__products"
        isTablet={isTablet}
        isVisible={areProductsVisible}
        title="CUSTOMER_PORTAL.ORDERS.DETAIL.PRODUCTS"
        toggleVisibility={toggleProductsVisibility}
      >
        <div className="table-wrapper">
          {isTablet ? <MobileProductsTable products={products} /> : <ProductsTable overview={products} />}
        </div>
      </OrderDetailItem>

      <OrderDetailItem
        className="order-detail__summary"
        isTablet={isTablet}
        isVisible={areVouchersVisible}
        title="CUSTOMER_PORTAL.ORDERS.VOUCHERS.TITLE"
        toggleVisibility={toggleVoucherVisibility}
      >
        <div className="table-wrapper">
          <VoucherTable isLoading={isVouchersLoading} voucherResponse={vouchersResponse} />
          <div className="pagination-and-download-wrapper">
            <Pagination metadata={metaDataResponse} query={voucherPagination} setQuery={setVoucherPagination} />
            <div className="products-actions">
              {containsDigitalProducts && <DownloadVouchersButton orderId={orderId} orderNumber={orderDetail?.orderNumber} />}
            </div>
          </div>
        </div>
      </OrderDetailItem>

      <OrderDetailItem
        className="order-detail__status-history"
        isTablet={isTablet}
        isVisible={isStatusHistoryVisible}
        title="CUSTOMER_PORTAL.INVOICES.STATUS"
        toggleVisibility={toggleStatusHistoryVisibility}
      >
        <div className="table-wrapper">
          <Table
            className="summary-table"
            columns={[] as TableColumn[]}
            data={statusHistoryData}
            emptyLabel={t('CUSTOMER_PORTAL.ORDERS.DETAIL.STATUS_HISTORY_EMPTY')}
            isLoading={isOrderDetailLoading}
            renderRow={renderStatusHistory}
          />
        </div>
      </OrderDetailItem>
    </div>
  );
};

export default OrderDetail;

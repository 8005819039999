import { TDigitalAddress, TPhysicalAddress } from '../_models/address';
import { TDetails } from '../_models/details';
import { TInvoiceDetails } from '../_models/invoice';

import { getInitialCheckoutValues } from './CheckoutContext';
import { CheckoutAction, TCheckoutActions } from './checkoutActions';

export type TCheckoutAddress = {
  digitalAddress?: TDigitalAddress;
  invoiceDetails?: TInvoiceDetails;
  physicalAddress?: TPhysicalAddress;
  wantInvoice?: boolean;
};

export type TCheckoutState = TCheckoutAddress & {
  details: TDetails;
  orderId?: string;
  paymentMethod: string;
  physicalAddress?: TPhysicalAddress;
  poNumber: string;
  step: number;
  timeout?: string;
};

export function checkoutReducer(state: TCheckoutState, action: TCheckoutActions): TCheckoutState {
  switch (action.type) {
    case CheckoutAction.RESET:
      return getInitialCheckoutValues();

    case CheckoutAction.RESTORE:
      return action.payload;

    case CheckoutAction.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case CheckoutAction.SET_DETAILS:
      return {
        ...state,
        details: action.payload,
        digitalAddress: {
          ...state.digitalAddress,
          email: state.digitalAddress.email || action.payload.email,
        },
      };

    case CheckoutAction.SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };

    case CheckoutAction.SET_PO_NUMBER:
      return {
        ...state,
        poNumber: action.payload,
      };

    case CheckoutAction.SET_SHIPPING_DETAILS:
      return {
        ...state,
        digitalAddress: { ...(action.payload.digitalAddress ?? state.digitalAddress) },
        invoiceDetails: { ...(action.payload.invoiceDetails ?? state.invoiceDetails) },
        physicalAddress: { ...(action.payload.physicalAddress ?? state.physicalAddress) },
        wantInvoice: { ...action.payload }?.wantInvoice ?? state?.wantInvoice ?? false,
      };

    case CheckoutAction.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case CheckoutAction.SET_TIMEOUT:
      return {
        ...state,
        timeout: action.payload,
      };

    default:
      return { ...state };
  }
}

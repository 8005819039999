import { FC } from 'react';

import { Button } from '@shared';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const links = () => [
  {
    label: i18n.t('FOOTER.NAVIGATION.ITEMS.TERMS_OF_USE.LABEL'),
    to: i18n.t('FOOTER.NAVIGATION.ITEMS.TERMS_OF_USE.LINK'),
  },
  {
    label: i18n.t('FOOTER.NAVIGATION.ITEMS.SALES_CONDITIONS.LABEL'),
    to: i18n.t('FOOTER.NAVIGATION.ITEMS.SALES_CONDITIONS.LINK'),
  },
  {
    label: i18n.t('FOOTER.NAVIGATION.ITEMS.PRIVACY.LABEL'),
    to: i18n.t('FOOTER.NAVIGATION.ITEMS.PRIVACY.LINK'),
  },
  {
    label: i18n.t('FOOTER.NAVIGATION.ITEMS.COOKIE_STATEMENT.LABEL'),
    to: i18n.t('FOOTER.NAVIGATION.ITEMS.COOKIE_STATEMENT.LINK'),
  },
  {
    label: i18n.t('FOOTER.NAVIGATION.ITEMS.COMPANY_INFORMATION.LABEL'),
    to: i18n.t('FOOTER.NAVIGATION.ITEMS.COMPANY_INFORMATION.LINK'),
  },
];

const Navigation: FC = () => {
  const { t } = useTranslation();

  return (
    <nav aria-label="Footer" className="footer__navigation">
      <h2 className="visually-hidden">{t('FOOTER.NAVIGATION.TITLE')}</h2>
      <ul>
        {links().map(({ label, to }) => (
          <li key={label}>
            <Button href={to} theme="plain-link">
              {label}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;

import { FC } from 'react';

import { useLockBodyScroll, usePortal } from '@hooks';
import { Button, Image } from '@shared';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import logo from '../_assets/images/logo.svg';

import './landingPage.scss';

type TProps = {
  setPreferredStore: (webshopHost: string) => void;
};

const LandingPage: FC<TProps> = ({ setPreferredStore }) => {
  const { t } = useTranslation();
  const container = usePortal('landing');
  useLockBodyScroll();

  function onClickSetStore(clientType: 'B2C' | 'B2B') {
    const webshopHost = `https://${window.location.host}/${clientType}`;
    setPreferredStore(webshopHost);
    window.location.replace(webshopHost);
  }

  return createPortal(
    <div className="landing-page">
      <Image src={logo} />
      <div className="shop-preference-group">
        <p>{t('LANDING_PAGE.B2C_DESCRIPTION')}</p>
        <Button onClick={() => onClickSetStore('B2C')}>
          <p>{t('STORE.BE.B2C')}</p>
        </Button>
      </div>

      <div className="shop-preference-group">
        <p>{t('LANDING_PAGE.B2B_DESCRIPTION')}</p>
        <Button onClick={() => onClickSetStore('B2B')}>
          <p>{t('STORE.BE.B2B')}</p>
        </Button>
      </div>
    </div>,
    container,
  );
};

export default LandingPage;

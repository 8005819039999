import { FC } from 'react';

import { Route, Switch } from 'react-router';

import Checkout from '../../checkout/Checkout';
import PaymentCallback from '../../checkout/PaymentCallback';
import { CheckoutContextProvider } from '../../checkout/_context/CheckoutContext';
import PickCompany from '../../company/pickCompany/PickCompany';
import RegisterCompany from '../../company/registerCompany/RegisterCompany';
import RegisterCompanyResult from '../../company/registerCompany/RegisterCompanyResult';
import { AccountInfo, OrderDetail, OrderOverview } from '../../customerPortal';
import HomePage from '../../general/homePage/HomePage';
import { ProductOverview, ProductDetail } from '../../product';
import ShoppingCartOverview from '../../shoppingCart/overview/ShoppingCartOverview';
import NotFound from '../layouts/screens/NotFound';

const B2BRoutes: FC = () => (
  <>
    <CheckoutContextProvider>
      <main id="main-content">
        <Switch>
          <Route component={HomePage} exact path="/" />
          <Route component={ProductOverview} exact path="/products" />
          <Route component={ProductDetail} exact path="/products/:id" />
          <Route component={ShoppingCartOverview} exact path="/cart" />
          <Route component={PaymentCallback} exact path="/checkout/callback/:orderId" />
          <Route component={Checkout} path="/checkout" />
          <Route component={PickCompany} exact path="/pick-company" />
          <Route component={RegisterCompany} exact path="/register-company" />
          <Route
            component={RegisterCompanyResult}
            exact
            path={['/register-company/success', '/register-company/exists', '/register-company/not-authorized']}
          />
          <Route component={AccountInfo} exact path="/account" />
          <Route component={OrderOverview} exact path="/account/orders" />
          <Route component={OrderDetail} exact path="/account/orders/:orderId" />
          <Route component={NotFound} />
        </Switch>
      </main>
    </CheckoutContextProvider>
  </>
);

export default B2BRoutes;

import { FC, useEffect } from 'react';

import { useStoreType, useResponsive } from '@hooks';
import { Button, Spinner } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useCurrentCompany } from '../../company/_hooks';
import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';
import { googleAnalyticsViewCartEvent } from '../../googleAnalytics/dataLayer';
import { useCustomerContext } from '../_context/CustomerContext';
import { useRemoveDiscount } from '../_queries';
import DiscountForm from '../components/DiscountForm';
import ShoppingCartItem from '../components/ShoppingCartItem';
import ShoppingCartLine from '../components/ShoppingCartLine';
import UnusedDiscounts from '../components/UnusedDiscounts';

import './shoppingCartOverview.scss';

const ShoppingCartOverview: FC = () => {
  const { t } = useTranslation();
  const { cart, isLoading, isGuest, containsPhysicalProducts, containsDigitalProducts } = useCustomerContext();
  const { isBiggerThan } = useResponsive();
  const { isB2C, isB2B } = useStoreType();
  const { data: generalInfo } = useGetGeneralInfo();
  const { mutate: removeDiscount } = useRemoveDiscount();
  const { companyId } = useCurrentCompany();

  const proceedRoute = isGuest ? '/logInOrGuest' : !companyId && isB2B ? '/pick-company' : '/checkout';

  if (isB2B && isGuest) return <Redirect to="/products" />;

  const getDescription = () => {
    if (!containsPhysicalProducts) return t('CART.FREE_SHIPPING_DIGITAL');

    if (generalInfo?.freeShippingAmount > 0 && !cart.freeShippingCode)
      return t('CART.FREE_SHIPPING_THRESHOLD', { amount: formatPrice(generalInfo?.freeShippingAmount) });

    return undefined;
  };

  const canAddDiscount = () => {
    if (cart?.shippingCosts == 0 && cart?.appliedDiscountCodes.length == 0) return false;
    if (!containsDigitalProducts && containsPhysicalProducts) return true;
    if (containsDigitalProducts && !containsPhysicalProducts) return false;
  };

  useEffect(() => {
    if (cart) {
      googleAnalyticsViewCartEvent(cart);
    }
  }, []);

  return (
    <section className="shopping-cart-overview">
      <Helmet>
        <title>{t('CART.TITLE')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
      <h1 className="visually-hidden">{t('CART.TITLE')}</h1>
      {isLoading ? (
        <Spinner />
      ) : cart && (cart?.items || []).length ? (
        <>
          <UnusedDiscounts />
          <div className="shopping-cart-overview__content">
            {(cart?.items || []).map(item => (
              <ShoppingCartItem item={item} key={item.productPackageId} />
            ))}
            <ShoppingCartLine label={t('CART.SUBTOTAL')} value={cart?.subtotal} />
            <ShoppingCartLine
              description={getDescription()}
              emptyLabel={t('CART.FREE')}
              label={t('CART.SHIPPING')}
              value={!containsPhysicalProducts ? null : cart?.shippingCosts}
            />
            {cart?.appliedDiscountCodes.map(discount => (
              <ShoppingCartLine
                description={t('CART.DISCOUNTS.CODE_APPLIED', { discountCode: discount.code })}
                handleDelete={() => {
                  removeDiscount(discount.discountId);
                }}
                key={discount.code}
                label={t('CART.DISCOUNT')}
                value={discount.amount}
                withMinus
              />
            ))}
            <ShoppingCartLine description={t('CHECKOUT.INCLUDING_VAT')} highlight label={t('CART.TOTAL')} value={cart?.total} />
            {isB2C && canAddDiscount() && <DiscountForm />}
          </div>
        </>
      ) : (
        <p className="shopping-cart-overview__empty">{t('CART.EMPTY')}</p>
      )}
      <div className="shopping-cart-overview__actions">
        <Button
          href="/products"
          icon={isBiggerThan('phone') ? 'ChevronLeft' : null}
          iconPosition="left"
          isNavLink
          theme="secondary"
        >
          {t('CART.CONTINUE_SHOPPING')}
        </Button>
        <Button
          disabled={cart?.items == undefined || cart?.items?.length === 0}
          href={proceedRoute}
          icon={isBiggerThan('phone') ? 'ChevronRight' : null}
          iconPosition={isBiggerThan('phone') ? 'right' : null}
          isNavLink
          theme="primary"
        >
          {t('CART.TO_CHECKOUT')}
        </Button>
      </div>
    </section>
  );
};

export default ShoppingCartOverview;

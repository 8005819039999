import { FC, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useResponsive, useStoreType } from '@hooks';
import { Button, Icon, Image } from '@shared';
import * as DropdownButton from '@shared/button/dropdownButton';
import { Tooltip } from '@shared/tooltip';
import { sortAlphabetically } from '@utils/arrayHelpers';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useCompanyContext } from '../../../company/_context/CompanyContext';
import { SELECTED_COMPANY_STORAGE_KEY } from '../../../company/_hooks/useCurrentCompany';
import { env } from '../../../env';
import { TLanguage, TStoreLink } from '../../../general/_models/general';
import { useCustomerContext } from '../../../shoppingCart/_context/CustomerContext';
import Menu from '../menu/Menu';

import LanguagePickerDropdown from './components/LanguagePickerDropdown';

import './header.scss';

const HEADER_ICON_SIZE = 1.5;

type TProps = {
  isCheckout?: boolean;
  languages: TLanguage[];
  logo: string;
  storeLinks?: TStoreLink[];
};

const Header: FC<TProps> = ({ logo, languages, storeLinks = [] }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { login, logout, oidcUser } = useReactOidc();
  const { isB2B, isB2C } = useStoreType();
  const { isSmallerThan } = useResponsive();
  const isTablet = isSmallerThan('tablet');
  const { customer, isGuest, cartCount, updateShoppingCartId } = useCustomerContext();
  const { selectedCompany } = useCompanyContext();
  const { pathname: windowPathname } = window.location;
  const isAdmin = isB2B ? customer?.role === 'Admin' : isB2C;
  const isCheckout = pathname.includes('/checkout');

  function handleLogout() {
    setLoading(true);
    logout();
    sessionStorage.removeItem(SELECTED_COMPANY_STORAGE_KEY);
  }

  function handleLogin() {
    setLoading(true);
    login();
  }

  return (
    <header className={classnames('global-header', { 'b2b-header': isB2B, 'b2c-header': isB2C })}>
      {!isTablet && !isCheckout && (
        <div className="global-header__links">
          <div className="container">
            <nav aria-label="Shops" className="global-header__links__shops">
              <ul>
                {sortAlphabetically('type', storeLinks).map(storeLink => {
                  const [, type] = windowPathname.split('/');
                  const canHaveAriaCurrentPage = storeLink.type === type;
                  return (
                    <li key={storeLink.id}>
                      <a
                        className="store-link"
                        href={`${storeLink.webshopHost}/products`}
                        onClick={() => {
                          if (oidcUser) {
                            updateShoppingCartId();
                          }
                        }}
                        {...(canHaveAriaCurrentPage ? { 'aria-current': 'page' } : {})}
                      >
                        {t(storeLink.translationKey) === storeLink.translationKey ? storeLink.name : t(storeLink.translationKey)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className="global-header__links__external-links">
              <ul>
                <li>
                  <Button href={t('SHARED.NAVIGATION.FAQ.LINK')} icon="CiQuestion" iconSize={1.4} theme="plain-link">
                    {t('SHARED.NAVIGATION.FAQ.LABEL')}
                  </Button>
                </li>
                <li>
                  <Button href={t('SHARED.NAVIGATION.KINEPOLIS.LINK')} theme="plain-link">
                    {t('SHARED.NAVIGATION.KINEPOLIS.LABEL')}
                  </Button>
                </li>
              </ul>
              <LanguagePickerDropdown languages={languages} />
            </div>
          </div>
        </div>
      )}
      <div className="global-header__content">
        <div className="container">
          <div className="global-header__content__navigation">
            {isTablet && !isCheckout && <Menu languages={languages} />}
            <Button href="/" theme="plain-link">
              <Image alt="Home" className="global-header__content__logo" src={logo} />
            </Button>
            {!isTablet && !isGuest && (
              <div className="global-header__content__auth">
                <div className="global-header__content__auth__info">
                  <span>
                    {t('CUSTOMER_PORTAL.LOGGED_IN_AS', {
                      loggedInAs: `${oidcUser?.profile?.given_name ?? ''} ${oidcUser?.profile?.family_name ?? ''}`.trim(),
                    })}
                  </span>
                  {isB2B && selectedCompany && (
                    <div className="global-header__content__auth__admin">
                      {selectedCompany?.invoiceDetails?.companyName.toUpperCase() + ' - '}
                      {isAdmin ? t('SHARED.GENERAL.IS_ADMIN') : t('SHARED.GENERAL.IS_NOT_ADMIN')}
                      <Tooltip
                        iconSize={1}
                        text={isAdmin ? t('SHARED.GENERAL.ADMIN_INFO') : t('SHARED.GENERAL.NOT_ADMIN_INFO')}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {!isCheckout && (
            <nav aria-label="Account" className="global-header__content__actions">
              {isGuest ? (
                <Button hideLabel={isTablet} icon="Person" loading={loading} onClick={handleLogin} theme="secondary">
                  {t('SHARED.GENERAL.LOGIN')}
                </Button>
              ) : (
                <DropdownButton.Trigger
                  hideLabel={isTablet}
                  icon={isTablet ? 'Person' : undefined}
                  label={t('SHARED.GENERAL.MY_ACCOUNT')}
                  theme="light-transparent"
                >
                  {isB2C && (
                    <>
                      <DropdownButton.Item href="/account">
                        <div className="dropdown-item-content">
                          <Icon name="Person" size={HEADER_ICON_SIZE} />
                          {t('CUSTOMER_PORTAL.ACCOUNT_INFO.TITLE')}
                        </div>
                      </DropdownButton.Item>
                      <DropdownButton.Item href="/account/orders">
                        <div className="dropdown-item-content">
                          <Icon name="OrderBox" size={HEADER_ICON_SIZE} />
                          {t('CUSTOMER_PORTAL.ORDERS.TITLE')}
                        </div>
                      </DropdownButton.Item>
                    </>
                  )}
                  {isB2B && (
                    <>
                      {selectedCompany && (
                        <span className="selected-company-dropdown-header">
                          <p>{t('CUSTOMER_PORTAL.SELECTED_COMPANY')}</p>
                          <p className="company-name">{selectedCompany?.invoiceDetails?.companyName}</p>
                        </span>
                      )}
                      <DropdownButton.Item href="/pick-company">
                        <div className="dropdown-item-content">
                          <Icon name="Company" size={HEADER_ICON_SIZE} />
                          {t('SHARED.GENERAL.PICK_COMPANY')}
                        </div>
                      </DropdownButton.Item>
                      <DropdownButton.Item href="/account">
                        <div className="dropdown-item-content">
                          <Icon name="Person" size={HEADER_ICON_SIZE} />
                          {t('CUSTOMER_PORTAL.ACCOUNT_INFO.TITLE')}
                        </div>
                      </DropdownButton.Item>
                      <DropdownButton.Item href="/account/orders">
                        <div className="dropdown-item-content">
                          <Icon name="OrderBox" size={HEADER_ICON_SIZE} />
                          {t('CUSTOMER_PORTAL.ORDERS.TITLE')}
                        </div>
                      </DropdownButton.Item>
                    </>
                  )}
                  <DropdownButton.Item href={env.REACT_APP_RESET_PASSWORD}>
                    <div className="dropdown-item-content">
                      <Icon name="Key" size={HEADER_ICON_SIZE} />
                      {t('CUSTOMER_PORTAL.ACCOUNT_INFO.RESET_PASSWORD')}
                    </div>
                  </DropdownButton.Item>
                  <DropdownButton.Item onClick={handleLogout}>
                    <div className="dropdown-item-content">
                      <Icon name="Logout" size={HEADER_ICON_SIZE} />
                      {t('SHARED.GENERAL.LOGOUT')}
                    </div>
                  </DropdownButton.Item>
                </DropdownButton.Trigger>
              )}
              {(isB2C || !isGuest) && (
                <Button hideLabel={isTablet} href="/cart" icon="Cart" notification={cartCount} theme="primary">
                  {t('SHARED.GENERAL.SHOPPING_CART')}
                </Button>
              )}
            </nav>
          )}

          {isCheckout && !isTablet && (
            <Button href="/" icon="ChevronRight" iconPosition="right" theme="plain-link">
              {t('SHARED.NAVIGATION.CONTINUE_SHOPPING')}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;

import { ApiError } from '@http';
import { useMutation } from 'react-query';

type TPickupPointRequest = {
  carrier: string; // BPOST or DHL
  countryCode: string; // supported countries 'BE' , 'NL' , 'DE' , 'LU' , 'FR'
  number?: string;
  street?: string;
  zipCode: string;
};

type PickupPointAddressAddress = {
  city: string;
  extension: null | string;
  number: string;
  street: string;
  zipcode: string;
};

export type TPickupPoint = {
  Address: PickupPointAddressAddress;
  distance: number;
  id: string;
  latitude: number;
  longitude: number;
  name: string;
};

type TPickupPointResponse = {
  AsyncState: string;
  CreationOptions: number;
  Exception: string;
  Id: number;
  IsCanceled: boolean;
  IsCompleted: boolean;
  IsFaulted: boolean;
  Result: { Shops: TPickupPoint[] };
  Status: number;
};
function getPickupPoints(access_token: string, requestParams: TPickupPointRequest): Promise<TPickupPoint[]> {
  const headers = {
    Authorization: `Bearer ${access_token}`,
    'Content-Type': 'application/json',
  };

  const queryParams = new URLSearchParams(requestParams).toString();
  const url = `https://production.sb.hf62.bme.be/api/parcelshop/locator?${queryParams}`;

  return fetch(url, { headers, method: 'GET' })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      return (data as TPickupPointResponse)?.Result?.Shops ?? [];
    });
}

export function useGetPickupPoints() {
  return useMutation<TPickupPoint[], ApiError, { access_token: string; body: TPickupPointRequest }>(
    ['pickupPoints'],
    ({ access_token, body }) => getPickupPoints(access_token, body),
    { onError: () => {} },
  );
}

import { FC, useState } from 'react';

import { Button } from '@shared';
import { Tooltip } from '@shared/tooltip';
import { FileType, downloadFileFromBase64 } from '@utils/fileHelpers';
import { useTranslation } from 'react-i18next';

import { useGetBase64Invoice } from '../../_queries';

type TProps = { countryCode: string; orderNumber: string };

const DownloadInvoiceButton: FC<TProps> = ({ countryCode, orderNumber }) => {
  const { t } = useTranslation();
  const { refetch, isLoading, isError } = useGetBase64Invoice(orderNumber, countryCode);
  const [creatingPdf, setCreatingPdf] = useState(false);

  const handleDownload = async () => {
    setCreatingPdf(true);
    const { data: invoiceResponse } = await refetch();

    if (invoiceResponse) {
      await downloadFileFromBase64(invoiceResponse, `Kinepolis_Invoice_${orderNumber}`, FileType.PDF);
    }
    setCreatingPdf(false);
  };

  return (
    <div className="download-invoice">
      <Button
        className="download-button"
        disabled={isError}
        loading={isLoading || creatingPdf}
        onClick={async () => handleDownload()}
        theme="primary"
      >
        {t('CUSTOMER_PORTAL.ORDERS.DETAIL.DOWNLOAD_INVOICE')}
      </Button>
      {isError && <Tooltip icon="Question" text={t('CUSTOMER_PORTAL.ORDERS.NO_INVOICE_TOOLTIP')} />}
    </div>
  );
};

export default DownloadInvoiceButton;

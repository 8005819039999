import { FC, useCallback, useEffect, useRef, useState } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin';
import { useStoreType } from '@hooks';
import { Spinner } from '@shared';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { log } from '../../_logs';
import { TAdyenData } from '../../checkout/_models/payment';
import getAdyenLocale from '../../checkout/_util/getAdyenLocale';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { env } from '../../env';
import { useGetConfiguration } from '../../general/_queries/useGetConfiguration';
import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';
import { googleAnalyticsAddPaymentInfoEvent } from '../../googleAnalytics/dataLayer';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import {
  setTimeout as setTimeoutAction,
  setOrderId as setOrderIdAction,
  setPaymentMethod as setPaymentMethodAction,
} from '../_context/checkoutActions';
import usePaymentData from '../_hooks/usePaymentData';
import usePollPaymentState from '../_hooks/usePollPaymentState';
import { PaymentResultCode, PaymentState } from '../_models/confirmation';
import { useGetPaymentMethods, useSubmitPayment, useUnlockShoppingCart } from '../_queries';
import { AUTHORIZED_TIMEOUT } from '../_util/constants';
import PayByLink from '../payByLink/PayByLink';

import '@adyen/adyen-web/dist/adyen.css';
import './payment.scss';

type TProps = {
  onSubmit: () => void;
};

type PaymentLogError = {
  order?: string;
  orderId?: string;
  orderNumber?: string;
  resultCode?: PaymentResultCode;
};

type PaymentStateLogError = {
  orderNumber?: string;
  state: PaymentState;
};

const Payment: FC<TProps> = ({ onSubmit }) => {
  const { isB2B } = useStoreType();
  const { getPayment } = usePaymentData();
  const { selectedCompany } = useCompanyContext();
  const { data: config } = useGetConfiguration();
  const { data: generalInfo } = useGetGeneralInfo();
  const { data: paymentMethods } = useGetPaymentMethods();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const history = useHistory();

  // state
  const [adyenInstance, setAdyenInstance] = useState<DropinElement>();
  const [orderId, setOrderId] = useState<string>();
  const { updateShoppingCartId, shoppingCartId, cart } = useCustomerContext();
  const { dispatch, state } = useCheckoutContext();
  const [isPayByLink, setIsPayByLink] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPayByLinkRadioChecked, setIsPayByLinkRadioChecked] = useState(false);

  // refs
  const containerRef = useRef<HTMLDivElement>(null);
  const { mutateAsync: submitPayment } = useSubmitPayment();
  const { mutateAsync: unlockShoppingCart } = useUnlockShoppingCart();
  const timeout = useRef<ReturnType<typeof setTimeout | undefined>>();

  const onSubmitPayment = useCallback(
    async (adyenData: TAdyenData) => {
      const payment = getPayment(adyenData);
      const data = await submitPayment(payment);

      setOrderId(data.orderId);
    },
    [getPayment, submitPayment],
  );

  const onSelect = useCallback(() => setIsPayByLinkRadioChecked(false), []);

  // data handling
  const fontStyle = {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 300,
  };

  const styles = {
    base: {
      color: generalInfo?.brandingInfo?.colors?.primaryText,
      ...fontStyle,
    },
    error: {
      color: generalInfo?.brandingInfo?.colors?.error,
      ...fontStyle,
    },
    placeholder: {
      color: generalInfo?.brandingInfo?.colors?.secondaryText,
      ...fontStyle,
    },
    validated: {
      color: generalInfo?.brandingInfo?.colors?.primaryText,
      ...fontStyle,
    },
  };

  useEffect(() => {
    let ignore = false;

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        amount: { currency: generalInfo.currency, value: cart?.total * 100 },
        clientKey: config?.adyenKey,
        environment: env.REACT_APP_ADYEN_ENVIRONMENT === 'test' ? 'test' : 'live',
        locale: getAdyenLocale(i18n.language),
        onSubmit: async ({ data }) => {
          if (onSubmit) {
            await onSubmitPayment(data);
          }
        },
        paymentMethodsConfiguration: {
          bcmc: {
            styles,
          },
          card: {
            styles,
          },
        },
        paymentMethodsResponse: paymentMethods,
      });

      if (containerRef.current && !ignore) {
        setAdyenInstance(
          checkout
            .create('dropin', {
              onSelect,
              openFirstPaymentMethod: false,
            })
            .mount(containerRef.current),
        );
      }
    };

    createCheckout();

    return () => {
      ignore = true;
    };
  }, []);

  const { paymentData: { state: paymentState, orderNumber, resultCode, action } = {} } = usePollPaymentState(orderId);

  const canPayLater = selectedCompany?.isPayLaterEnabled;
  const showPayByLink = isB2B;

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      unlockShoppingCart({ shoppingCartId: shoppingCartId });
    };
  }, []);

  useEffect(() => {
    if (isPayByLink && paymentState === PaymentState.WAITING_FOR_PAYMENT && action) {
      // PayByLink will never get a complete state because order can be payed later
      dispatch(setOrderIdAction(orderNumber));
      dispatch(setPaymentMethodAction('paybylink'));
      onSubmit();
    } else if (paymentState === PaymentState.CANCELLED) {
      if (resultCode != PaymentResultCode.AMOUNT_INVALID) toast.error(t('CHECKOUT.PAYMENT.PAYMENT_CANCELLED'));

      adyenInstance?.setStatus('ready');
    } else if (paymentState === PaymentState.COMPLETE) {
      clearTimeout(timeout.current);
      dispatch(setOrderIdAction(orderNumber));
      onSubmit();
    } else if (paymentState === PaymentState.VISTA_SYNC_FAILED) {
      dispatch(setTimeoutAction(resultCode));
      onSubmit();

      // Notify the log service.
      log<PaymentStateLogError>('error', {
        orderNumber,
        state: paymentState,
      });
    } else if (paymentState === PaymentState.SHOPPING_CART_LOCKED) {
      toast.error(t('CHECKOUT.FATAL_ERROR'));
      updateShoppingCartId();
      history.push('/');

      // Notify the log service.
      log<PaymentStateLogError>('error', {
        orderNumber,
        state: paymentState,
      });
    }
  }, [
    paymentState,
    action,
    adyenInstance,
    dispatch,
    onSubmit,
    timeout,
    updateShoppingCartId,
    history,
    t,
    isPayByLink,
    orderNumber,
    resultCode,
  ]);

  useEffect(() => {
    if (!resultCode) return;
    switch (resultCode) {
      case PaymentResultCode.REDIRECT_SHOPPER:
      case PaymentResultCode.PENDING:
        localStorage.setItem('orderId', orderId);
        localStorage.setItem('order', JSON.stringify(state));
        adyenInstance?.handleAction(JSON.parse(action));
        break;
      case PaymentResultCode.AMOUNT_INVALID:
        toast.error(t('CHECKOUT.PAYMENT.AMOUNT_INVALID'));
        adyenInstance?.setStatus('ready');
        break;
      case PaymentResultCode.AUTHORISATION:
      case PaymentResultCode.AUTHORISED:
        timeout.current = setTimeout(() => {
          dispatch(setTimeoutAction(resultCode));
          onSubmit();
        }, AUTHORIZED_TIMEOUT);
        break;
      case PaymentResultCode.CANCELLED:
        toast.error(t('CHECKOUT.PAYMENT.PAYMENT_CANCELLED'));
        adyenInstance?.setStatus('ready');
        break;
      case PaymentResultCode.ERROR:
      case PaymentResultCode.REFUSED:
        toast.error(t('CHECKOUT.PAYMENT.PAYMENT_ERROR'));
        adyenInstance?.setStatus('ready');

        // Notify the log service.
        log<PaymentLogError>('error', {
          order: JSON.stringify(state),
          orderId,
          orderNumber,
          resultCode,
        });

        break;
    }
  }, [resultCode, action, adyenInstance, dispatch, onSubmit, t, orderId, state, orderNumber]);

  useEffect(() => {
    if (!adyenInstance) return;
    adyenInstance.setStatus(isPending ? 'loading' : 'ready');
  }, [isPending, adyenInstance]);

  useEffect(() => {
    if (!adyenInstance?.dropinRef?.state?.status?.type) return;

    setIsPending(adyenInstance?.dropinRef?.state?.status?.type === 'loading');
  }, [adyenInstance?.dropinRef?.state?.status?.type]);

  const lastSelectedPaymentMethod = useRef<string | null>(null);

  useEffect(() => {
    const handleClick = () => {
      const selectedPaymentMethodName = document.querySelector(
        '.adyen-checkout__payment-method__name.adyen-checkout__payment-method__name--selected',
      )?.textContent;

      if (selectedPaymentMethodName && selectedPaymentMethodName !== lastSelectedPaymentMethod.current) {
        googleAnalyticsAddPaymentInfoEvent(cart, selectedPaymentMethodName);
        lastSelectedPaymentMethod.current = selectedPaymentMethodName;
      }
    };

    const container = containerRef?.current;
    if (container) {
      container?.addEventListener('click', handleClick);
    }

    return () => {
      container?.removeEventListener('click', handleClick);
    };
  }, [cart]);

  useEffect(() => {
    if (isPayByLinkRadioChecked) {
      const selectedPaymentMethodName = 'PayByLink';
      if (selectedPaymentMethodName !== lastSelectedPaymentMethod.current) {
        googleAnalyticsAddPaymentInfoEvent(cart, selectedPaymentMethodName);
        lastSelectedPaymentMethod.current = selectedPaymentMethodName;
      }
    }
  }, [isPayByLinkRadioChecked, cart]);

  return (
    <div className="payments">
      <div className="payments__direct">
        {showPayByLink && <h1 className="payments__direct__title">{t('CHECKOUT.PAYMENT.DIRECT_PAYMENT.TITLE')}</h1>}
        <div id="dropin-container" ref={containerRef}>
          <Spinner />
        </div>
      </div>
      {showPayByLink && (
        <PayByLink
          canPayLater={canPayLater}
          checked={isPayByLinkRadioChecked}
          isPending={isPending}
          onSelect={checked => {
            setIsPayByLinkRadioChecked(checked);
            adyenInstance.closeActivePaymentMethod();
          }}
          onSubmit={adyenData => {
            setIsPayByLink(true);
            setIsPending(true);
            return onSubmitPayment(adyenData);
          }}
        />
      )}
    </div>
  );
};

export default Payment;

import { ApiError, HttpClient } from '@http';
import { useMutation, useQueryClient } from 'react-query';

type TShoppingCartUnlockBody = {
  shoppingCartId: string;
};

function unlockShoppingCart(shoppingCartId: string): Promise<null> {
  return HttpClient.post(`shoppingcart/${shoppingCartId}/unlock`);
}

export function useUnlockShoppingCart() {
  const queryClient = useQueryClient();

  return useMutation<null, ApiError, TShoppingCartUnlockBody>(
    'unlockShoppingCart',
    body => unlockShoppingCart(body.shoppingCartId),
    {
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('shoppingCart');
      },
    },
  );
}

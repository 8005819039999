import { useQuery } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { TOrderDetail } from '../_models/order';

export type TGetOrderDetailResponse = {
  data: TOrderDetail;
};

function getOrderDetail(orderId: string): Promise<TOrderDetail> {
  return HttpClient.get(`orders/${orderId}`);
}

export function useGetOrderDetail(orderId: string) {
  return useQuery<TOrderDetail, ApiError>(['orders', orderId], () => getOrderDetail(orderId), {
    cacheTime: 0,
    onError: () => {
      //Do nothing
    },
  });
}

import { FC, useEffect, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useResponsive } from '@hooks';
import { HttpMetadataPagingResponse, HttpMetadataQuery, HttpSortDirection } from '@http';
import { Button, Icon, Pagination } from '@shared';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useGetOrders } from '../_queries';

import MobileOrdersOverview from './components/MobileOrdersTable';
import OrdersTable from './components/OrdersTable';
import './orderOverview.scss';

const OrderOverview: FC = () => {
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('tablet');
  const { t } = useTranslation();
  const { login } = useReactOidc();
  const { isGuest } = useCustomerContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<HttpMetadataQuery>({
    skip: 0,
    sortBy: 'dateOfOrder',
    sortDirection: HttpSortDirection.Descending,
    take: 10,
  });

  const { data: ordersResponse, isLoading, refetch } = useGetOrders(pagination);

  const metaDataResponse = {
    count: ordersResponse?.count,
    skip: pagination.skip,
    totalCount: ordersResponse?.totalCount,
  } as HttpMetadataPagingResponse;

  useEffect(() => {
    refetch();
  }, [pagination]);

  function handleLogin() {
    setLoading(true);
    login();
  }

  if (isGuest)
    return (
      <div className="guest">
        <Button
          className="guest__login-button"
          hideLabel={isMobile}
          icon="Person"
          loading={loading}
          onClick={handleLogin}
          theme="secondary"
        >
          {t('SHARED.GENERAL.LOGIN')}
        </Button>
      </div>
    );

  return (
    <>
      <Helmet>
        <title>{t('CUSTOMER_PORTAL.TITLE') + ' | ' + t('CUSTOMER_PORTAL.ORDERS.TITLE')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
      <div className="back-to-shop-wrapper">
        <Button className="back-button" href="/" icon="ChevronLeft" iconPosition="left" theme="plain-link">
          {t('SHARED.BUTTONS.BACK_TO_SHOP')}
        </Button>
      </div>
      <h1 className={!isMobile ? '' : 'visually-hidden'}> {t('CUSTOMER_PORTAL.ORDERS.TITLE')}</h1>

      <div className={isMobile ? 'orders-overview' : undefined}>
        <h2 className={isMobile ? 'h4' : 'visually-hidden'}>
          <Icon className="orders-overview__icon" name="OrderBox" size={2.4} />
          {t('CUSTOMER_PORTAL.ORDERS.TITLE')}
        </h2>
        {isMobile ? (
          <MobileOrdersOverview isLoading={isLoading} orders={ordersResponse?.data} />
        ) : (
          <OrdersTable isLoading={isLoading} orders={ordersResponse?.data} setQuery={setPagination} />
        )}
        <Pagination metadata={metaDataResponse} query={pagination} setQuery={setPagination} />
      </div>
    </>
  );
};

export default OrderOverview;

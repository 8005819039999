import { createElement, FC, ReactNode } from 'react';

import rehypeExternalLinks from 'rehype-external-links';
import rehypeReact from 'rehype-react';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

const processor = unified()
  .use(remarkParse)
  .use(remarkRehype)
  .use(rehypeExternalLinks, {
    rel: ['nofollow', 'noopener', 'noreferrer'],
    target: '_blank',
  })
  .use(rehypeReact, { createElement });

type TProps = {
  children: ReactNode;
};

const Markdown: FC<TProps> = ({ children }) => {
  if (typeof children !== 'string') {
    return null;
  }
  // Replace '\\n' with '\n' to correctly interpret line breaks
  const content = children.replaceAll('\\n', '\n');
  return <div className="content-block">{processor.processSync(content).result}</div>;
};

export default Markdown;

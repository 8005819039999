import { useStoreType } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TPhysicalAddress } from '../_models/address';

function prepareBody(address: TPhysicalAddress) {
  // rename countryIsoCode to country
  const { country: countryIsoCode, ...newBody } = address;
  return { ...newBody, countryIsoCode };
}

function createB2CPhysicalShippingAdress(address: TPhysicalAddress, userId: string): Promise<null> {
  return HttpClient.post(`customers/${userId}/physical-shipping-addresses`, prepareBody(address));
}

function createB2BPhysicalShippingAdress(address: TPhysicalAddress, companyId: string): Promise<null> {
  return HttpClient.post(
    `b2b/companies/${companyId}/contact-person/physical-shipping-addresses`,

    prepareBody(address),
  );
}

export function useCreatePhysicalShippingAddress() {
  const queryClient = useQueryClient();
  const { isB2C } = useStoreType();
  const { selectedCompany } = useCompanyContext();
  const { customer: { id: userId } = {} } = useCustomerContext();

  return useMutation<null, ApiError, TPhysicalAddress>(
    'updatePhysicalShippingAdresses',
    body => (isB2C ? createB2CPhysicalShippingAdress(body, userId) : createB2BPhysicalShippingAdress(body, selectedCompany?.id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
      },
    },
  );
}

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useStoreType } from '@hooks';
import { ApiError, HttpClient } from '@http';
import { useQuery } from 'react-query';

import { useCompanyContext } from '../../company/_context/CompanyContext';
import { TBackendContactPerson, TBackendCustomer, TContactPerson, TCustomer } from '../_models/customer';

async function getCustomer(): Promise<TCustomer> {
  const result = await HttpClient.get<TBackendCustomer>('customers');

  return {
    ...result,
    physicalAddresses: result.physicalAddresses.map(({ countryIsoCode: country, ...address }) => ({ ...address, country })),
  };
}

async function getContactPerson(companyId: string): Promise<TContactPerson> {
  if (!companyId) return;
  const result = await HttpClient.get<TBackendContactPerson>(`b2b/companies/${companyId}/contact-person`);

  return {
    ...result,
    digitalAddresses: result.digitalShippingAddresses,
    physicalAddresses: result.physicalShippingAddresses?.map(({ countryIsoCode: country, ...address }) => ({
      ...address,
      country,
    })),
  };
}

export function useGetCustomer() {
  const { oidcUser } = useReactOidc();
  const { isB2C, isB2B } = useStoreType();
  const { selectedCompany } = useCompanyContext();

  return useQuery<TCustomer | TContactPerson, ApiError>(
    ['customer', selectedCompany?.id],
    () => (isB2C ? getCustomer() : getContactPerson(selectedCompany?.id)),
    {
      enabled: !!oidcUser && (isB2B ? !!selectedCompany : true),
    },
  );
}

import { FC, useState } from 'react';

import { useToggle } from '@hooks';
import { Button, InputField } from '@shared';
import { useTranslation } from 'react-i18next';

import { useAddDiscount } from '../_queries';
import './discountForm.scss';

const DiscountForm: FC = () => {
  const { t } = useTranslation();
  const [hasNoDiscount, toggle] = useToggle(true);
  const [discountCode, setDiscountCode] = useState('');
  const { mutate: addDiscount, isLoading: isDiscountLoading } = useAddDiscount();

  return (
    <div className="discount-form">
      {hasNoDiscount ? (
        <Button icon="Plus" iconSize={1.2} onClick={toggle} theme="plain-link">
          {t('CART.DISCOUNTS.ADD_DISCOUNT')}
        </Button>
      ) : (
        <form
          onSubmit={event => {
            event.preventDefault();
            addDiscount(discountCode);
            setDiscountCode('');
          }}
        >
          <>
            <InputField
              autoComplete="off"
              hideAsterisk
              label={t('CART.DISCOUNTS.DISCOUNT_CODE')}
              name="discountCode"
              onChange={(value: string) => setDiscountCode(value)}
              value={discountCode}
            />

            <Button disabled={isDiscountLoading} icon="CircledCheck" iconSize={1.6} theme="secondary" type="submit">
              {t('CART.DISCOUNTS.APPLY')}
            </Button>
          </>
        </form>
      )}
    </div>
  );
};

export default DiscountForm;

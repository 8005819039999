import { useState, useEffect, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../_hooks';
import { FillMetadataQueryFunction, HttpMetadataQuery } from '../../_http';
import Button from '../button/Button';
import InputField from '../input/inputField/InputField';
import './searchField.scss';

type TProps = {
  query?: HttpMetadataQuery;
  setQuery: FillMetadataQueryFunction;
};

function shouldSearch(currentQuery: HttpMetadataQuery, searchString: string): boolean {
  if (currentQuery?.search === searchString) return false;
  if (!currentQuery?.search && !searchString) return false;
  return true;
}

const SearchField: FC<TProps> = ({ query, setQuery }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (shouldSearch(query, debouncedSearch)) {
      setQuery({ search: debouncedSearch, skip: 0 });
    }
  }, [debouncedSearch, query, setQuery]);

  return (
    <div className="search-field-wrapper">
      <InputField
        autoComplete="off"
        className="search-field"
        icon={
          <Button className="search-field-icon" icon={search ? 'Close' : 'Search'} onClick={() => setSearch('')}>
            {t(search ? 'SHARED.SEARCH.CLOSE_SEARCH' : 'SHARED.SEARCH.SUBMIT')}
          </Button>
        }
        name="search"
        onChange={(value: string) => setSearch(value)}
        placeholder={t('SHARED.SEARCH.PLACEHOLDER')}
        value={search}
      />
    </div>
  );
};

export default SearchField;

import { FC } from 'react';

import { formatPrice } from '@utils/formatHelpers';
import { TOrderProductsOverview } from 'customerPortal/_models';
import { useTranslation } from 'react-i18next';

import './mobileProductsTable.scss';

type TProps = {
  products: TOrderProductsOverview;
};

const MobileProductsTable: FC<TProps> = ({ products }) => {
  const { t } = useTranslation();

  return (
    <div className="mobile-table" key={'mobile-table-wrapper'}>
      <ul className="mobile-table__products" key={'products-list'}>
        {products.items.map((row, i) => (
          <div className="mobile-table__group" key={i}>
            <li className="mobile-table__line" key={'name-' + i}>
              <div>
                <span className="mobile-table__line__product-name">{row.name}</span>
              </div>
            </li>

            <li className="mobile-table__line" key={'unit-price-' + i}>
              <div>
                <span className="mobile-table__label">{t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.UNIT_PRICE') + ': '}</span>
                <span className="mobile-table__value">{row?.price}</span>
              </div>
            </li>

            <li className="mobile-table__line" key={'amount-' + i}>
              <div>
                <span className="mobile-table__label">{t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.AMOUNT') + ': '}</span>
                <span className="mobile-table__value">{row.amount}</span>
              </div>
            </li>

            <li className="mobile-table__line" key={'total-price-' + i}>
              <div>
                <span className="mobile-table__label">{t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.TOTAL_PRICE') + ': '}</span>
                <span className="mobile-table__value">{formatPrice(row.price * row.amount)}</span>
              </div>
            </li>
          </div>
        ))}
      </ul>
      <ul className="global-information" key={'global-info'}>
        <li className="global-information__row">
          <span className="mobile-table__label">{t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.SUBTOTAL')}</span>
          <span className="mobile-table__value">{formatPrice(products.subtotal)}</span>
        </li>
        <li className="global-information__row">
          <span className="mobile-table__label">{t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.SHIPPING')}</span>
          <span className="mobile-table__value">{formatPrice(products.shippingCosts)}</span>
        </li>
        <li className="global-information__row">
          <span className="mobile-table__label">
            {`${t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.TOTAL')} ${t('PRODUCT.VAT_INCLUDED')}`}
          </span>
          <span className="mobile-table__value">{formatPrice(products.total)}</span>
        </li>
      </ul>
    </div>
  );
};

export default MobileProductsTable;

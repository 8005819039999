import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../_context/CustomerContext';

function removeDiscount(shoppingCartId: string, discountId: string): Promise<void> {
  return HttpClient.delete(`shoppingcart/${shoppingCartId}/discountcodes/${discountId}`, discountId);
}

export function useRemoveDiscount() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { shoppingCartId } = useCustomerContext();

  return useMutation<void, ApiError, string>(
    'removeDiscount',
    discountId => {
      return removeDiscount(shoppingCartId, discountId);
    },
    {
      onSuccess: () => {
        toast.success(t('CART.DISCOUNTS.REMOVE_DISCOUNT_SUCCESS'));
        queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);
      },
    },
  );
}

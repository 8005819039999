import { FC, useEffect, useState } from 'react';

import { useTableSort } from '@hooks';
import { HttpSortDirection } from '@http';
import { Table } from '@shared';
import { TableColumn } from '@shared/table/Table';
import { formatPrice } from '@utils/formatHelpers';
import { useTranslation } from 'react-i18next';

import { TOrderProductsOverview, TOrderProduct } from '../../_models/order';
import './productsTable.scss';

type TProps = {
  overview: TOrderProductsOverview;
};

const ProductsTable: FC<TProps> = ({ overview }) => {
  const { i18n, t } = useTranslation();
  const [data, setData] = useState<TOrderProduct[]>([]);

  const { sortFunctions } = useTableSort(
    (column, direction) =>
      setData(data => {
        if (direction === HttpSortDirection.Ascending) {
          return data.sort((a, b) => (a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0));
        }
        return data.sort((a, b) => (a[column] < b[column] ? 1 : a[column] > b[column] ? -1 : 0));
      }),
    'name',
  );

  const columns: TableColumn[] = [
    { className: 'name', label: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.NAME'), name: 'name', sortable: false },
    { className: 'unit-price', label: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.UNIT_PRICE'), name: 'unitPrice', sortable: false },
    { className: 'amount', label: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.AMOUNT'), name: 'amount', sortable: false },
    { className: 'total-price', label: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.TOTAL_PRICE'), name: 'totalPrice', sortable: false },
  ];

  useEffect(() => {
    if (overview?.items) {
      setData(
        overview.items.map(item => ({
          ...item,
          totalPrice: item.price * item.amount,
        })),
      );
    }
  }, [overview]);

  return (
    <>
      <Table
        className="products-table"
        columns={columns}
        data={[
          ...data,
          { name: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.SUBTOTAL'), totalPrice: formatPrice(overview.subtotal) },
          { name: t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.SHIPPING'), totalPrice: formatPrice(overview.shippingCosts) },
          {
            name: `${t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.TOTAL')} ${t('PRODUCT.VAT_INCLUDED')}`,
            totalPrice: formatPrice(overview.total),
          },
        ]}
        emptyLabel={t('CUSTOMER_PORTAL.ORDERS.PRODUCTS.EMPTY')}
        isLoading={false}
        renderRow={(row: TOrderProduct & { totalPrice: number }) => {
          const name = row.translations?.find(t => t.key == "product.name" && t.langaugeCode == i18n.language)?.value ?? row.name;
          
          return (
            <tr key={row.name}>
              <td className="name">
                <span>{name}</span>
              </td>
              <td className="unit-price">{row.price && row.amount && <span>{formatPrice(row?.price)}</span>}</td>
              <td className="amount">
                <span>{row.amount}</span>
              </td>

              <td className="total-price">{row.totalPrice && <span>{formatPrice(row.totalPrice)}</span>}</td>
            </tr>
          );
        }}
        sortFunctions={sortFunctions}
      />
    </>
  );
};

export default ProductsTable;

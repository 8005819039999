const getAdyenLocale = (language: string): string => {
  // https://docs.adyen.com/online-payments/web-drop-in/customization#language-and-localization
  const languages = {
    ca_EN: 'en-US',
    de_CH: 'de-DE',
    en_LU: 'en-US',
    en_US: 'en-US',
    es_CA: 'es-ES',
    es_ES: 'es-ES',
    fr_BE: 'fr-FR',
    fr_LU: 'fr-FR',
    nl_BE: 'nl-NL',
    nl_NL: 'nl-NL',
  };

  return languages?.[language] ?? 'en-US';
};

export default getAdyenLocale;

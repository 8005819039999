import { useEffect, useState } from 'react';

/* Keep in sync _breakpoints.scss */
const breakpoints = {
  bigMonitor: 1920,
  desktop: 1025,
  monitor: 1280,
  phone: 632,
  tablet: 864,
};

const maxFix = 0.2;

export type BreakpointName = 'bigMonitor' | 'desktop' | 'monitor' | 'phone' | 'tablet';

type TResponse = {
  isBiggerThan: (breakpoint: BreakpointName) => boolean;
  isSmallerThan: (breakpoint: BreakpointName) => boolean;
};

function useResponsive(): TResponse {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isBiggerThan: (breakpoint: BreakpointName) => {
      return breakpoints[breakpoint] < width;
    },
    isSmallerThan: (breakpoint: BreakpointName) => {
      return breakpoints[breakpoint] - maxFix >= width;
    },
  };
}

export default useResponsive;

import { getCountryCodeFromDomain } from '@utils/countries';
import { useQuery } from 'react-query';

import { HttpClient, HttpStatus } from '../../_http';

const DEFAULT_COUNTRY_CODE = getCountryCodeFromDomain().toUpperCase();

type invoiceApiError = {
  error: {
    error: string;
    statusCode: HttpStatus;
  };
};

function getBase64Invoice(orderNumber: string, countryCode: string = DEFAULT_COUNTRY_CODE): Promise<string> {
  return HttpClient.get<string>(`invoice/base64?orderNumber=${orderNumber}&countryCode=${countryCode}`, {}, {}, 'invoices');
}

export function useGetBase64Invoice(orderNumber: string, countryCode: string) {
  return useQuery<string, invoiceApiError>(['invoices'], () => getBase64Invoice(orderNumber, countryCode), {
    cacheTime: 0,
    enabled: !!orderNumber,
    onError() {
      //do nothing
    },
  });
}

import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { useResponsive, useStoreType } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Dropdown, GoogleMapsAddressLookup, InputField } from '@shared';
import Phone from '@shared/input/phone/Phone';
import { countries, getCountries } from '@utils/countries';
import { useTranslation } from 'react-i18next';

import { TPhysicalAddress, TAddressType } from '../_models/address';

import { TAddressFields } from './Address';

type TProps = {
  autoFocus?: boolean;
  setAttribute: (value: unknown, name: string) => void;
  setFormValues?: Dispatch<SetStateAction<TPhysicalAddress | TAddressFields>>;
  type?: TAddressType;
  validationErrors: FormValidationErrors<TPhysicalAddress>;
  values: TPhysicalAddress;
};

const AddressFields: FC<TProps> = ({
  autoFocus = false,
  setAttribute,
  setFormValues,
  type = 'shipping',
  validationErrors,
  values,
}) => {
  const { t } = useTranslation();
  const { isSmallerThan } = useResponsive();
  const isSmallerThanTablet = isSmallerThan('tablet');
  const { isB2C } = useStoreType();

  const { phoneNumber, phoneNumberPrefix, country } = values;

  useEffect(() => {
    setAttribute(false, 'isPickupPoint');
    setAttribute('', 'pickupPointId');
  }, []);

  return (
    <>
      <fieldset>
        <InputField
          autoComplete="name"
          autoFocus={autoFocus}
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.NAME')}
          name="name"
          onChange={setAttribute}
          required
          validation={validationErrors?.name}
          value={values?.name}
        />
        <InputField
          autoComplete="additional-name"
          className={isSmallerThanTablet ? undefined : 'input-75'}
          label={t('CHECKOUT.ADDRESS.ATTN')}
          name="attn"
          onChange={setAttribute}
          validation={validationErrors?.attn}
          value={values?.attn}
        />
      </fieldset>

      <fieldset>{setFormValues && <GoogleMapsAddressLookup setFormValues={setFormValues} />}</fieldset>

      <fieldset>
        <InputField
          autoComplete="address-line1"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.STREET')}
          name="street"
          onChange={setAttribute}
          required
          validation={validationErrors?.street}
          value={values?.street}
        />
        <InputField
          autoComplete="address-line2"
          className={isSmallerThanTablet ? undefined : 'input-25'}
          label={t('CHECKOUT.ADDRESS.NUMBER')}
          name="number"
          onChange={setAttribute}
          required
          validation={validationErrors?.number}
          value={values?.number}
        />
        <InputField
          autoComplete="address-line3"
          className={isSmallerThanTablet ? undefined : 'input-25'}
          label={t('CHECKOUT.ADDRESS.BOX')}
          name="box"
          onChange={setAttribute}
          validation={validationErrors?.box}
          value={values?.box}
        />
        <InputField
          autoComplete="postal-code"
          className={isSmallerThanTablet ? undefined : 'input-25'}
          label={t('CHECKOUT.ADDRESS.POSTAL_CODE')}
          name="postalCode"
          onChange={setAttribute}
          required
          validation={validationErrors?.postalCode}
          value={values?.postalCode}
        />
        <InputField
          autoComplete="address-level2"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.CITY')}
          name="city"
          onChange={setAttribute}
          required
          validation={validationErrors?.city}
          value={values?.city}
        />
        {isB2C ? (
          <InputField
            className={isSmallerThanTablet ? undefined : 'input-50'}
            disabled={isB2C}
            label={t('CHECKOUT.ADDRESS.COUNTRY')}
            name="country"
            onChange={setAttribute}
            required
            validation={validationErrors?.country}
            value={t(countries.find(country => country.value === values?.country).label)}
          />
        ) : (
          <Dropdown
            className={isSmallerThanTablet ? undefined : 'input-50'}
            disabled={isB2C}
            hasNoEmtyOption
            label={t('CHECKOUT.ADDRESS.COUNTRY')}
            name="country"
            onChange={setAttribute}
            options={getCountries()}
            required
            validation={validationErrors?.country}
            value={values.country}
          />
        )}
      </fieldset>
      <fieldset>
        {type === 'shipping' && 'phoneNumberPrefix' in values && (
          <Phone
            countryIsoCode={country}
            setAttribute={setAttribute}
            validation={validationErrors}
            values={{ phoneNumber, phoneNumberPrefix }}
          />
        )}
      </fieldset>
      {type === 'shipping' && <p className="disclaimer disclaimer--phone">{t('CHECKOUT.ADDRESS.PHONE_DISCLAIMER')}</p>}
    </>
  );
};

export default AddressFields;

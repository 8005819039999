import { FC } from 'react';

import { useResponsive } from '@hooks';
import { Button } from '@shared';
import { TIconType } from '@shared/icon/Icon';
import { useTranslation } from 'react-i18next';

import { TSocialMedia } from '../../../../general/_models/general';

type TProps = {
  socialMedia: TSocialMedia;
};

type TSocialListItem = {
  icon: TIconType;
  label: string;
  link: string;
  name: keyof TSocialMedia;
};

const SocialMedia: FC<TProps> = ({ socialMedia }) => {
  const { t } = useTranslation();
  const { isBiggerThan } = useResponsive();
  const iconSize = isBiggerThan('tablet') ? 2.4 : 3.5;
  const socialListItem: TSocialListItem[] = [
    {
      icon: 'Facebook',
      label: t('FOOTER.SOCIAL_MEDIA.FACEBOOK.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK'),
      name: 'facebook',
    },
    {
      icon: 'Instagram',
      label: t('FOOTER.SOCIAL_MEDIA.INSTAGRAM.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK'),
      name: 'instagram',
    },
    {
      icon: 'Youtube',
      label: t('FOOTER.SOCIAL_MEDIA.YOUTUBE.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.YOUTUBE.LINK'),
      name: 'youtube',
    },
    {
      icon: 'LinkedIn',
      label: t('FOOTER.SOCIAL_MEDIA.LINKEDIN.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK'),
      name: 'linkedin',
    },
    {
      icon: 'TwitterX',
      label: t('FOOTER.SOCIAL_MEDIA.TWITTER.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.TWITTER.LINK'),
      name: 'twitter',
    },
    {
      icon: 'TikTok',
      label: t('FOOTER.SOCIAL_MEDIA.TIKTOK.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.TIKTOK.LINK'),
      name: 'tiktok',
    },
  ];

  return (
    <div className="footer__social-media">
      <h2 className="h5">{t('FOOTER.SOCIAL_MEDIA.TITLE')}</h2>
      <ul>
        {socialListItem
          .filter(item => socialMedia?.[item.name])
          .map(({ icon, label, link }) => (
            <li key={link}>
              <Button hideLabel href={link} icon={icon} iconSize={iconSize} theme="plain-link">
                {label}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SocialMedia;

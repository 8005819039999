import { FC, useState, useEffect } from 'react';

import { useResponsive } from '@hooks';
import { Button } from '@shared';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { HttpMetadataPagingResponse, HttpMetadataQuery } from '../../_http';
import { Dropdown } from '../input';

import './pagination.scss';

type TProps = {
  metadata: HttpMetadataPagingResponse;
  query: HttpMetadataQuery;
  setQuery: (newQuery: React.SetStateAction<HttpMetadataQuery>) => void;
};

const MAX_NUMBER_OF_PAGES = 5;
const limitOptions = [10, 25, 50, 100].map(option => ({ label: option.toString(), value: option }));

const computePages = (total: number, currentPage: number) => {
  if (total < MAX_NUMBER_OF_PAGES) {
    return new Array(total).fill(null).map((_, i) => i + 1);
  } else {
    return new Array(MAX_NUMBER_OF_PAGES).fill(null).map((_, i) => {
      if (currentPage < MAX_NUMBER_OF_PAGES) return i + 1;
      if (currentPage === total) return total - MAX_NUMBER_OF_PAGES + i + 1;
      return i + currentPage - 3;
    });
  }
};

const Pagination: FC<TProps> = ({ metadata, query, setQuery }) => {
  const { t } = useTranslation();
  const { isSmallerThan } = useResponsive();
  const isTablet = isSmallerThan('tablet');

  const total = Math.ceil(metadata?.totalCount / query.take);
  const currentPage = query.skip / query.take + 1;

  const [pages, setPages] = useState(computePages(total, currentPage));

  useEffect(() => {
    setPages(computePages(total, currentPage));
  }, [total, currentPage]);

  return (
    <div className="pagination-wrapper">
      <div className="pagination">
        {!isTablet && (
          <div className="pagination__limit">
            <span>{t('SHARED.PAGINATION.ITEMS_PER_PAGE')}</span>
            <Dropdown
              className="limit"
              hasNoEmtyOption
              name="limit"
              onChange={(value: number) => setQuery(prev => ({ ...prev, skip: 0, take: value }))}
              options={limitOptions}
              value={query?.take}
            />
          </div>
        )}
        <nav className="pagination__navigation">
          <Button
            className="nav-button"
            disabled={query.skip === 0}
            onClick={() => setQuery(prev => ({ ...prev, skip: query.skip - query.take }))}
            theme={isTablet ? 'light-transparent' : 'plain-link'}
          >
            {t('SHARED.BUTTONS.PREVIOUS')}
          </Button>
          {isTablet ? (
            <span>{t('SHARED.PAGINATION.PAGE', { count: currentPage, total })}</span>
          ) : (
            <ul>
              {pages.map(page => (
                <li className={classnames('page', { ['current-page']: page === currentPage })} key={page}>
                  <button className="plain" onClick={() => setQuery(prev => ({ ...prev, skip: (page - 1) * query.take }))}>
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          )}
          <Button
            className="nav-button"
            disabled={query.skip + query.take >= metadata.totalCount}
            onClick={() => setQuery(prev => ({ ...prev, skip: query.skip + query.take }))}
            theme={isTablet ? 'light-transparent' : 'plain-link'}
          >
            {t('SHARED.BUTTONS.NEXT')}
          </Button>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;

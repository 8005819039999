import { getUserManager } from '@axa-fr/react-oidc-context';
import axios, { AxiosError, AxiosResponse, ResponseType } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { SELECTED_COMPANY_STORAGE_KEY } from '../company/_hooks/useCurrentCompany';
import { Config } from '../config';

import { ApiError } from './HttpError';
import { HttpStatus } from './HttpStatus';

type Params = Record<string, string | number | boolean | null | undefined>;
type Headers = Record<string, string>;

type ApiBase = 'shop' | 'invoices' | 'vouchers';

const userManager = getUserManager();
const refreshAuthLogic = () => userManager.revokeAccessToken();

createAuthRefreshInterceptor(axios, refreshAuthLogic);

class HttpClient {
  static getUrl(route: string, apiBase: ApiBase): string {
    const [path, kinepolisDomain, extension] = window.location.host.split('.');
    const apiPath = path.replace('shop', 'shop-api');
    const host = `${apiPath}.${kinepolisDomain}.${extension}`;

    if (route.indexOf('http://') === 0 || route.indexOf('https://') === 0 || route.indexOf('www.') === 0) {
      return route;
    }

    switch (apiBase) {
      case 'vouchers':
        return `${Config.vouchersApi}${route}`;
      case 'invoices':
        return `${Config.invoicesApi}${route}`;
      default:
        return `https://${host}/api/${route}`;
    }
  }

  static getUrlWithParams(route: string, params: Params, apiBase: ApiBase = 'shop'): string {
    let url = HttpClient.getUrl(route, apiBase);
    if (params) {
      for (const property in params) {
        if (params[property] !== null && params[property] !== undefined) {
          url = HttpClient.addQueryStringParameter(url, property, `${params[property]}`);
        }
      }
    }
    return url;
  }

  static addQueryStringParameter(uri: string, key: string, value: string): string {
    const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(regex)) {
      return uri.replace(regex, `$1${key}=${value}$2`);
    }
    return `${uri + separator + key}=${value}`;
  }

  static parseRequestPayload<T>(object: T): T {
    return Object.keys(object).reduce(
      (acc: T, key: string) => ({ ...acc, [key]: object[key] === '' ? null : object[key] }),
      {} as T,
    );
  }

  static getBasicHeaders(): Headers {
    const user = JSON.parse(sessionStorage.getItem(`oidc.user:${Config.authority}:${Config.clientId}`));
    let headers: Headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'KPIC-STORE-ID': sessionStorage.getItem('kpic-store-id'),
    };

    const selectedCompanyId = sessionStorage.getItem(SELECTED_COMPANY_STORAGE_KEY);
    if (selectedCompanyId) {
      headers = {
        ...headers,
        'KPIC-SELECTED-COMPANY-ID': selectedCompanyId,
      };
    }

    if (user?.access_token) {
      return {
        ...headers,
        Authorization: 'Bearer ' + user.access_token,
      };
    }
    return headers;
  }

  static createApiError(error: AxiosError): ApiError {
    if (error.response) {
      const data: { detail: string; status: HttpStatus; title: string } = error.response.data;
      return {
        error: error.response.data,
        message: data?.detail,
        statusCode: data?.status,
        title: data?.title,
      };
    }
    return {
      message: error.message,
      statusCode: HttpStatus.InternalServerError,
    };
  }

  static async getRaw<T>(
    route: string,
    params: Params = {},
    headers: Headers = {},
    apiBase: ApiBase = 'shop',
    responseType: ResponseType = 'json',
  ): Promise<AxiosResponse<T>> {
    try {
      return await axios.get<T>(this.getUrlWithParams(route, params, apiBase), {
        headers: { ...this.getBasicHeaders(), ...headers },
        responseType,
        withCredentials: true,
      });
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async get<T>(
    route: string,
    params: Params = {},
    headers: Headers = {},
    apiBase: ApiBase = 'shop',
    responseType: ResponseType = 'json',
  ): Promise<T> {
    const result = await this.getRaw<T>(route, params, headers, apiBase, responseType);
    return result.data;
  }

  static async put<T>(
    route: string,
    body: Record<string, unknown> | Record<string, unknown>[] = {},
    headers: Headers = {},
    params: Params = {},
    apiBase: ApiBase = 'shop',
  ): Promise<T> {
    try {
      const result = await axios.put<T>(this.getUrlWithParams(route, params, apiBase), body, {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async patch<T>(
    route: string,
    body: Record<string, unknown> = {},
    headers: Headers = {},
    apiBase: ApiBase = 'shop',
  ): Promise<T> {
    try {
      const result = await axios.patch<T>(this.getUrl(route, apiBase), body, {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async post<T>(
    route: string,
    body: Record<string, unknown> = {},
    headers: Headers = {},
    apiBase: ApiBase = 'shop',
  ): Promise<T> {
    try {
      const result = await axios.post<T>(this.getUrl(route, apiBase), body, {
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw this.createApiError(error);
    }
  }

  static async delete<T>(route: string, body: string, headers: Headers = {}, apiBase: ApiBase = 'shop'): Promise<T> {
    try {
      const result = await axios.delete(this.getUrl(route, apiBase), {
        data: body,
        headers: { ...this.getBasicHeaders(), ...headers },
        withCredentials: true,
      });
      return result.data || true;
    } catch (error) {
      throw this.createApiError(error);
    }
  }
}

export default HttpClient;

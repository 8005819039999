import { useEffect } from 'react';

import { getCountryCodeFromDomain } from '@utils/countries';
import TagManager from 'react-gtm-module';

import { initializeDataLayer } from './dataLayer';
import { gtmIds, TGTMIds } from './shared/gtmIds';

export const useGoogleTagManager = () => {
  useEffect(() => {
    const hostname: string = window.location.hostname;
    let countryCode: keyof TGTMIds;

    // Check for test domain
    if (hostname === 'shop-acc.kinepolis.be') {
      countryCode = 'shop-acc.kinepolis.be';
    } else {
      countryCode = getCountryCodeFromDomain().toUpperCase() as keyof TGTMIds;
    }

    const gtmId = gtmIds[countryCode];

    if (gtmId) {
      // Initialize the data layer
      initializeDataLayer();

      // Initialize GTM
      TagManager.initialize({ gtmId: gtmId });

      // Insert GTM snippet if not already present
      if (!document.querySelector('iframe[src^="https://www.googletagmanager.com/ns.html"]')) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.cssText = 'display:none; visibility:hidden;';
        noscript.appendChild(iframe);
        document.body.insertBefore(noscript, document.body.firstChild);
      }
    } else {
      console.warn(`GTM ID not found for shop country: ${countryCode}`);
    }
  }, []);
};

export default useGoogleTagManager;

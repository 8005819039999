import { TAdyenConfig } from 'checkout/_models/payment';

export function getBrowserInfo(): Omit<TAdyenConfig, 'paymentMethod'> {
  const screenWidth = window && window.screen ? window.screen.width : 0;
  const screenHeight = window && window.screen ? window.screen.height : 0;
  const colorDepth = window && window.screen ? window.screen.colorDepth : 0;
  const userAgent = window && window.navigator ? window.navigator.userAgent : '';
  const javaEnabled = false;
  const language = window && window.navigator ? window.navigator.language : '';

  const d = new Date();
  const timeZoneOffset = d.getTimezoneOffset();

  const browserInfo = {
    acceptHeader: '*/*',
    colorDepth,
    javaEnabled,
    language,
    screenHeight,
    screenWidth,
    timeZoneOffset,
    userAgent,
  };

  return browserInfo;
}

import { ApiError, HttpClient } from '@http';
import { useQuery } from 'react-query';

import { TOrderConfirmation, PaymentResultCode, PaymentState } from '../_models/confirmation';

async function getPayment(orderId: string): Promise<TOrderConfirmation> {
  const { resultCode, state, ...data } = await HttpClient.get<TOrderConfirmation>(`payments/${orderId}`);

  return {
    ...data,
    resultCode: resultCode?.toLowerCase() as PaymentResultCode,
    state: state.toLowerCase() as PaymentState,
  };
}

export function useGetPayment(orderId: string, enabled = true) {
  return useQuery<TOrderConfirmation, ApiError>(['payment', orderId], () => getPayment(orderId), {
    enabled,
    onError: ({ statusCode }) => {
      if (statusCode === 404) return;
    },
  });
}

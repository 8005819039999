import { FC, useId, useState } from 'react';

import { useStoreType } from '@hooks';
import { Button, Icon, Radio } from '@shared';
import { TPhysicalAddress, TDigitalAddress } from 'checkout/_models/address';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useDeletePhysicalShippingAddress } from '../../../checkout/_queries';

interface TProps<T> {
  addresses: T[];
  checkedId?: string;
  disableEdit?: boolean;
  onChange: (address: T) => void;
  onEdit?: (address: T) => void;
}

const ShippingAddresses: FC<TProps<TPhysicalAddress | TDigitalAddress>> = ({
  addresses,
  checkedId,
  disableEdit = false,
  onChange,
  onEdit,
}) => {
  const { t } = useTranslation();
  const componentId = useId();
  const { isB2C } = useStoreType();
  const { mutateAsync: deletePhysicalAddress, isLoading: isDeleteAddressLoading } = useDeletePhysicalShippingAddress();
  const [toDeleteId, setToDeleteId] = useState('');

  if (addresses.length === 0) return null;

  return (
    <ul className={classNames(['shipping-list', isB2C ? 'shipping-list__B2C' : ''])} key={componentId}>
      {addresses.map((address, index) => (
        <li className="shipping-list__item" key={`${componentId}-${index}`}>
          <Radio checked={address?.id === checkedId} name="shipping" onChange={() => onChange(address)}>
            <address>
              {'name' in address ? (
                <>
                  {address.name}
                  <br />
                  {address?.attn && (
                    <>
                      {address.attn}
                      <br />
                    </>
                  )}
                  {address.street} {' ' + address.number} {address.box ? ' /' + address?.box : ''}
                  <br />
                  {address.postalCode} {address.city}
                  <br />
                  {address.country}
                </>
              ) : (
                address.email
              )}
            </address>
            {address?.default && (
              <p className="shipping-list__item__default">{t('CHECKOUT.OVERVIEW.SHIPPING_MODAL.DEFAULT_SHIPPING_ADDRESS')}</p>
            )}
          </Radio>
          <span className="edit-and-delete">
            {isB2C && (
              <Button
                className="delete-address"
                loading={isDeleteAddressLoading && toDeleteId == address?.id}
                onClick={async () => {
                  setToDeleteId(address?.id);
                  await deletePhysicalAddress({ addressId: address?.id });
                  setToDeleteId('');
                }}
                theme="primary-link"
              >
                <Icon name="Trash" />
              </Button>
            )}
            {!disableEdit && (
              <Button hideLabel icon="Pencil" iconPosition="right" onClick={() => onEdit(address)} theme="plain-link">
                {t('SHARED.BUTTONS.EDIT')}
              </Button>
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default ShippingAddresses;

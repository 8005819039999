import { FC, useEffect } from 'react';

import { useResponsive } from '@hooks';
import Multistep from '@shared/multistep/multistep';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation, Redirect, useHistory, matchPath, useRouteMatch } from 'react-router';

import { log } from '../_logs';
import { googleAnalyticsBeginCheckoutEvent } from '../googleAnalytics/dataLayer';
import { useCustomerContext } from '../shoppingCart/_context/CustomerContext';

import { useCheckoutContext } from './_context/CheckoutContext';
import { setShippingDetails, setStep } from './_context/checkoutActions';
import useCheckoutUser from './_hooks/useCheckoutUser';

import { Details, Confirmation, Overview, Payment, Address } from '.';

import './checkout.scss';

type TStep = {
  Component: FC<{ backPath?: string; onSubmit?: (index: number) => void }>;
  label: string;
  path: string;
  title?: string;
};

type CheckoutLogError = {
  orderId?: string;
  timeout?: string;
};

const guestSteps: TStep[] = [
  {
    Component: Details,
    label: 'CHECKOUT.YOUR_DETAILS.TITLE',
    path: '/details',
  },
  {
    Component: Address,
    label: 'CHECKOUT.ADDRESS.LABEL',
    path: '/address',
    title: 'CHECKOUT.ADDRESS.TITLE',
  },
];

const requiredSteps: TStep[] = [
  {
    Component: Overview,
    label: 'CHECKOUT.OVERVIEW.TITLE',
    path: '/overview',
  },
  {
    Component: Payment,
    label: 'CHECKOUT.PAYMENT.TITLE',
    path: '/payment',
  },
  {
    Component: Confirmation,
    label: 'CHECKOUT.CONFIRMATION.LABEL',
    path: '/confirmation',
    title: 'CHECKOUT.CONFIRMATION.TITLE',
  },
];

const Checkout: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const { isSmallerThan } = useResponsive();
  const { isGuest, cart, isLoading: cartIsLoading } = useCustomerContext();
  const { digitalShippingAddresses } = useCheckoutUser();
  const { dispatch, state } = useCheckoutContext();
  const steps = isGuest ? [...guestSteps, ...requiredSteps] : requiredSteps;

  useEffect(() => {
    googleAnalyticsBeginCheckoutEvent(cart);

    if (!isGuest && digitalShippingAddresses?.length && !state?.digitalAddress?.email?.length) {
      const digitalAddress = digitalShippingAddresses[0];
      dispatch(
        setShippingDetails({
          digitalAddress,
        }),
      );
    }
  }, []);

  const advance = (index: number) => {
    dispatch(setStep(index + 1));
    history.push(`${match.path}${steps[index + 1].path}`);
  };

  const step = Math.max(
    steps.findIndex(({ path }) => matchPath(pathname, `${match.path}${path}`)),
    0,
  );
  const isAtLastStep = step + 1 === steps.length;
  const shouldShowResult = state?.timeout || state?.orderId;

  if (!cart && cartIsLoading && !shouldShowResult) return null;
  if (!shouldShowResult && (isAtLastStep || cart?.items.length === 0)) {
    return <Redirect to="/cart" />;
  } else if (!(isAtLastStep && shouldShowResult) && state.step < step) {
    return <Redirect to={`${match.path}${steps[state.step].path}`} />;
  }

  const { label } = steps?.[step] ?? {};
  let { title } = steps?.[step] ?? {};

  if (isAtLastStep && state.timeout && !state.orderId) {
    switch (state.timeout) {
      case 'authorisation':
      case 'authorised':
      case 'received':
        title = t('CHECKOUT.CONFIRMATION.SYNC_ERROR.TITLE');

        log<CheckoutLogError>('error', {
          orderId: state.orderId,
          timeout: state.timeout,
        });
        break;
      case 'pending':
        title = t('CHECKOUT.CONFIRMATION.PAYMENT_PENDING.TITLE');
        break;
      default:
        title = t('CHECKOUT.CONFIRMATION.SYNC_ERROR.TITLE');

        log<CheckoutLogError>('error', {
          orderId: state.orderId,
          timeout: state.timeout,
        });
        break;
    }
  }

  return (
    <>
      <Multistep
        allowBack={step + 1 !== steps.length}
        currentStep={step + 1}
        label={t('CHECKOUT.STEPS.TITLE')}
        steps={steps.map(step => ({ ...step, path: `${match.path}${step.path}` }))}
      />
      <section className="checkout">
        <Helmet>
          <title>{t(title ?? label)}</title>
          <meta content="noindex, nofollow" name="robots" />
        </Helmet>
        <h1
          className={classNames('page-title', {
            'visually-hidden': isSmallerThan('tablet') && step !== steps.length - 1,
          })}
        >
          {t(title ?? label)}
          <span className="visually-hidden">
            {t('CHECKOUT.STEPS.STEP_N_OF_Y', {
              current: step + 1,
              total: steps.length,
            })}
          </span>
        </h1>
        <Switch>
          {steps.map(({ Component, path }, index) => (
            <Route
              exact
              key={path}
              path={`${match.path}${path}`}
              render={() => (
                <Component
                  backPath={index ? `${match.path}${steps?.[index - 1]?.path}` : '/cart'}
                  onSubmit={steps.length - 1 !== index ? () => advance(index) : undefined}
                />
              )}
            />
          ))}
          <Redirect to={`${match.path}${steps[0].path}`} />
        </Switch>
      </section>
    </>
  );
};

export default Checkout;

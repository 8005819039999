import { Dispatch, FC, SetStateAction } from 'react';

import { useResponsive, useStoreType } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Dropdown, GoogleMapsAddressLookup, InputField } from '@shared';
import Phone from '@shared/input/phone/Phone';
import { getCountries, countries } from '@utils/countries';
import { useTranslation } from 'react-i18next';

import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';
import { TInvoiceDetails } from '../_models/invoice';

type TProps = {
  disableAddressLookup?: boolean;
  setAttribute: (value: unknown, name: string) => void;
  setFormValues: Dispatch<SetStateAction<TInvoiceDetails>>;
  validationErrors: FormValidationErrors<TInvoiceDetails>;
  values: TInvoiceDetails;
};

const InvoiceFields: FC<TProps> = ({ validationErrors, values, setAttribute, setFormValues, disableAddressLookup = false }) => {
  const { t } = useTranslation();
  const { isSmallerThan } = useResponsive();
  const isSmallerThanTablet = isSmallerThan('tablet');
  const { isB2B, isB2C } = useStoreType();
  const { data: generalInfo } = useGetGeneralInfo();
  const { mobilePhoneNumber, mobilePhoneNumberPrefix, phoneNumber, phoneNumberPrefix, countryIsoCode } = values;

  const getCountryCodePrefix = (countryIsoCode: string) => {
    const country = countries.find(c => c.value === countryIsoCode);
    return country ? country.vatPrefix : '';
  };

  const onVatNumberBlur = () => {
    if (values.countryIsoCode && values.vat) {
      const prefix = getCountryCodePrefix(values.countryIsoCode);
      const vatNumber = values.vat;
      if (vatNumber.indexOf(prefix) === 0 && vatNumber.length === prefix.length) {
        setAttribute('', 'vatNumber');
      }
    }
  };

  const onVatNumberFocus = () => {
    if (values.countryIsoCode && (!values.vat || values.vat.length <= 2)) {
      const prefix = getCountryCodePrefix(values.countryIsoCode);
      if (!values.vat || values.vat.length <= 2) {
        setAttribute(prefix, 'vatNumber');
      }
    }
  };

  return (
    <>
      <fieldset>
        <InputField
          autoComplete="organization"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={isB2C ? t('CHECKOUT.ADDRESS.NAME') : t('CHECKOUT.ADDRESS.COMPANY_NAME')}
          name="companyName"
          onChange={setAttribute}
          required
          validation={validationErrors?.companyName}
          value={values?.companyName}
        />

        <InputField
          autoComplete="email"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.EMAIL')}
          name="email"
          onChange={setAttribute}
          required
          validation={validationErrors?.email}
          value={values.email}
        />
        {isB2C ? (
          <InputField
            className={isSmallerThanTablet ? undefined : 'input-50'}
            disabled={isB2C}
            label={t('CHECKOUT.ADDRESS.COUNTRY')}
            name="countryIsoCode"
            onChange={setAttribute}
            required
            validation={validationErrors?.countryIsoCode}
            value={t(countries.find(country => country.value === values?.countryIsoCode).label)}
          />
        ) : (
          <Dropdown
            className={isSmallerThanTablet ? undefined : 'input-50'}
            disabled={isB2C}
            hasNoEmtyOption
            label={t('CHECKOUT.ADDRESS.COUNTRY')}
            name="countryIsoCode"
            onChange={setAttribute}
            options={getCountries()}
            required
            validation={validationErrors?.countryIsoCode}
            value={values.countryIsoCode}
          />
        )}

        {generalInfo?.entrepriseNumbersEnabled && <br />}

        <InputField
          autoComplete="off"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          disabled={isB2B}
          helpText={t('REGISTER_COMPANY.FORM.VAT_NUMBER.HELP')}
          helpTextTitle={t('REGISTER_COMPANY.FORM.VAT_NUMBER.HELP_TITLE')}
          label={t('CHECKOUT.ADDRESS.VAT_NUMBER')}
          name="vat"
          normalize={value => value.replace(/[.,]/g, '').toUpperCase()}
          onBlur={onVatNumberBlur}
          onChange={setAttribute}
          onFocus={onVatNumberFocus}
          required={!values?.enterpriseNumber.length}
          validation={validationErrors?.vat}
          value={values?.vat}
        />

        {generalInfo?.entrepriseNumbersEnabled ? (
          <InputField
            autoComplete="off"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            disabled={isB2B}
            helpText={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.HELP')}
            helpTextTitle={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.HELP_TITLE')}
            hideAsterisk
            label={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.LABEL')}
            name="enterpriseNumber"
            normalize={value => value.replace(/[.,]/g, '')}
            onChange={setAttribute}
            required={!values?.vat.length}
            validation={validationErrors?.enterpriseNumber}
            value={values?.enterpriseNumber}
          />
        ) : (
          <br />
        )}
      </fieldset>

      {!disableAddressLookup && <GoogleMapsAddressLookup setFormValues={setFormValues} />}

      <fieldset>
        <InputField
          autoComplete="off"
          className={isSmallerThanTablet ? undefined : 'input-75'}
          label={t('REGISTER_COMPANY.FORM.ADDRESS.EXTRA_INFO')}
          name="extraInfo"
          onChange={setAttribute}
          validation={validationErrors?.extraInfo}
          value={values?.extraInfo}
        />

        <InputField
          autoComplete="address-line1"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.STREET')}
          name="street"
          onChange={setAttribute}
          required
          validation={validationErrors?.street}
          value={values?.street}
        />

        <InputField
          autoComplete="address-line2"
          className={isSmallerThanTablet ? undefined : 'input-25'}
          label={t('CHECKOUT.ADDRESS.NUMBER')}
          name="number"
          onChange={setAttribute}
          required
          validation={validationErrors?.number}
          value={values?.number}
        />

        <InputField
          autoComplete="address-line3"
          className={isSmallerThanTablet ? 'box' : 'input-25'}
          label={t('CHECKOUT.ADDRESS.BOX')}
          name="box"
          onChange={setAttribute}
          validation={validationErrors?.box}
          value={values?.box}
        />
        <InputField
          autoComplete="postal-code"
          className={isSmallerThanTablet ? undefined : 'input-25'}
          label={t('CHECKOUT.ADDRESS.POSTAL_CODE')}
          name="postalCode"
          onChange={setAttribute}
          required
          validation={validationErrors?.postalCode}
          value={values?.postalCode}
        />
        <InputField
          autoComplete="address-level2"
          className={isSmallerThanTablet ? undefined : 'input-50'}
          label={t('CHECKOUT.ADDRESS.CITY')}
          name="city"
          onChange={setAttribute}
          required
          validation={validationErrors?.city}
          value={values?.city}
        />
      </fieldset>

      <fieldset className="phone-numbers">
        {'phoneNumberPrefix' in values && (
          <Phone
            countryIsoCode={countryIsoCode}
            required={isB2B}
            setAttribute={setAttribute}
            validation={validationErrors}
            values={{ phoneNumber, phoneNumberPrefix }}
          />
        )}
        {'mobilePhoneNumberPrefix' in values && (
          <Phone
            countryIsoCode={countryIsoCode}
            isMobilePhoneNumber
            setAttribute={setAttribute}
            validation={validationErrors}
            values={{ mobilePhoneNumber, mobilePhoneNumberPrefix }}
          />
        )}
      </fieldset>
    </>
  );
};

export default InvoiceFields;

import { FC } from 'react';

import { Spinner } from '@shared';

const LoadingScreen: FC = () => (
  <div className="empty-screen">
    <Spinner />
  </div>
);

export default LoadingScreen;

import { FC, useEffect } from 'react';

import { useForm, useResponsive, useStoreType } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, InputField, TabBar } from '@shared';
import { getCountryCodeFromDomain } from '@utils/countries';
import { formValidator } from '@utils/formValidation';
import { TPickupPoint } from 'checkout/_queries';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { setShippingDetails } from '../_context/checkoutActions';
import { TCheckoutAddress } from '../_context/checkoutReducer';
import { TB2CInvoiceDetails, TInvoiceDetails } from '../_models/invoice';

import AddressFields from './AddressFields';
import SelectPickupPoint from './SelectPickupPoint';
import './address.scss';

type TProps = {
  backPath?: string;
  onSubmit: () => void;
};

export type TAddressFields = Omit<TCheckoutAddress, 'invoiceDetails'> & {
  invoiceDetails: TInvoiceDetails;
};

const Address: FC<TProps> = ({ backPath, onSubmit }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const { dispatch, state } = useCheckoutContext();
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const { isB2C } = useStoreType();
  const { containsPhysicalProducts, containsDigitalProducts, isLoading } = useCustomerContext();
  const { data: generalInfo } = useGetGeneralInfo();

  function validateForm(values: TAddressFields): FormValidationErrors<TAddressFields> {
    const physicalFields = {
      box: validator.box(values.physicalAddress.street, values.physicalAddress.box, values.physicalAddress.number),
      city: validator.city(values.physicalAddress.city),
      country: validator.required(values.physicalAddress.country),
      name: validator.name(values.physicalAddress.name),
      number: validator.streetNumber(values.physicalAddress.street, values.physicalAddress.box, values.physicalAddress.number),
      phoneNumber: validator.phoneNumber(values.physicalAddress.phoneNumber, values.physicalAddress.phoneNumberPrefix),
      phoneNumberPrefix: validator.required(values.physicalAddress.phoneNumberPrefix),
      postalCode: validator.postalCode(values.physicalAddress.postalCode),
      street: validator.street(values.physicalAddress.street, values.physicalAddress.box, values.physicalAddress.number),
    };

    const digitalFields = {
      email: validator.email(values.digitalAddress.email),
    };

    let validation: FormValidationErrors<TCheckoutAddress> = {};

    if (containsDigitalProducts) {
      validation = { ...validation, digitalAddress: digitalFields };
    }
    if (containsPhysicalProducts) {
      validation = { ...validation, physicalAddress: physicalFields };
    }

    if (values.wantInvoice && containsPhysicalProducts) {
      validation = {
        ...validation,
      };
    }
    return validation;
  }

  const submitForm = (values: TAddressFields) => {
    dispatch(setShippingDetails(values));
    onSubmit();
  };

  const { submit, values, setFormValues, validationErrors } = useForm({
    initialForm: {
      digitalAddress: state.digitalAddress,
      invoiceDetails: state.invoiceDetails as TB2CInvoiceDetails,
      physicalAddress: state.physicalAddress,
      wantInvoice: state?.wantInvoice || false,
    },
    submitForm,
    validateForm,
  });

  const setValues = target => (value, key) => {
    setFormValues(values => ({
      ...values,

      [target]: {
        ...values[target],
        [key]: value,
      },
    }));
  };

  const handlePickupPointSelect = (pickupPoint: TPickupPoint) => {
    setFormValues(currentValues => ({
      ...currentValues,
      physicalAddress: {
        ...currentValues?.physicalAddress,
        city: pickupPoint?.Address?.city,
        isPickupPoint: true,
        number: pickupPoint?.Address?.number,
        pickupPointId: pickupPoint?.id,
        postalCode: pickupPoint?.Address?.zipcode,
        street: pickupPoint?.Address?.street,
      },
    }));
  };

  useEffect(() => {
    if (containsDigitalProducts && !containsPhysicalProducts) {
      setFormValues(value => ({
        ...value,

        invoiceDetails: {
          companyName: state?.details?.firstName + ' ' + state?.details?.lastName || '',
          email: state?.details?.email || values?.digitalAddress || '',
          wantInvoice: false,
          ...values?.invoiceDetails,
        },
        wantInvoice: false,
      }));
    } else {
      setFormValues(value => ({
        ...value,
        invoiceDetails: {
          box: values?.physicalAddress?.box || '',
          city: values?.physicalAddress?.city || '',
          companyName: values?.physicalAddress?.name || '',
          countryIsoCode: getCountryCodeFromDomain().toUpperCase(),
          email: value.digitalAddress.email || '',
          enterpriseNumber: value.invoiceDetails.enterpriseNumber || '',
          number: values?.physicalAddress?.number || '',
          phoneNumber: values?.physicalAddress?.mobilePhoneNumber || '',
          phoneNumberPrefix: values?.physicalAddress?.phoneNumberPrefix || '',
          postalCode: values?.physicalAddress?.postalCode || '',
          street: values?.physicalAddress?.street || '',
          vat: value.invoiceDetails.vat,
          wantInvoice: false,
        },
        wantInvoice: false,
      }));
    }
  }, [values?.physicalAddress]);

  if (isLoading) return null;

  return (
    <div
      className={classnames('shipping', {
        'shipping--digital': containsDigitalProducts,
        'shipping--physical': containsPhysicalProducts,
      })}
    >
      <form onSubmit={submit}>
        {containsDigitalProducts && (
          <>
            <p className="disclaimer">
              {t(`CHECKOUT.ADDRESS.${containsPhysicalProducts ? 'PHYSICAL_AND_DIGITAL' : 'DIGITAL_ONLY'}`)}
            </p>

            <fieldset className="guest-email-wrapper">
              <InputField
                autoComplete="email"
                className="guest-email"
                label={t('CHECKOUT.ADDRESS.EMAIL')}
                name="email"
                onChange={setValues('digitalAddress')}
                required
                type="email"
                validation={validationErrors.digitalAddress?.email}
                value={values.digitalAddress.email}
              />
            </fieldset>
          </>
        )}
      </form>

      {containsPhysicalProducts &&
        (isB2C && generalInfo?.pickupPointsEnabled ? (
          <TabBar
            tabSelectedByDefault={values?.physicalAddress?.isPickupPoint ? 1 : 0}
            tabs={[
              {
                tabContent: (
                  <form onSubmit={submit}>
                    <AddressFields
                      setAttribute={setValues('physicalAddress')}
                      setFormValues={setFormValues}
                      validationErrors={validationErrors.physicalAddress}
                      values={values.physicalAddress}
                    />
                  </form>
                ),
                tabTitle: t('CHECKOUT.PICKUP_POINT.PHYSICAL_ADDRESS_TAB_TITLE'),
              },
              {
                tabContent: (
                  <SelectPickupPoint onPickupPointSelect={handlePickupPointSelect} prefillAddress={values?.physicalAddress} />
                ),
                tabTitle: t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_TAB_TITLE'),
              },
            ]}
          />
        ) : (
          <form onSubmit={submit}>
            <AddressFields
              setAttribute={setValues('physicalAddress')}
              setFormValues={setFormValues}
              validationErrors={validationErrors.physicalAddress}
              values={values.physicalAddress}
            />
          </form>
        ))}

      <div className={isMobile ? 'actions--stacked' : 'actions'}>
        <Button
          className="action__confirm"
          disabled={values.wantInvoice && !validationErrors}
          icon="ChevronRight"
          iconPosition="right"
          onClick={submit}
          theme="primary"
        >
          {t('SHARED.BUTTONS.CONTINUE')}
        </Button>
        {backPath && (
          <Button className="action__back" href={backPath} icon="ChevronLeft" theme="light-transparent">
            {t('SHARED.BUTTONS.BACK')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Address;

import tinycolor from 'tinycolor2';

import { TGeneralInfo } from './_models/general';

export const setBranding = ({ brandingInfo, announcementBackgroundColor, announcementTextColor }: TGeneralInfo) => {
  document.documentElement.style.setProperty('--announce-background', announcementBackgroundColor);
  document.documentElement.style.setProperty('--announce-text', announcementTextColor);

  Object.entries(brandingInfo.colors).map(([key, value]) => {
    const cssVariable = key
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase();
    document.documentElement.style.setProperty(`--${cssVariable}`, value);
    document.documentElement.style.setProperty(`--${cssVariable}-dark`, tinycolor(value).darken(12).saturate(15).toString());
  });
};

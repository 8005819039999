import { FC, useState } from 'react';

import { Button, Markdown } from '@shared';
import { useTranslation } from 'react-i18next';

import './announcement.scss';

type TProps = {
  isAnnouncementShown?: boolean;
};

const Announcement: FC<TProps> = ({ isAnnouncementShown }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(isAnnouncementShown);

  if (!isVisible || !isAnnouncementShown) return null;

  return (
    <div aria-live="polite" className="announcement">
      <div className="container">
        <Button
          className="announcement__close"
          hideLabel
          icon="Close"
          iconSize={1.4}
          onClick={() => setIsVisible(false)}
          theme="plain-link"
        >
          {t('SHARED.BUTTONS.CLOSE')}
        </Button>
        <Markdown>{t('ANNOUNCEMENT.TEXT')}</Markdown>
      </div>
    </div>
  );
};

export default Announcement;

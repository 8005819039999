import { TOrderDetail } from 'customerPortal/_models';
import { TProduct, TProductDetail } from 'product/_models';
import { TShoppingCart } from 'shoppingCart/_models';

const isB2B = window.location.pathname.includes('/B2B');

const AFFILIATION = `Kinepolis eshop ${isB2B ? 'B2B' : 'B2C'}`;
const BRAND = 'Kinepolis';
const CURRENCY = 'EUR';

export type TDataLayerObject = Record<string, unknown>;

export type TEcommerceItem = {
  affiliation: string;
  coupon?: string;
  currency?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_list_name?: string;
  item_name?: string;
  price?: number;
  quantity?: number;
};

// Type for the ecommerce object
export type TEcommerceObject = {
  coupon?: string;
  currency?: string;
  items?: TEcommerceItem[];
  payment_type?: string;
  shipping?: number;
  shipping_tier?: string;
  tax?: number;
  transaction_id?: string;
  value?: number;
};

const initializeDataLayer = (): void => {
  // Initialize the dataLayer if it doesn't exist
  window.dataLayer = window?.dataLayer || [];

  // Push the initial event to the dataLayer
  window?.dataLayer?.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });
};

const clearDataLayer = (): void => {
  window.dataLayer = [];
};

// Internal function to push events to the dataLayer
const pushEventToDataLayer = (dataLayerObject: TDataLayerObject): void => {
  window?.dataLayer?.push(dataLayerObject);
};

const googleAnalyticsViewItemEvent = (product: TProduct | TProductDetail): void => {
  const ecommerceObject: TEcommerceObject = {
    currency: CURRENCY,
    items: [
      {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_category: product?.isDigital ? 'digital' : 'physical',
        item_id: product.id,
        item_name: product.name,
        price: product.price,
      },
    ],
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'view_item' });
};

const googleAnalyticsAddToCartEvent = (ammount: number, product: TProduct | TProductDetail): void => {
  const ecommerceObject: TEcommerceObject = {
    currency: CURRENCY,
    items: [
      {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_category: product?.isDigital ? 'digital' : 'physical',
        item_id: product.id,
        item_name: product.name,
        price: product.price,
        quantity: ammount,
      },
    ],
  };

  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'add_to_cart' });
};

const googleAnalyticsRemoveFromCartEvent = (productId: string): void => {
  const ecommerceObject: TEcommerceObject = {
    currency: CURRENCY,
    items: [
      {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: productId,
      },
    ],
  };

  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'remove_from_cart' });
};

const googleAnalyticsViewCartEvent = (cart: TShoppingCart): void => {
  const cartItems = cart.items;
  const ecommerceObject: TEcommerceObject = {
    coupon: cart?.appliedDiscountCodes?.length ? cart?.appliedDiscountCodes[0]?.code : undefined,
    currency: CURRENCY,
    items: cartItems?.map(item => {
      return {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: undefined,
        item_name: item?.name,
        price: item?.price,
        quantity: item?.amount,
      };
    }),
    shipping: cart?.shippingCosts,
    value: cart?.total,
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'view_cart' });
};

const googleAnalyticsBeginCheckoutEvent = (cart: TShoppingCart): void => {
  const orderItems = cart?.items;
  const ecommerceObject: TEcommerceObject = {
    coupon: cart?.appliedDiscountCodes?.length ? cart?.appliedDiscountCodes[0]?.code : undefined,
    currency: CURRENCY,
    items: orderItems?.map(item => {
      return {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: undefined,
        item_name: item?.name,
        price: item?.price,
        quantity: item?.amount,
      };
    }),
    shipping: cart?.shippingCosts,
    value: cart?.total,
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'begin_checkout' });
};

const googleAnalyticsAddShippingInfoEvent = (cart: TShoppingCart): void => {
  const orderItems = cart?.items;
  const ecommerceObject: TEcommerceObject = {
    coupon: cart?.appliedDiscountCodes?.length ? cart?.appliedDiscountCodes[0]?.code : undefined,
    currency: CURRENCY,
    items: orderItems?.map(item => {
      return {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: undefined,
        item_name: item?.name,
        price: item?.price,
        quantity: item?.amount,
      };
    }),

    shipping: cart?.shippingCosts,
    value: cart?.total,
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'add_shipping_info' });
};

const googleAnalyticsAddPaymentInfoEvent = (cart: TShoppingCart, paymentMethod: string): void => {
  const orderItems = cart?.items;

  const ecommerceObject: TEcommerceObject = {
    coupon: cart?.appliedDiscountCodes?.length ? cart?.appliedDiscountCodes[0]?.code : undefined,
    currency: CURRENCY,
    items: orderItems?.map(item => {
      return {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: undefined,
        item_name: item?.name,
        price: item?.price,
        quantity: item?.amount,
      };
    }),
    payment_type: paymentMethod,
    shipping: cart?.shippingCosts,
    value: cart?.total,
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'add_payment_info' });
};

const googleAnalyticsPurchaseEvent = (order: TOrderDetail, containsPhysicalProducts: boolean): void => {
  const ecommerceObject: TEcommerceObject = {
    coupon: order?.discounts?.length ? order?.discounts[0]?.code : undefined,
    currency: CURRENCY,
    items: order?.products?.map(item => {
      return {
        affiliation: AFFILIATION,
        item_brand: BRAND,
        item_id: order?.orderNumber,
        item_name: item?.name,
        price: item?.price,
        quantity: item?.amount,
      };
    }),
    payment_type: order?.paymentMethod,
    shipping: order?.shippingCost,
    shipping_tier: containsPhysicalProducts ? 'Physical Shipping' : 'Digital Shipping',
    value: order?.price,
  };
  pushEventToDataLayer({ ecommerce: ecommerceObject, event: 'purchase' });
};

export {
  initializeDataLayer,
  clearDataLayer,
  googleAnalyticsAddShippingInfoEvent,
  googleAnalyticsAddPaymentInfoEvent,
  googleAnalyticsAddToCartEvent,
  googleAnalyticsBeginCheckoutEvent,
  googleAnalyticsPurchaseEvent,
  googleAnalyticsRemoveFromCartEvent,
  googleAnalyticsViewCartEvent,
  googleAnalyticsViewItemEvent,
};

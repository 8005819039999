import { FC } from 'react';

import { useStoreType } from '@hooks';
import { Icon } from '@shared';
import { TIconType } from '@shared/icon/Icon';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TCompositionItem } from '../../../_models/products';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import './productInformation.scss';

type TProps = {
  info: TCompositionItem[];
};

const ProductInformation: FC<TProps> = ({ info = [] }) => {
  const { t } = useTranslation();
  const { isB2C } = useStoreType();

  const filteredInfo = info.filter(({ title }) => title?.length > 0);
  if (filteredInfo.length === 0) return null;

  return (
    <section className="product__information product--detail__information">
      {filteredInfo.length > 0 && (
        <>
          <h2 className="visually-hidden">{t('PRODUCT.INFORMATION.TITLE')}</h2>
          <ul>
            {filteredInfo.map(({ content, icon, title }) => (
              <li key={title}>
                <div>
                  <Icon name={`Ci${icon}` as TIconType} size={1.6} />
                  <span>{t(title)}</span>
                  {content && (
                    <Tippy appendTo="parent" content={t(content)} duration={500} theme="light-border">
                      <button className="product__information__tooltip" type="button">
                        <Icon label={t('SHARED.BUTTONS.TOOLTIP')} name="Question" size={1.6} />
                      </button>
                    </Tippy>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      {isB2C && <p className="product__information__disclaimer">{t('PRODUCT.INFORMATION.DISCLAIMER')}</p>}
    </section>
  );
};

export default ProductInformation;

import { useStoreType } from '@hooks';
import { TCompanyDetails } from 'company/_models';
import { TConfiguration } from 'general/_models/configuration';
import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { useGetConfiguration } from '../../general/_queries/useGetConfiguration';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TB2BInvoiceDetails, TB2CInvoiceDetails, TInvoiceDetails } from '../_models/invoice';

function updateB2CInvoiceAddress(invoiceDetails: TB2CInvoiceDetails, customerId: string): Promise<void> {
  const payload = {
    ...invoiceDetails,
  };

  return HttpClient.put(`customers/${customerId}/invoice-details`, payload);
}

function updateB2BInvoiceAddress(
  invoiceDetails: TB2BInvoiceDetails,
  company: TCompanyDetails,
  config: TConfiguration,
  customerId: string,
): Promise<void> {
  if (!company.id) return;

  const payload = {
    ...invoiceDetails,
    kinepolisCountry: config.countryCode,
  };

  return HttpClient.put(`b2b/companies/${company.id}/invoice-details/${customerId}`, payload);
}

export function useUpdateInvoiceAddress() {
  const queryClient = useQueryClient();
  const { isB2C } = useStoreType();
  const { customer } = useCustomerContext();
  const { selectedCompany } = useCompanyContext();
  const { data: config } = useGetConfiguration();

  return useMutation<void, ApiError, TInvoiceDetails>(
    'updateInvoiceAddress',
    body =>
      isB2C
        ? updateB2CInvoiceAddress(body as TB2CInvoiceDetails, customer?.id)
        : updateB2BInvoiceAddress(body as TB2BInvoiceDetails, selectedCompany, config, customer?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('company');
        queryClient.invalidateQueries('companies');
      },
    },
  );
}

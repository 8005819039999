import { FC, useEffect } from 'react';

import { useModal, useStoreType } from '@hooks';
import { Button, Icon } from '@shared';
import { useTranslation } from 'react-i18next';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { setShippingDetails } from '../_context/checkoutActions';
import useCheckoutUser from '../_hooks/useCheckoutUser';

import DigitalModal from './modal/digitalModal';

const OverviewDigitalAddress: FC = () => {
  const { t } = useTranslation();
  const { isGuest, customer } = useCustomerContext();
  const { state, dispatch } = useCheckoutContext();
  const [renderModal, showModal] = useModal(modalProps => <DigitalModal {...modalProps} />);
  const { digitalShippingAddresses } = useCheckoutUser();
  const { isB2C } = useStoreType();
  const canEditAddress = isB2C && !isGuest;

  useEffect(() => {
    if (state.digitalAddress?.id) return;

    // When no other address was selected, find the default for the user and select it
    const defaultForUser = digitalShippingAddresses?.find(({ default: isDefault }) => isDefault);
    dispatch(
      setShippingDetails({
        digitalAddress: defaultForUser,
      }),
    );
  }, [state.digitalAddress?.id]);

  return (
    <>
      <section className={digitalShippingAddresses?.length && digitalShippingAddresses ? '' : 'noAddress'}>
        <header className="actionable-header">
          <h3 className="h2">
            <Icon className="email-icon" name="Email" size={2} />
            {t('CHECKOUT.OVERVIEW.DIGITAL_SHIPPING')}
          </h3>
          {canEditAddress && (
            <Button hideLabel icon="Pencil" iconPosition="right" onClick={showModal} theme="plain-link">
              {t('SHARED.BUTTONS.EDIT')} <span className="visually-hidden">{t('CHECKOUT.OVERVIEW.DIGITAL_SHIPPING')}</span>
            </Button>
          )}
        </header>

        <address>
          {isGuest
            ? state.digitalAddress.email
            : customer?.digitalAddresses?.length && state.digitalAddress?.id
            ? customer.digitalAddresses.find(({ id }) => id === state.digitalAddress?.id)?.email
            : t('CHECKOUT.OVERVIEW.NO_ADDRESS')}
        </address>
      </section>
      {renderModal()}
    </>
  );
};

export default OverviewDigitalAddress;

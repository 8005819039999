import { BaseSyntheticEvent, ElementType, forwardRef, ReactNode, useCallback } from 'react';

import Icon, { TIconType } from '@shared/icon/Icon';
import Spinner from '@shared/spinner/Spinner';
import { isExternalLink, isPlatformLink } from '@utils/linkHelpers';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import './button.scss';

type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'light-transparent'
  | 'action'
  | 'primary-link'
  | 'secondary-link'
  | 'plain-link'
  | 'wrapper'
  | 'accent';
export type TActionType = string | ((event?: BaseSyntheticEvent) => void);
export type TProps = {
  autoFocus?: boolean;
  children?: ReactNode | undefined;
  className?: string;
  disabled?: boolean;
  form?: string;
  hideLabel?: boolean;
  href?: string;
  icon?: TIconType;
  iconPosition?: 'left' | 'right';
  iconSize?: number;
  id?: string;
  isNavLink?: boolean;
  loading?: boolean;
  notification?: number;
  onClick?: (event?: BaseSyntheticEvent) => void;
  stopPropagation?: boolean;
  theme?: TButtonVariant;
  type?: 'button' | 'submit' | 'reset';
};

export type TForwardRef = ReactNode;

const Button = forwardRef<TForwardRef, TProps>(
  (
    {
      hideLabel,
      children,
      className = '',
      disabled,
      href,
      icon,
      iconSize = 1.8,
      iconPosition,
      isNavLink,
      loading,
      notification,
      onClick,
      theme = 'primary',
      type = 'button',
      stopPropagation = false,
      ...otherProps
    },
    ref,
  ) => {
    const cssClasses = classnames(className, `button--${theme}`, {
      button: theme !== 'wrapper',
      'button--disabled': disabled,
      'button--icon': icon && hideLabel,
      'button--icon-reverse': iconPosition === 'right',
      'button--loading': loading,
      'button--with-icon': icon,
      'button--with-text': !hideLabel,
    });
    const notifications = notification >= 100 ? '99+' : notification;
    const Type: ElementType =
      href && !disabled ? (isExternalLink(href) || isPlatformLink(href) ? 'a' : isNavLink ? NavLink : Link) : 'button';
    let actionProp: Record<string, unknown> = {};

    const callback = useCallback(
      event => {
        // Do not prevent default behavior if onClick prop is present
        if (onClick) {
          onClick(event);
        } else {
          // Prevent default behavior only when there's no onClick prop
          event.preventDefault();
          if (stopPropagation) {
            event.stopPropagation();
          }
        }
      },
      [onClick, stopPropagation],
    );

    if (href && !disabled) {
      if (isExternalLink(href) || isPlatformLink(href)) {
        actionProp = {
          'aria-disabled': disabled,
          href: disabled ? '#' : href,
          onClick: onClick ? callback : stopPropagation ? event => event.stopPropagation() : undefined,
          rel: isExternalLink(href) ? 'noopener noreferrer' : undefined,
          target: isExternalLink(href) ? '_blank' : undefined,
        };
      } else {
        actionProp = { 'aria-disabled': disabled, onClick, to: disabled ? '#' : href };
      }
    } else {
      actionProp = { disabled, onClick, type };
    }

    const inlineSpinner = loading ? (
      <div className="spinner-wrapper">
        <Spinner theme="inversed" />
      </div>
    ) : null;
    const inlineIcon = icon ? <Icon name={icon} size={iconSize} /> : undefined;

    return (
      <Type {...otherProps} {...actionProp} className={cssClasses} ref={ref} to={actionProp?.to}>
        {notification > 0 && inlineIcon && !hideLabel ? (
          <span className="button__notificationWrapper">
            {inlineIcon}
            <span className="button__notification">{notifications}</span>
          </span>
        ) : (
          inlineIcon
        )}
        {hideLabel ? (
          <span className="visually-hidden">{children}</span>
        ) : typeof children === 'string' ? (
          <span className="button__label">{children}</span>
        ) : (
          children
        )}
        {inlineSpinner}
        {notifications > 0 && (!icon || hideLabel) && <span className="button__notification">{notifications}</span>}
      </Type>
    );
  },
);

export default Button;

import { ApiError } from '@http';
import { getBaseUrl } from '@utils/linkHelpers';
import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { env } from './env';

export type Environment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = env.NODE_ENV;
function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get apiHost(): string {
    return env.REACT_APP_API_HOST as string;
  }

  static get vouchersApi(): string {
    return env.REACT_APP_VOUCHERS_API as string;
  }

  static get invoicesApi(): string {
    return env.REACT_APP_INVOICES_API as string;
  }

  static get environment(): Environment {
    return nodeEnv as Environment;
  }

  static get sentryDsn(): string {
    return env.REACT_APP_SENTRY_DSN as string;
  }

  static get authority(): string {
    return env.REACT_APP_AUTHORITY as string;
  }

  static get clientId(): string {
    return env.REACT_APP_CLIENT_ID as string;
  }

  static getOidcConfiguration(businessPathName: string) {
    const base = `${getBaseUrl()}${businessPathName}`;

    return {
      authority: this.authority,
      automaticSilentRenew: false,
      client_id: this.clientId,
      loadUserInfo: true,
      monitorSession: false,
      post_logout_redirect_uri: base,
      redirect_uri: base + '/authentication/callback',
      response_type: 'code',
      scope: 'openid profile kinepolis-required MyKinepolis ' + env.REACT_APP_SCOPE,
      silent_redirect_uri: base + '/authentication/silent_callback',
    };
  }

  static getQueryClient() {
    return new QueryClient({
      defaultOptions: {
        mutations: {
          onError: () => {
            toast.error('An Error occurred');
          },
          retry: false,
        },
        queries: {
          onError: error => {
            if ((error as ApiError)?.statusCode === 403) return;
            toast.error('An Error occurred');
          },
          refetchOnWindowFocus: false,
          retry: false,
          staleTime: 1000 * 60, // 1 minute
        },
      },
    });
  }
}

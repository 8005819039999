import { FC, ReactNode, useRef } from 'react';

import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

import Button, { TProps as TButtonProps } from './Button';
import './dropdownButton.scss';

type TProps = {
  hideLabel?: boolean;
  label: string;
} & TButtonProps;

type TItemProps = {
  children: ReactNode;
  href?: string;
  onClick?: () => void;
};

const Item: FC<TItemProps> = ({ onClick, href, children }) => (
  <li>
    <Button href={href} onClick={onClick} theme="plain-link">
      {children}
    </Button>
  </li>
);

const Trigger: FC<TProps> = ({ label, children, icon, ...props }) => {
  const ref = useRef<Instance>();
  const dropdown = (
    <ul
      onClick={() => {
        ref.current?.hide();
      }}
    >
      {children}
    </ul>
  );

  return (
    <div className="btn-dropdown">
      <Tippy
        appendTo="parent"
        content={dropdown}
        duration={500}
        hideOnClick
        interactive
        onCreate={instance => {
          ref.current = instance;
        }}
        placement="bottom"
        theme="light-border"
        trigger="click"
      >
        <Button icon={icon ?? 'ChevronDown'} iconPosition="right" {...props}>
          {label}
        </Button>
      </Tippy>
    </div>
  );
};

export { Trigger, Item };

export const formatNumberToDotNotation = (number: number): string => {
  const numberString = number.toString();
  const groups = [];
  let i = numberString.length;
  while (i > 0) {
    groups.unshift(numberString.slice(Math.max(0, i - 3), i));
    i -= 3;
  }
  return groups.join('.');
};

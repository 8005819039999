import { TDigitalAddress, TPhysicalAddress } from 'checkout/_models/address';
import { TB2BInvoiceDetails } from 'checkout/_models/invoice';

export type TCompany = {
  id: string;
  isAdmin: boolean;
  name: string;
};

export type TCompanyDetails = TCompany & {
  customerNumber: string;
  digitalShippingAddresses: TDigitalAddress[];
  domains: string[];
  enterpriseNumber: string;
  extraInfo: string;
  invoiceDetails: TB2BInvoiceDetails;
  isPayLaterEnabled: boolean;
  physicalShippingAddresses: TPhysicalAddress[];
  vatNumber: string;
};

export type TCompanyPayload = {
  box: string;
  city: string;
  companyEmail: string;
  companyName: string;
  countryIsoCode: string;
  enterpriseNumber: string;
  extraInfo: string;
  kinepolisCountry: string;
  language: string;
  mobilePhoneNumber?: string;
  mobilePhoneNumberPrefix?: string;
  number: string;
  phoneNumber?: string;
  phoneNumberPrefix?: string;
  postalCode: string;
  street: string;
  vatNumber: string;
};

export enum RegisterCompanyStatus {
  NotAuthorized = 'NotAuthorized',
}

export type TRegisterCompanyResponse = {
  companyId: string;
  isExistingCompany: boolean;
  isTaxExempt: boolean;
  status: string;
};

import { HttpMetadataQuery, HttpSortDirection } from '@http';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ApiError, HttpClient } from '../../_http';
import { TOrder } from '../_models/order';

export type TGetOrdersResponse = {
  count: number;
  data: TOrder[];
  totalCount: number;
};

function getOrders(metadata: HttpMetadataQuery): Promise<TGetOrdersResponse> {
  return HttpClient.get<TGetOrdersResponse>(
    `orders?skip=${metadata.skip ?? 0}&take=${metadata.take ?? 30}&sortBy=${metadata.sortBy ?? 'dateOfOrder'}&sortDirection=${
      metadata.sortDirection ?? HttpSortDirection.Descending
    }`,
  );
}

export function useGetOrders(metadata: HttpMetadataQuery) {
  const { t } = useTranslation();
  return useQuery<TGetOrdersResponse, ApiError>(['orders'], () => getOrders(metadata), {
    cacheTime: 0,
    onError: () => {
      toast.error(t('CUSTOMER_PORTAL.ERRORS.ORDER_REQUEST_ERROR'));
    },
  });
}

import React, { Dispatch, SetStateAction, useState } from 'react';

import Icon from '@shared/icon/Icon';
import Spinner from '@shared/spinner/Spinner';
import { getCountryCodeFromDomain } from '@utils/countries';
import { TAddressFields } from 'checkout/address/Address';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { TPhysicalAddress } from '../../checkout/_models/address';
import { TInvoiceDetails } from '../../checkout/_models/invoice';
import { TCompanyPayload } from '../../company/_models';

import { TSuggestion, TChildrenFuncParams } from './AutocompleteTypes';

import './googleMapsAddressLookup.scss';

type TAddress = {
  box?: string;
  city?: string;
  number?: string;
  postalCode?: string;
  street?: string;
};

type TGooglePlacesAutocompleteProps = {
  setFormValues?:
    | Dispatch<SetStateAction<TPhysicalAddress>>
    | Dispatch<SetStateAction<TInvoiceDetails>>
    | Dispatch<SetStateAction<TCompanyPayload>>
    | Dispatch<SetStateAction<TAddressFields>>;
};

const parseAddress = (addressComponents: google.maps.GeocoderAddressComponent[]): TAddress => {
  const components: TAddress = {};

  for (const component of addressComponents) {
    if (component.types.includes('street_number')) {
      components.number = component.long_name.toLowerCase().replace('no.', '').trim();
    } else if (component.types.includes('route')) {
      components.street = component.long_name.trim();
    } else if (component.types.includes('locality')) {
      components.city = component.long_name.trim();
    } else if (component.types.includes('postal_code')) {
      components.postalCode = component.long_name.trim();
    } else if (component.types.includes('subpremise')) {
      components.box = component.long_name
        .toLowerCase()
        .replace('bus' || 'box' || 'unit' || 'apt', '')
        .trim();
    }
  }

  return components;
};

const GoogleMapsAddressLookup: React.FC<TGooglePlacesAutocompleteProps> = ({ setFormValues }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSelect = (value: string) => {
    setSearchValue(value);
    geocodeByAddress(value)
      .then((results: google.maps.GeocoderResult[]) => {
        const address = parseAddress(results[0].address_components);
        setFormValues &&
          setFormValues(currentValues => {
            if ('physicalAddress' in currentValues) {
              // Handles Guest Checkout Type
              return {
                ...currentValues,
                physicalAddress: {
                  ...currentValues.physicalAddress,
                  box: address?.box ?? '',
                  city: address?.city ?? '',
                  number: address?.number ?? '',
                  postalCode: address?.postalCode ?? '',
                  street: address?.street ?? '',
                },
              };
            } else {
              // Handles TPhysicalAddress types
              return {
                ...currentValues,
                box: address?.box ?? '',
                city: address?.city ?? '',
                number: address?.number ?? '',
                postalCode: address?.postalCode ?? '',
                street: address?.street ?? '',
              };
            }
          });
      })
      .catch((error: google.maps.GeocoderStatus) => {
        console.error('Failed to retrieve address details', error);
      });
  };

  const searchOptions = {
    componentRestrictions: { country: getCountryCodeFromDomain()?.toUpperCase() },
  };

  return (
    <>
      <div className="google-places-container">
        <PlacesAutocomplete
          debounce={500}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={searchOptions}
          value={searchValue}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: TChildrenFuncParams) => (
            <>
              <div className="places-icon">
                <Icon name="Search" />
              </div>
              <input
                className="google-places-input"
                value={searchValue || ''}
                {...getInputProps({
                  placeholder: t('GOOGLE.GOOGLE_ADDRESS_LOOKUP.PLACEHOLDER'),
                })}
              />
              <div className="autocomplete-dropdown-container">
                {suggestions.map((suggestion: TSuggestion, idx) => {
                  const className = 'suggestion-item';
                  return (
                    <div
                      key={`Suggestion ${idx}`}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <Icon className="search-result-icon" name="MapMarker" />
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
              {loading && (
                <div className="loading">
                  <Spinner />
                </div>
              )}
            </>
          )}
        </PlacesAutocomplete>
      </div>
    </>
  );
};

export default GoogleMapsAddressLookup;

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ApiError, HttpClient } from '../../_http';
import { googleAnalyticsAddToCartEvent } from '../../googleAnalytics/dataLayer';
import { TProductDetail } from '../../product/_models';
import { useCustomerContext } from '../_context/CustomerContext';
import { TAddToShoppingCartRequest, TShoppingCartItem } from '../_models';

function addToShoppingCart(shoppingCartId: string, body: TAddToShoppingCartRequest): Promise<void> {
  const personalizationText = body.personalizationText?.trim() || undefined;

  return HttpClient.post(`shoppingcart/${shoppingCartId}/items`, {
    amount: body.amount,
    personalizationText,
    productId: 'id' in body.product ? body.product.id : body.product.productPackageId,
  });
}

export function useAddToShoppingCart(params?: { addToExistingAmount: boolean; isToastHidden: boolean }) {
  const { isToastHidden, addToExistingAmount } = params;
  const queryClient = useQueryClient();
  const { shoppingCartId, cart, updateShoppingCartId } = useCustomerContext();
  const { t } = useTranslation();

  return useMutation<void, ApiError, TAddToShoppingCartRequest>(
    'addToShoppingCart',
    ({ product, amount, personalizationText }) => {
      const existingItem = cart?.items.find(item => {
        if ((product as TProductDetail).id) return item.productPackageId === (product as TProductDetail).id;
        if ('productPackageId' in product) return item.productPackageId === product.productPackageId;
        return false;
      });

      return addToShoppingCart(shoppingCartId, {
        amount: addToExistingAmount && existingItem ? existingItem.amount + amount : amount,
        personalizationText,
        product,
      });
    },
    {
      onError: error => {
        if (error.statusCode === 403) {
          updateShoppingCartId();
        }
      },
      onSuccess: (_data, { product, amount }) => {
        const productName = product.name;
        if (!isToastHidden) {
          toast.success(t('CART.SUCCESS_MESSAGE', { amount, productName }), {
            autoClose: 3000,
          });
        }
        queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);

        googleAnalyticsAddToCartEvent(amount, {
          ...(product as TProductDetail),
          id: (product as TShoppingCartItem).productPackageId,
        });
      },
      retry: 1,
    },
  );
}

import { useEffect } from 'react';

const useGoogleMaps = (ApiKey: string) => {
  useEffect(() => {
    window.initGoogleMaps = () => {
      // Callback for the loading of the google maps script
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}&libraries=places&callback=initGoogleMaps&loading=async`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      delete window.initGoogleMaps;
    };
  }, [ApiKey]);
};

export default useGoogleMaps;

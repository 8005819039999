import { useStoreType } from '@hooks';
import { ApiError, HttpClient, HttpPagedResponse, HttpMetadataQuery, HttpSortDirection } from '@http';
import i18next from 'i18next';
import { useQuery } from 'react-query';

import { TProduct } from '../_models/products';

type TParameters = {
  ProductCategoryId: string;
} & HttpMetadataQuery;

function getProductsByCategoryId({
  ProductCategoryId,
  skip = 0,
  take = 100,
  sortBy,
  sortDirection = HttpSortDirection.Ascending,
}: TParameters): Promise<HttpPagedResponse<TProduct>> {
  return HttpClient.get<HttpPagedResponse<TProduct>>(`products`, {
    ProductCategoryId,
    skip,
    sortBy,
    sortDirection,
    take,
  });
}

export function useGetProductsByCategoryId(query: TParameters) {
  const { isB2C } = useStoreType();

  return useQuery<HttpPagedResponse<TProduct>, ApiError>(['products', query, isB2C], () => getProductsByCategoryId(query), {
    select: response => ({
      ...response,
      data: response.data.map(product => {
        const translations = product.translations.filter(translation => translation.languageCode === i18next.language);
        return {
          ...product,
          composition: product.composition.map(compositionItem => ({
            ...compositionItem,
            content: translations.find(translation => translation.key === compositionItem.content)?.value,
            title: translations.find(translation => translation.key === compositionItem.title)?.value,
          })),
          name: translations.find(translation => translation.key === 'product.name')?.value || product.name,
        };
      }),
    }),
  });
}

import { useEffect } from 'react';

import { PaymentResultCode, PaymentState } from '../_models/confirmation';
import { useGetPayment } from '../_queries';

const usePollPaymentState = (orderId?: string, enabled = true) => {
  const { data: paymentData, isFetching, refetch, isIdle } = useGetPayment(orderId, !!orderId && enabled);
  const { state: paymentState } = paymentData ?? {};
  const canPollPayment = ![PaymentState.COMPLETE, PaymentResultCode.REFUSED, PaymentState.CANCELLED].includes(paymentState);

  useEffect(() => {
    if (isIdle || isFetching || !orderId) return;
    let timeout: ReturnType<typeof setTimeout>;
    if (canPollPayment) {
      timeout = setTimeout(refetch, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching, isIdle, orderId, paymentData, paymentState, refetch]);

  return {
    paymentData,
  };
};

export default usePollPaymentState;

import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

function deletePhysicalShippingAddress(addressId: string, customerId: string): Promise<void> {
  return HttpClient.delete(`customers/${customerId}/physical-shipping-addresses/${addressId}`, '');
}

export function useDeletePhysicalShippingAddress() {
  const queryClient = useQueryClient();
  const { customer: { id: userId } = {} } = useCustomerContext();

  return useMutation<void, ApiError, { addressId: string }>(
    'deletePhysicalShippingAddress',
    body => deletePhysicalShippingAddress(body?.addressId, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
      },
    },
  );
}

import { useState, createContext, FC, ReactNode, useCallback, useContext } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useStoreType } from '@hooks';
import { v1 as uuidv1 } from 'uuid';

import { TContactPerson, TCustomer } from '../../general/_models/customer';
import { useGetCustomer } from '../../general/_queries/useGetCustomer';
import { TShoppingCart } from '../_models';
import { useGetShoppingCart } from '../_queries';

export type TProductSortOption = 'price' | 'recommendedOrder';

type TCustomerContext = {
  cart: TShoppingCart;
  cartCount: number;
  containsDigitalProducts: boolean;
  containsPhysicalProducts: boolean;
  customer: TCustomer | TContactPerson;
  isGuest: boolean;
  isLoading: boolean;
  productSort: TProductSortOption;
  shoppingCartId?: string;
  updateProductSort: (value: TProductSortOption) => void;
  updateShoppingCartId: () => void;
  wantInvoice: boolean;
};

export const CustomerContext = createContext<TCustomerContext>({
  cart: null,
  cartCount: 0,
  containsDigitalProducts: false,
  containsPhysicalProducts: false,
  customer: null,
  isGuest: true,
  isLoading: false,
  productSort: 'recommendedOrder',
  updateProductSort: () => {},
  updateShoppingCartId: () => {},
  wantInvoice: false,
});

export const useCustomerContext = () => useContext(CustomerContext);

type TProps = {
  children: ReactNode;
};

export const CustomerContextProvider: FC<TProps> = ({ children }) => {
  const { oidcUser } = useReactOidc();
  const { isB2C } = useStoreType();
  const { data: customer } = useGetCustomer();

  const [shoppingCartId, setShoppingCartId] = useState(
    document.cookie
      ?.split('; ')
      ?.find(row => row.startsWith('shoppingCartId='))
      ?.split('=')[1],
  );
  const [productSort, setProductSort] = useState<TProductSortOption>('recommendedOrder');

  const updateShoppingCartId = useCallback(() => {
    const id = uuidv1();
    setShoppingCartId(id);
    document.cookie = `shoppingCartId=${id}; max-age=${60 * 60 * 24}; path=/`;
  }, []);

  if (!shoppingCartId) updateShoppingCartId();

  const {
    data: shoppingCart,
    isLoading,
    error,
  } = useGetShoppingCart({ enabled: !!shoppingCartId && (isB2C || !!oidcUser), shoppingCartId });

  if (error && error.statusCode === 403) {
    updateShoppingCartId();
  }

  return (
    <CustomerContext.Provider
      value={{
        cart: shoppingCart,
        cartCount: (shoppingCart?.items || []).reduce((total, item) => total + item?.amount, 0),
        containsDigitalProducts: shoppingCart?.items.some(({ isDigital }) => isDigital),
        containsPhysicalProducts: shoppingCart?.items.some(({ isDigital }) => !isDigital),
        customer,
        isGuest: !oidcUser,
        isLoading,
        productSort,
        shoppingCartId,
        updateProductSort: setProductSort,
        updateShoppingCartId,
        wantInvoice: (customer as TCustomer)?.invoiceDetails?.wantInvoice ?? false,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

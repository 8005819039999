import { ApiError, HttpClient } from '@http';
import i18n from 'i18next';
import { useQuery } from 'react-query';

import { TTranslations } from '../_models/translation';

function getLanguage(languageCode: string): Promise<TTranslations> {
  return HttpClient.get<TTranslations>(`store/translations/${languageCode}`);
}

export function useGetLanguage(enabled = true) {
  const languageCode = i18n.language;
  return useQuery<TTranslations, ApiError>(['language', languageCode], () => getLanguage(languageCode), {
    enabled,
    onSuccess: ({ translations }) => i18n.addResources(languageCode, 'translation', translations),
  });
}

export enum PaymentResultCode {
  AMOUNT_INVALID = '137',
  AUTHORISATION = 'authorisation',
  AUTHORISED = 'authorised',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  PENDING = 'pending',
  RECEIVED = 'received',
  REDIRECT_SHOPPER = 'redirectshopper',
  REFUSED = 'refused',
}

export enum PaymentState {
  CANCELLED = 'cancelled',
  COMPLETE = 'complete',
  DELAYED_PAYMENT_PENDING = 'initiatepayment.pending',
  SHOPPING_CART_LOCKED = 'shoppingcartwaslocked',
  VISTA_SYNC_FAILED = 'vistasyncfailed',
  WAITING_FOR_PAYMENT = 'waitingforpayment',
}

export type TOrderConfirmation = {
  action: string;
  orderId: string;
  orderNumber: string;
  resultCode?: PaymentResultCode;
  state: PaymentState;
};

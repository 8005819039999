import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { googleAnalyticsRemoveFromCartEvent } from '../../googleAnalytics/dataLayer';
import { useCustomerContext } from '../_context/CustomerContext';

function removeFromShoppingCart(shoppingCartId: string, productId: string): Promise<void> {
  return HttpClient.delete(`shoppingcart/${shoppingCartId}/items/${productId}`, productId);
}

export function useRemoveFromShoppingCart() {
  const queryClient = useQueryClient();
  const { shoppingCartId } = useCustomerContext();

  return useMutation<void, ApiError, string>(
    'removeFromShoppingCart',
    productId => {
      return removeFromShoppingCart(shoppingCartId, productId);
    },
    {
      onSuccess: (_data, productId) => {
        queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);

        googleAnalyticsRemoveFromCartEvent(productId);
      },
    },
  );
}

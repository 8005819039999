import { FC } from 'react';

import { useInputError } from '@hooks';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import InputWrapper, { InputWrapperProps } from '../InputWrapper';
import './dropdown.scss';

export type DropdownOption = {
  label: string;
  value: string | number;
};

type TProps = InputWrapperProps & {
  hasNoEmtyOption?: boolean;
  isSearchable?: boolean;
  multiple?: boolean;
  normalize?: (value: string) => string;
  onChange: (value: string | string[] | number, name: string) => void;
  options: DropdownOption[] | [];
  placeholder?: string;
  value: string | string[] | number;
};

const Dropdown: FC<TProps> = ({
  multiple,
  normalize = (value: string | number) => value,
  onChange,
  options,
  placeholder,
  value,
  hasNoEmtyOption,
  isSearchable,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { t } = useTranslation();
  const { setDirty, showError } = useInputError(validation);

  let allOptions: DropdownOption[] = options || [];
  const containsEmptyOption = !hasNoEmtyOption && value && !multiple;
  if (containsEmptyOption) {
    allOptions = [{ label: t('SHARED.DROPDOWN.OPTION_CLEAR_VALUE'), value: null }, ...allOptions];
  }

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <Select
        className={classnames('dropdown', {
          'contains-empty-option': containsEmptyOption,
          error: showError,
        })}
        classNamePrefix="dropdown"
        isClearable={false}
        isDisabled={disabled}
        isMulti={multiple}
        isSearchable={isSearchable}
        menuPlacement="auto"
        name={name}
        noOptionsMessage={() => t('SHARED.DROPDOWN.NO_OPTIONS')}
        onChange={(option: DropdownOption | DropdownOption[]) => {
          let normalizedValue = null;
          if (!option && Array.isArray(value)) normalizedValue = [];
          else if (Array.isArray(option)) normalizedValue = option.map(opt => normalize(opt.value));
          else normalizedValue = normalize(option.value);
          onChange(normalizedValue, name);
          setDirty();
        }}
        options={allOptions}
        placeholder={placeholder}
        value={allOptions?.filter(option =>
          Array.isArray(value) ? value.includes(option.value as string) : option.value === value,
        )}
      />
    </InputWrapper>
  );
};

export default Dropdown;

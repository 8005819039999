import { useEffect, FC, ReactNode } from 'react';

import { useResponsive } from '@hooks';
import { useFocusTrap } from '@mantine/hooks';
import { Button } from '@shared';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ModalOpener } from './ModalOpener';
import './modal.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  onCancel?: () => void;
};

const Header: FC<{ children: ReactNode }> = ({ children }) => <header className="modal__header">{children}</header>;

const Title: FC<{ children: ReactNode }> = ({ children }) => <h1 id="modal__title">{children}</h1>;

const Content: FC<{ children: ReactNode }> = ({ children }) => <div className="modal__content">{children}</div>;

const Buttons: FC<{ children: ReactNode }> = ({ children }) => <div className="modal__buttons">{children}</div>;

const Modal: FC<TProps> = ({ children, className = '', onCancel }) => {
  const { isSmallerThan } = useResponsive();
  const focusTrapRef = useFocusTrap();

  const { t } = useTranslation();
  const cancelModal = () => {
    onCancel?.();
    ModalOpener.instance.close();
  };

  useEffect(() => {
    document.documentElement.classList.add('modal-open');
    return () => document.documentElement.classList.remove('modal-open');
  }, []);

  useEffect(() => {
    const onEscapePress = ({ key }) => {
      if (key?.toLowerCase() === 'escape') {
        cancelModal();
      }
    };
    document.addEventListener('keyup', onEscapePress);
    return () => document.removeEventListener('keyup', onEscapePress);
  });

  return ReactDOM.createPortal(
    <>
      <button
        aria-hidden="true"
        aria-label={`${t('SHARED.BUTTONS.CLOSE')} ${t('SHARED.DIALOG')}`}
        className="modal__dimmer"
        onClick={cancelModal}
        type="button"
      />
      <div aria-labelledby="modal__title" className={classnames('modal', className)} ref={focusTrapRef} role="dialog">
        <div className="modal__box">
          {children}
          <Button
            className="modal__close"
            hideLabel
            icon={isSmallerThan('phone') ? 'ArrowLeft' : 'Close'}
            iconSize={1.6}
            onClick={cancelModal}
            theme="wrapper"
          >
            {t('SHARED.BUTTONS.CLOSE')} {t('SHARED.DIALOG')}
          </Button>
        </div>
      </div>
    </>,
    document.querySelector('#portal'),
  );
};

export default Object.assign(Modal, { Buttons, Content, Header, Title });

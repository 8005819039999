import { FC } from 'react';

import { getTranslatedCountryName } from '@utils/countries';
import { useTranslation } from 'react-i18next';

import './formattedAddress.scss';

type TProps = {
  attn?: string;
  box?: string;
  city?: string;
  companyName?: string;
  country?: string;
  email?: string;
  enterpriseNumber?: string;
  isPickupPoint?: boolean;
  mobilePhoneNumber?: string;
  mobilePhoneNumberPrefix?: string;
  name?: string;
  number?: string;
  phoneNumber?: string;
  phoneNumberPrefix?: string;
  postalCode?: string;
  street?: string;
  vat?: string;
};

const FormattedAddress: FC<TProps> = ({
  attn,
  box,
  city,
  companyName,
  country,
  email,
  enterpriseNumber,
  isPickupPoint,
  mobilePhoneNumber,
  mobilePhoneNumberPrefix,
  name,
  number,
  phoneNumber,
  phoneNumberPrefix,
  postalCode,
  street,
  vat,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isPickupPoint && <span className="pickup-point-title">{t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_TAB_TITLE')}:</span>}
      <div className="formatted-address">
        {(companyName || name) && (
          <div className="name">
            {companyName || name} <br />
          </div>
        )}

        {email && (
          <div>
            {email} <br />
          </div>
        )}

        {phoneNumber && (
          <div>
            {phoneNumberPrefix}
            {phoneNumber} <br />
          </div>
        )}

        {mobilePhoneNumber && (
          <div>
            {mobilePhoneNumberPrefix}
            {mobilePhoneNumber} <br />
          </div>
        )}

        {attn && (
          <>
            {attn}
            <br />
          </>
        )}

        {street && (
          <div>
            {street + ' '} {number + ' '} {box && '/' + ' ' + box} <br />
          </div>
        )}

        {(postalCode || city) && (
          <div>
            {postalCode} {city} <br />
          </div>
        )}

        {country && (
          <div>
            {getTranslatedCountryName(country)} <br />
          </div>
        )}

        {(vat || enterpriseNumber) && (
          <div>
            {vat || enterpriseNumber} <br />
          </div>
        )}
      </div>
    </>
  );
};

export default FormattedAddress;

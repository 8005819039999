import { useForm } from '@hooks';
import { FormValidationErrors, SubmitFormFunction } from '@hooks/useForm';
import { formValidator } from '@utils/formValidation';
import { useHistory } from 'react-router-dom';

import { useCompanyContext } from '../_context/CompanyContext';
import { TCompany } from '../_models';

type TPickCompanyForm = {
  companyId: TCompany['id'];
};

const usePickCompanyForm = () => {
  const validator = formValidator();
  const { push } = useHistory();
  const { companies, selectedCompany, setCurrentCompany } = useCompanyContext();

  const initialForm: TPickCompanyForm = {
    companyId: selectedCompany?.id || '',
  };

  const validateForm = (values: TPickCompanyForm): FormValidationErrors<TPickCompanyForm> => ({
    companyId: validator.required(values.companyId),
  });

  const submitForm: SubmitFormFunction<TPickCompanyForm> = async values => {
    try {
      const companyId = companies.find(c => c.id === values.companyId)?.id;
      setCurrentCompany(companyId);

      // Navigate to last visited page
      push('/products');
    } catch (e) {
      // do nothing specific.
    }
  };

  const form = useForm<TPickCompanyForm, TPickCompanyForm>({
    initialForm: { ...initialForm, companyId: selectedCompany?.id },
    submitForm,
    validateForm,
  });

  return form;
};

export default usePickCompanyForm;

import { FC, ReactElement } from 'react';

import { useResponsive } from '@hooks';
import { Table } from '@shared';
import { TGetVouchersResponse, TVoucher } from 'customerPortal/_models';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import './voucherTable.scss';
import '../../../_styles/_colors.scss';

type Props = {
  isLoading: boolean;
  voucherResponse: TGetVouchersResponse;
};

const VoucherTable: FC<Props> = ({ isLoading, voucherResponse }) => {
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const { t } = useTranslation();

  const getVoucherColor = (voucher: TVoucher) => {
    if (voucher?.expiryDate && new Date(voucher?.expiryDate) > new Date()) return 'var(--success)';
    return 'var(--error)';
  };

  const columns = isMobile
    ? [
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.PRODUCT_NAME'), name: 'name' },
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.BARCODE'), name: 'barcode' },
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.STATUS'), name: 'status' },
      ]
    : [
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.PRODUCT_NAME'), name: 'name' },
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.BARCODE'), name: 'barcode' },
        { label: t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.EXPIRY_DATE'), name: 'expiryDate' },
      ];

  const renderRow = (voucher: TVoucher): ReactElement => (
    <Table.Row className="voucher-table-row" key={`${voucher.barcode}-${voucher.expiryDate}`}>
      <Table.Cell>
        <span>{voucher?.name}</span>
      </Table.Cell>
      <Table.Cell>
        <span>{voucher?.barcode}</span>
      </Table.Cell>

      {isMobile ? (
        <Table.Cell>
          <span
            style={{ alignItems: 'center', display: 'inline-flex', flexDirection: 'row', justifyContent: 'right', width: '100%' }}
          >
            <div
              className="status-indicator"
              style={{
                backgroundColor: getVoucherColor(voucher),
              }}
            />
            {voucher?.expiryDate && new Date(voucher?.expiryDate) > new Date() ? 'Active' : 'Expired'}
          </span>
        </Table.Cell>
      ) : (
        <>
          <Table.Cell>
            <span>{format(new Date(voucher?.expiryDate), 'dd-MM-yyyy')}</span>
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );

  return (
    <div>
      <Table
        className="voucher-table"
        columns={columns}
        data={voucherResponse?.vouchers}
        emptyLabel={
          voucherResponse?.generating
            ? t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.GENERATING_VOUCHERS')
            : t('CUSTOMER_PORTAL.ORDERS.VOUCHERS.NO_VOUCHERS')
        }
        isLoading={isLoading}
        renderRow={renderRow}
      />
    </div>
  );
};

export default VoucherTable;

import { FC, useEffect } from 'react';

import { useStoreType } from '@hooks';
import { GoBackLink, Spinner } from '@shared';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router';

import { googleAnalyticsViewItemEvent } from '../../googleAnalytics/dataLayer';
import AddToCartForm from '../../shoppingCart/components/AddToCartForm';
import { useGetProduct } from '../_queries/useGetProduct';

import ProductDescription from './components/ProductDescription';
import ProductBulkPricing from './components/bulkPricing/ProductBulkPricing';
import Contents from './components/contents/ProductContents';
import ProductHeader from './components/header/ProductHeader';
import ProductInformation from './components/information/ProductInformation';

import './productDetail.scss';
const ProductDetail: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ category: string; id: string }>();
  const { data: product, isLoading, isFetching } = useGetProduct(id);
  const { isB2B } = useStoreType();

  const hasBulkPricing = isB2B && product?.priceRanges.length > 1;

  useEffect(() => {
    if (product) {
      googleAnalyticsViewItemEvent(product);
    }
  }, [product]);

  if (isLoading || isFetching)
    return (
      <div className="empty-content">
        <Spinner />
      </div>
    );

  if (!product) return <Redirect to="/products" />;

  return (
    <>
      <GoBackLink label={t('CUSTOMER_PORTAL.ORDERS.DETAIL.GO_BACK')} />
      <div className="product product--detail">
        <Helmet>
          <title>{product.name}</title>
          <meta content={product?.extraInfo[0]?.title} name="description" />

          <meta content={product.name} property="og:title" />
          <meta content={product?.extraInfo[0]?.title} property="og:description" />
          <meta content={product?.picture} property="og:image" />

          <meta content="summary_large_image" name="twitter:card" />
          <meta content={product.name} name="twitter:title" />
          <meta content={product?.extraInfo[0]?.title} name="twitter:description" />
          <meta content={product?.picture} name="twitter:image" />
        </Helmet>

        <div className="product-wrapper">
          <div className="product-wrapper__header">
            <ProductHeader isDigital={product.isDigital} picture={product.picture} title={product.name} />
            <ProductInformation info={product.extraInfo} />
          </div>
          <span className="product-wrapper__add-to-chart">
            <AddToCartForm product={product} />
            {hasBulkPricing && (
              <ProductBulkPricing minimumOrderAmmount={product.minimumOrderQuantity} pricing={product.priceRanges} />
            )}
          </span>
        </div>
        <Contents contents={product.whatsInside} />
        <div className="information-wrapper">
          <ProductDescription
            description={product.translations.find(translation => translation.key === 'product.description')?.value}
          />
        </div>
      </div>
    </>
  );
};

export default ProductDetail;

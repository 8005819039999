export type TGTMIds = {
  [key: string]: string;
};

const TGTMIds = {
  BE: 'GTM-P7985L',
  ES: 'GTM-NTDHTRK',
  FR: 'GTM-5JJGMMS3',
  LU: 'GTM-KB5JLKF',
  NL: 'GTM-W3X5737S',
  'shop-acc.kinepolis.be': 'GTM-P7985L', //Test environment

  // Add new GTM container Id's here when new shops are launched in other countries
};

export const gtmIds = TGTMIds;

import { FC, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Button } from '@shared';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import './logInOrGuest.scss';

const LogInOrGuest: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { login } = useReactOidc();
  const { isGuest } = useCustomerContext();
  const { t } = useTranslation();

  if (!isGuest) return <Redirect to="/checkout" />;

  function handleLogin() {
    setLoading(true);
    login();
  }

  return (
    <section className="account">
      <Helmet>
        <title>{t('ACCOUNT.TITLE')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
      <h1>{t('ACCOUNT.TITLE')}</h1>

      <div className="account__sign-in">
        <h2 className="h4">{t('ACCOUNT.HAVE_ACCOUNT')}</h2>

        <p className="account__sign-in-summary">{t('ACCOUNT.ACCOUNT_ADVANTAGES')}</p>

        <Button loading={loading} onClick={handleLogin}>
          {t('ACCOUNT.LOG_IN_OR_REGISTER')}
        </Button>
      </div>

      <div className="account__guest">
        <h2 className="h4">{t('ACCOUNT.CONTINUE_WITHOUT_ACCOUNT')}</h2>

        <p>{t('ACCOUNT.GUEST_DESCRIPTION')}</p>

        <Button href="/checkout" theme="light-transparent">
          {t('ACCOUNT.GUEST_BUTTON')}
        </Button>
      </div>
    </section>
  );
};

export default LogInOrGuest;

import { FC, useEffect, useState } from 'react';

import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context';
import { useGoogleMaps, useHotjar, useStoreType } from '@hooks';
import { Spinner } from '@shared';
import ScrollToTop from '@shared/scrollToTop/ScrollToTop';
import { getCountryCodeFromDomain } from '@utils/countries';
import { selectLanguageAndCountry } from '@utils/languageHelper';
import i18next from 'i18next';
import { stringify } from 'query-string';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'focus-visible/dist/focus-visible.min.js';

import Toastify from './Toastify';
import defaultHelmetImage from './_assets/images/DefaultHelmetImage.jpg';
import logo from './_assets/images/logo.svg';
import Footer from './_routing/layouts/footer/Footer';
import Header from './_routing/layouts/header/Header';
import Callback from './_routing/layouts/screens/Callback';
import LoadingScreen from './_routing/layouts/screens/LoadingScreen';
import B2BRoutes from './_routing/routes/B2BRoutes';
import B2CRoutes from './_routing/routes/B2CRoutes';
import Announcement from './announcement/Announcement';
import { CompanyContextProvider } from './company/_context/CompanyContext';
import { Config } from './config';
import { env } from './env';
import { useGetGeneralInfo } from './general/_queries/useGetGeneralInfo';
import { useGetLanguage } from './general/_queries/useGetLanguage';
import { useGoogleTagManager } from './googleAnalytics/useGoogleTagManager';
import ModalWrapper from './modal/ModalWrapper';
import { CustomerContextProvider } from './shoppingCart/_context/CustomerContext';

const App: FC = () => {
  const { data: generalInfo, isLoading: isGeneralInfoLoading } = useGetGeneralInfo();
  const { isLoading: isGetLanguageLoading } = useGetLanguage(!!generalInfo?.storeId);
  const { businessPathName, isB2B } = useStoreType();
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([]);

  useGoogleTagManager();
  useGoogleMaps(env.REACT_APP_GOOGLE_PLACES_API_KEY);
  useHotjar(isB2B);

  useEffect(() => {
    // A bit ugly but no info from react-router-dom available to check if we are on the root path
    if (window.location.pathname.length < 2) {
      const path = isB2B ? '/B2B/products' : '/B2C/products';
      window.location.replace(path);
    }
  }, []);

  useEffect(() => {
    if (generalInfo?.languages) {
      setSupportedLanguages(generalInfo.languages.map(lang => lang.code));
    }
  }, [generalInfo]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('preferredLanguage');

    if (storedLanguage) {
      i18next.changeLanguage(storedLanguage);
    } else {
      const countryCode = getCountryCodeFromDomain().toUpperCase();
      const defaultLanguage = supportedLanguages.find(lang => lang.toLowerCase().endsWith(countryCode.toLowerCase()));

      if (defaultLanguage) {
        i18next.changeLanguage(defaultLanguage);
        localStorage.setItem('preferredLanguage', defaultLanguage);
      }
    }
  }, [supportedLanguages]);

  if (isGeneralInfoLoading || isGetLanguageLoading || !generalInfo)
    return (
      <div className="page-transition">
        <Spinner />
      </div>
    );

  const routes = isB2B ? B2BRoutes : B2CRoutes;
  const oidcConfiguration = Config.getOidcConfiguration(businessPathName);
  const { isoCountryCode, possibleLanguage } = selectLanguageAndCountry();

  const customOidcConfig = {
    country: isoCountryCode,
    language: possibleLanguage,
    sourceid: isB2B ? '206' : '131',
    template: isB2B ? 'B2B' : null,
  };

  const authenticationConfig = {
    ...oidcConfiguration,
    metadata: {
      authorization_endpoint: `${oidcConfiguration.authority}/connect/authorize?${stringify(customOidcConfig, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|',
      })}`,
      end_session_endpoint: `${oidcConfiguration.authority}/connect/endsession`,
      introspection_endpoint: `${oidcConfiguration.authority}/connect/introspect`,
      issuer: oidcConfiguration.authority,
      jwks_uri: `${oidcConfiguration.authority}/.well-known/openid-configuration/jwks`,
      revocation_endpoint: `${oidcConfiguration.authority}/connect/revocation`,
      token_endpoint: `${oidcConfiguration.authority}/connect/token`,
      userinfo_endpoint: `${oidcConfiguration.authority}/connect/userinfo`,
    },
  };

  return (
    <AuthenticationProvider
      authenticating={LoadingScreen}
      callbackComponentOverride={LoadingScreen}
      configuration={authenticationConfig}
      loggerLevel={Config.environment === 'development' ? oidcLog.WARN : oidcLog.NONE}
      notAuthenticated={null}
      notAuthorized={null}
      sessionLostComponent={null}
    >
      <BrowserRouter basename={businessPathName}>
        <Switch>
          <Route path="/">
            <Helmet titleTemplate={`%s | ${generalInfo?.name}`}>
              <html lang={i18next.language} />
              <link href={logo} rel="icon" type="image/png" />

              {/* Basic Open Graph Tags */}
              <meta content={generalInfo?.name} property="og:title" />
              <meta content="website" property="og:type" />
              <meta content={window.location.href} property="og:url" />
              <meta content={defaultHelmetImage} property="og:image" />
              <meta content="Eshop Kinepolis Link" property="og:image:alt" />
              <meta content="image/jpeg" property="og:image:type" />
              <meta content="Eshop Kinepolis" property="og:description" />
              <meta content="Eshop Kinepolis" property="og:site_name" />

              {/* Twitter Card Tags */}
              <meta content="summary_large_image" property="twitter:card" />
              <meta content={defaultHelmetImage} property="twitter:image" />
              <meta content="Descriptive text about the image" property="twitter:image:alt" />

              {/* Pinterest Tag */}
              <meta content="nopin" name="pinterest" />
            </Helmet>

            <CompanyContextProvider>
              <CustomerContextProvider>
                <Header
                  languages={generalInfo?.languages}
                  logo={generalInfo?.brandingInfo?.logos?.headerLogo?.url}
                  storeLinks={generalInfo?.storeLinks}
                />
                <Announcement isAnnouncementShown={generalInfo?.showAnnouncement} />
                <Route component={Callback} exact path="/authentication/callback" />
                <Route component={Callback} exact path="/authentication/silent_callback" />
                <Route component={routes} path="/" />

                <Footer
                  languages={generalInfo?.languages}
                  logo={generalInfo?.brandingInfo?.logos?.footerLogo?.url}
                  socialMedia={generalInfo?.brandingInfo?.socialMedia}
                />

                <ModalWrapper />
                <Toastify />
                <ScrollToTop />
              </CustomerContextProvider>
            </CompanyContextProvider>
          </Route>
        </Switch>
      </BrowserRouter>
    </AuthenticationProvider>
  );
};

export default App;

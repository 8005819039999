import { FC } from 'react';

import { useTableSort } from '@hooks';
import { HttpMetadataQuery } from '@http';
import { Icon, Table } from '@shared';
import { TableColumn } from '@shared/table/Table';
import { convertUtcToLocal, formatISOString } from '@utils/dateHelpers';
import { formatPrice } from '@utils/formatHelpers';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { TOrder, getPaymentMethodLabel } from '../../_models/order';
import { getOrderStatusColor, getOrderStatusLabel } from '../../_util/orderHelpers';

import OrderStatusIndicator from './OrderStatusIndicator';

import './ordersTable.scss';

type TProps = {
  isLoading?: boolean;
  orders: TOrder[];
  setQuery: (newQuery: React.SetStateAction<HttpMetadataQuery>) => void;
};

const OrdersTable: FC<TProps> = ({ isLoading, orders = [], setQuery }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const convertDate = (dateString: string) => {
    const localDate = convertUtcToLocal(dateString);
    return formatISOString(localDate.toISOString());
  };

  const { sortFunctions } = useTableSort(
    (column, direction) => setQuery({ skip: 0, sortBy: column, sortDirection: direction }),
    'dateOfOrder',
  );

  const columns: TableColumn[] = [
    {
      className: 'order-number',
      label: t('CUSTOMER_PORTAL.ORDERS.ORDER_NUMBER'),
      name: 'orderNumber',
      sortable: false,
    },
    {
      className: 'date-time',
      label: t('CUSTOMER_PORTAL.ORDERS.DATE_TIME'),
      name: 'dateOfOrder',
      sortable: false,
    },
    {
      className: 'price',
      label: t('CUSTOMER_PORTAL.ORDERS.PRICE'),
      name: 'price',
      sortable: false,
    },
    {
      className: 'payment-method',
      label: t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.TITLE'),
      name: 'paymentMethod',
      sortable: false,
    },
    {
      className: 'status',
      label: t('CUSTOMER_PORTAL.ORDERS.STATUS.TITLE'),
      name: 'status',
      sortable: false,
    },
  ];

  return (
    <>
      <div>
        <Table
          columns={columns}
          data={orders}
          emptyLabel={t('CUSTOMER_PORTAL.ORDERS.EMPTY')}
          isLoading={isLoading}
          renderRow={(row: TOrder) => {
            const url = () => history.push(`${path}/${row.id}`);
            return (
              <tr className="order-list-item" key={row.id}>
                <td className="order-number">
                  <a onClick={url}>
                    <Icon name="OrderBox" />
                    {row.orderNumber}
                  </a>
                </td>
                <td className="date-time">
                  <a onClick={url}>{convertDate(row.dateOfOrder)}</a>
                </td>
                <td className="price">
                  <a onClick={url}>{formatPrice(row.price)}</a>
                </td>
                <td className="payment-method">
                  <a onClick={url}>{getPaymentMethodLabel(row.paymentMethod)}</a>
                </td>
                <td className={classNames('status')}>
                  <a onClick={url}>
                    <OrderStatusIndicator className="status-icon" color={getOrderStatusColor(row.status)} />
                    {getOrderStatusLabel(row.status)}
                  </a>
                </td>
              </tr>
            );
          }}
          sortFunctions={sortFunctions}
        />
      </div>
    </>
  );
};

export default OrdersTable;

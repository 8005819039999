import { FC, useEffect, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useModal, useResponsive, useStoreType } from '@hooks';
import { Button, Chip, FormattedAddress, Icon, Spinner } from '@shared';
import { countries } from '@utils/countries';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

import { useCheckoutContext } from '../../checkout/_context/CheckoutContext';
import { setShippingDetails } from '../../checkout/_context/checkoutActions';
import useCheckoutUser from '../../checkout/_hooks/useCheckoutUser';
import { TDigitalAddress, TPhysicalAddress } from '../../checkout/_models/address';
import { TInvoiceAddress } from '../../checkout/_models/invoice';
import { useDeletePhysicalShippingAddress } from '../../checkout/_queries';
import AddressModal from '../../checkout/overview/modal/addressModal';
import InvoiceModal from '../../checkout/overview/modal/invoiceModal';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

import { getTranslatedCountryName } from './../../_utils/countries';

import './accountInfo.scss';

const AccountInfo: FC = () => {
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isB2B, isB2C } = useStoreType();
  const { customer, isGuest } = useCustomerContext();
  const { selectedCompany, isGetSelectedCompanyLoading } = useCompanyContext();
  const { digitalShippingAddresses, physicalShippingAddresses = [] } = useCheckoutUser();
  const { login } = useReactOidc();
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const history = useHistory();
  const { state, dispatch } = useCheckoutContext();
  const { mutateAsync: deletePhysicalAddress, isLoading: isDeleteAddressLoading } = useDeletePhysicalShippingAddress();
  const [toDeleteId, setToDeleteId] = useState('');

  const [address, setAddress] = useState<TPhysicalAddress | TDigitalAddress | TInvoiceAddress | void>();
  const [renderInvoiceModal, showInvoiceModal] = useModal(modalProps => <InvoiceModal {...modalProps} />);
  const [renderPhysicalAddressModal, showPhysicalAddressModal] = useModal(modalProps => (
    <AddressModal {...modalProps} address={address as TPhysicalAddress} />
  ));

  useEffect(() => {
    if (physicalShippingAddresses?.length === 1 && !state.physicalAddress.id) {
      const singleAddress = physicalShippingAddresses[0];
      dispatch(setShippingDetails({ physicalAddress: singleAddress }));
    }
  }, []);

  function handleLogin() {
    setLoginLoading(true);
    login();
  }

  const sortedPhysicalAddresses = [...physicalShippingAddresses].sort((a, b) => {
    if (state?.physicalAddress?.id === a.id) return -1;
    if (state?.physicalAddress?.id === b.id) return 1;
    return 0;
  });

  if (isGuest) {
    return (
      <div className="guest">
        <Button
          className="guest__login-button"
          hideLabel={isMobile}
          icon="Person"
          loading={loginLoading}
          onClick={handleLogin}
          theme="secondary"
        >
          {t('SHARED.GENERAL.LOGIN')}
        </Button>
      </div>
    );
  }

  if (!selectedCompany && isB2B) {
    if (isGetSelectedCompanyLoading)
      return (
        <div className="account-info-loading">
          <Spinner />
        </div>
      );

    return (
      <div className="no-company-selected">
        <NavLink className="pick-company-button" to={'/pick-company'}>
          <Icon name="Company" size={2} />
          {t('SHARED.GENERAL.PICK_COMPANY')}
        </NavLink>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('CUSTOMER_PORTAL.ACCOUNT_INFO.TITLE')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>
      <div className="back-to-shop-wrapper">
        <Button
          className="back-button"
          icon="ChevronLeft"
          iconPosition="left"
          onClick={() => history.goBack()}
          theme="plain-link"
        >
          {t('SHARED.BUTTONS.BACK_TO_SHOP')}
        </Button>
      </div>
      <>
        <div className="account-info">
          <h1>{t('CUSTOMER_PORTAL.ACCOUNT_INFO.TITLE')}</h1>
          <div className="account-info__section-header">
            <h2>
              {isB2B ? t('CUSTOMER_PORTAL.ACCOUNT_INFO.INVOICE_DETAILS') : t('CUSTOMER_PORTAL.ACCOUNT_INFO.PERSONAL_DETAILS')}
            </h2>
          </div>
          {isB2B ? (
            <>
              <div className="account-info__invoice-details">
                <div className="account-info__invoice-details__user-icon">
                  <Icon name="Company" />
                </div>
                <section>
                  {selectedCompany?.invoiceDetails ? (
                    <FormattedAddress
                      companyName={selectedCompany?.invoiceDetails?.companyName}
                      country={countries.find(country => country.value == selectedCompany?.invoiceDetails?.countryIsoCode)?.value}
                      email={selectedCompany?.invoiceDetails?.email}
                      vat={selectedCompany?.vatNumber || selectedCompany?.enterpriseNumber}
                      {...selectedCompany?.invoiceDetails}
                    />
                  ) : (
                    t('CUSTOMER_PORTAL.ACCOUNT_INFO.NO_INVOICE_DETAILS')
                  )}
                  <div>
                    <Button
                      className="invoice-edit"
                      hideLabel
                      icon="Pencil"
                      iconPosition="right"
                      loading={isGetSelectedCompanyLoading}
                      onClick={showInvoiceModal}
                      theme="plain-link"
                    >
                      {t('SHARED.BUTTONS.EDIT')}
                      <span className="visually-hidden">{t('CHECKOUT.OVERVIEW.INVOICE_DETAILS')}</span>
                    </Button>
                  </div>
                </section>
              </div>
            </>
          ) : (
            <div className="account-info__invoice-details center-icon">
              <div className="account-info__invoice-details__user-icon">
                <Icon name="Person" />
              </div>
              <section>
                <FormattedAddress email={customer?.email} name={customer?.firstName + ' ' + customer?.lastName} />
              </section>
            </div>
          )}

          <div className="account-info__section-header">
            <h2>{t('CUSTOMER_PORTAL.ACCOUNT_INFO.PHYSICAL_SHIPPING')}</h2>
          </div>
          {(customer?.physicalAddresses?.length > 0 && isB2C) ||
          (selectedCompany?.physicalShippingAddresses?.length > 0 && isB2B) ? (
            <ul className="account-info__address-list">
              {sortedPhysicalAddresses?.map(address => (
                <li className="account-info__address-line" key={address?.id}>
                  <address className="inline-icon-wrapper">
                    <Icon
                      className={address?.isPickupPoint ? 'pickup-point-icon' : ''}
                      name={address?.isPickupPoint ? 'Pin' : 'AddressHouse'}
                      size={address?.isPickupPoint ? 2 : 2.5}
                    />
                    <span>{address?.name}</span>
                    <span>
                      {address.street} {address?.number} {address?.box}
                    </span>
                    <span>
                      {address.postalCode} {address?.city}
                    </span>
                    <span>{getTranslatedCountryName(address?.country)}</span>
                  </address>
                  <div className="actions-wrapper">
                    {state?.physicalAddress?.id == address?.id && (
                      <Chip
                        chipColor="table"
                        fontSize="1.2rem"
                        label={t('CUSTOMER_PORTAL.ACCOUNT_INFO.SELECTED')}
                        textColor="grey-200"
                      />
                    )}
                    {sortedPhysicalAddresses?.length > 1 && isB2C && (
                      <Button
                        className="delete-address"
                        loading={isDeleteAddressLoading && toDeleteId == address?.id}
                        onClick={async () => {
                          setToDeleteId(address?.id);
                          await deletePhysicalAddress({ addressId: address?.id });
                          setToDeleteId('');
                        }}
                        theme="primary-link"
                      >
                        <Icon name="Trash" />
                      </Button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="account-info__no-results">{t('CUSTOMER_PORTAL.ACCOUNT_INFO.NO_PHYSICAL_ADDRESSES')}</div>
          )}
          <Button
            className="address__add"
            onClick={() => {
              setAddress();
              showPhysicalAddressModal();
            }}
            theme="secondary"
          >
            {t('CUSTOMER_PORTAL.ACCOUNT_INFO.MANAGE_PHYSICAL_ADDRESSES')}
          </Button>

          <div className="account-info__section-header">
            <h2>{t('CUSTOMER_PORTAL.ACCOUNT_INFO.DIGITAL_SHIPPING')}</h2>
          </div>
          {(customer?.digitalAddresses.length > 0 && isB2C) || (selectedCompany?.digitalShippingAddresses.length > 0 && isB2B) ? (
            <ul className="account-info__address-list">
              {digitalShippingAddresses?.map(address => (
                <li className="account-info__address-line" key={address.id}>
                  <address className="inline-icon-wrapper">
                    <Icon name="Email" /> {address.email}
                  </address>
                </li>
              ))}
            </ul>
          ) : (
            <div className="account-info__no-results">{t('CUSTOMER_PORTAL.ACCOUNT_INFO.NO_DIGITAL_ADDRESSES')}</div>
          )}
          {/* TODO - Add identity server edit email when its implemented (same style and button as the reset password)*/}
        </div>
      </>

      {renderPhysicalAddressModal()}
      {renderInvoiceModal()}
    </>
  );
};

export default AccountInfo;

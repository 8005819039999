import i18next from 'i18next';

import { TPhysicalAddress } from '../../checkout/_models/address';
import { env } from '../../env';
import { TDiscount } from '../../shoppingCart/_models';

export type TOrder = {
  customerName: string;
  dateOfOrder: string;
  id: string;
  invoiceNumber: string;
  orderNumber: string;
  paymentMethod: OrderPaymentMethod;
  poNumber: string;
  price: number;
  status: OrderStatus;
};

export type TOrderDetail = TOrder & {
  companyName: string;
  contactDetails: {
    email: string;
    name: string;
  };
  digitalShippingAddress: string;
  discounts: TDiscount[];
  invoiceDetails: TOrderInvoice;
  physicalAddress: TPhysicalAddress;
  products: TOrderProduct[];
  purchaseOrder: string;
  shippingCost: number;
  statusHistory: TOrderStatusHistory[];
  vatNumber: string;
  vistaOrderNumber: string;
};

export type TOrderProductsOverview = {
  appliedDiscountCodes: TDiscount[];
  discountAmmount: number;
  items: TOrderProduct[];
  shippingCosts: number;
  subtotal: number;
  total: number;
};

export type TOrderInvoice = {
  box?: string;
  city: string;
  companyName: string;
  country: string;
  number: string;
  postalCode: string;
  street: string;
  vat: string;
};

export type TOrderProduct = {
  amount: number;
  name: string;
  price: number;
  totalPrice?: number;
  translations: TOrderProductTranslation[];
};

export type TOrderProductTranslation = {
  key: string;
  langaugeCode: string;
  value: string;
};

export type TOrderStatusHistory = {
  status: string;
  statusDate: Date;
};

export enum OrderPaymentMethod {
  Bankcontact = 'bcmc',
  CreditCard = 'CreditCard',
  Ideal = 'ideal',
  Mastercard = 'mc',
  PayByCheque = 'PayByCheque',
  PayByLink = 'paybylink',
  Payconiq = 'bcmc_mobile',
  PaymentAfterwards = 'PaymentAfterwards',
  Scheme = 'scheme',
  Visa = 'visa',
}

export enum OrderStatus {
  BMECancelled = 'BMECancelled',
  BMEValidationError = 'BMEValidationError',
  Cancelled = 'Cancelled',
  DigitalOrderFailed = 'DigitalOrderFailed',
  DigitalOrderSent = 'DigitalOrderSent',
  OrderCompleted = 'OrderCompleted',
  Ordered = 'Ordered',
  PhysicalOrderCompleted = 'PhysicalOrderCompleted',
  Processing = 'Processing',
}

export const getPaymentMethodLabel = (paymentMethod: OrderPaymentMethod): string => {
  switch (paymentMethod) {
    case OrderPaymentMethod.Bankcontact:
      return 'Bankcontact';
    case OrderPaymentMethod.Ideal:
      return 'IDeal';
    case OrderPaymentMethod.Payconiq:
      return 'Payconiq';
    case OrderPaymentMethod.Mastercard:
      return 'Mastercard';
    case OrderPaymentMethod.Visa:
      return 'Visa';

    case OrderPaymentMethod.PaymentAfterwards:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.PAYMENT_AFTERWARDS');
    case OrderPaymentMethod.PayByLink:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.PAY_BY_LINK');
    case OrderPaymentMethod.PayByCheque:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.PAY_BY_CHEQUE');
    case OrderPaymentMethod.CreditCard:
      return i18next.t('CUSTOMER_PORTAL.ORDERS.PAYMENT_METHOD.CREDITCARD');
    default:
      return paymentMethod;
  }
};

export const getPaymentMethodIcon = (paymentMethod: OrderPaymentMethod) => {
  if (paymentMethod == 'ideal') return `${env.REACT_APP_ICON_URL}/ideal.svg`;

  if (paymentMethod == 'visa') return `${env.REACT_APP_ICON_URL}/visa.svg`;

  if (paymentMethod == 'bcmc') return `${env.REACT_APP_ICON_URL}/bcmc.svg`;

  if (paymentMethod == 'bcmc_mobile') return `${env.REACT_APP_ICON_URL}/bcmc_mobile.svg`;

  if (paymentMethod == 'mc') return `${env.REACT_APP_ICON_URL}/mc.svg`;

  if (paymentMethod == 'scheme') return `${env.REACT_APP_ICON_URL}/mc.svg`;
};

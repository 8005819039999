import { FC } from 'react';

import { useResponsive, useStoreType } from '@hooks';
import { Button, Dropdown, GoogleMapsAddressLookup, InputField } from '@shared';
import Phone from '@shared/input/phone/Phone';
import { countries, getCountries } from '@utils/countries';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';

import useRegisterCompany from './useRegisterCompany';

import './registerCompany.scss';

const RegisterCompany: FC = () => {
  const { t } = useTranslation();
  const { submit, setAttribute, setFormValues, validationErrors, values, isLoading } = useRegisterCompany();
  const { isSmallerThan } = useResponsive();
  const { isB2B, isB2C } = useStoreType();
  const isSmallerThanTablet = isSmallerThan('tablet');
  const { data: generalInfo } = useGetGeneralInfo();
  const { mobilePhoneNumber, mobilePhoneNumberPrefix, phoneNumber, phoneNumberPrefix, countryIsoCode } = values;

  const getCountryCodePrefix = (countryIsoCode: string) => {
    const country = countries.find(c => c.value === countryIsoCode);
    return country ? country.vatPrefix : '';
  };

  function isVatOrEnterpriseRequired(
    enterpriseNumber: string | null,
    vat: string | null,
  ): { enterpriseNumber: boolean; vat: boolean } {
    if (enterpriseNumber && vat) return { enterpriseNumber: true, vat: true };
    if (!enterpriseNumber && !vat) {
      if (isB2B) return { enterpriseNumber: true, vat: true };
      if (isB2C) return { enterpriseNumber: false, vat: false };
    }

    return {
      enterpriseNumber: enterpriseNumber ? true : false,
      vat: vat ? true : false,
    };
  }

  const onVatNumberBlur = () => {
    if (values.countryIsoCode && values.vatNumber) {
      const prefix = getCountryCodePrefix(values.countryIsoCode);
      const vatNumber = values.vatNumber;
      if (vatNumber.indexOf(prefix) === 0 && vatNumber.length === prefix.length) {
        setAttribute('', 'vatNumber');
      }
    }
  };

  const onVatNumberFocus = () => {
    if (values.countryIsoCode && (!values.vatNumber || values.vatNumber.length <= 2)) {
      const prefix = getCountryCodePrefix(values.countryIsoCode);
      if (!values.vatNumber || values.vatNumber.length <= 2) {
        setAttribute(prefix, 'vatNumber');
      }
    }
  };

  return (
    <div className="register-company">
      <h1 className={classNames('page-title')}>{t('REGISTER_COMPANY.TITLE')}</h1>

      <form className="register-company__form" onSubmit={submit}>
        <fieldset>
          <InputField
            autoComplete="organization"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            label={t('REGISTER_COMPANY.FORM.COMPANY_NAME')}
            name="companyName"
            onChange={setAttribute}
            required
            validation={validationErrors.companyName}
            value={values.companyName}
          />

          <InputField
            autoComplete="email"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            label={t('CHECKOUT.ADDRESS.EMAIL')}
            name="companyEmail"
            onChange={setAttribute}
            required
            validation={validationErrors.companyEmail}
            value={values.companyEmail}
          />

          <Dropdown
            className={isSmallerThanTablet ? undefined : 'input-50'}
            hasNoEmtyOption
            label={t('REGISTER_COMPANY.FORM.ADDRESS.COUNTRY')}
            name="countryIsoCode"
            onChange={setAttribute}
            options={getCountries()}
            required
            validation={validationErrors?.countryIsoCode}
            value={values.countryIsoCode}
          />

          {generalInfo?.entrepriseNumbersEnabled && <br />}

          <InputField
            autoComplete="off"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            helpText={t('REGISTER_COMPANY.FORM.VAT_NUMBER.HELP')}
            helpTextTitle={t('REGISTER_COMPANY.FORM.VAT_NUMBER.HELP_TITLE')}
            label={t('REGISTER_COMPANY.FORM.VAT_NUMBER.LABEL')}
            name="vatNumber"
            normalize={value => value.replace(/[.,]/g, '').toUpperCase()}
            onBlur={onVatNumberBlur}
            onChange={setAttribute}
            onFocus={onVatNumberFocus}
            required={isVatOrEnterpriseRequired(values?.enterpriseNumber, values?.vatNumber).vat}
            validation={validationErrors?.vatNumber}
            value={values.vatNumber}
          />

          {generalInfo?.entrepriseNumbersEnabled ? (
            <InputField
              autoComplete="off"
              className={isSmallerThanTablet ? undefined : 'input-50'}
              helpText={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.HELP')}
              helpTextTitle={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.HELP_TITLE')}
              hideAsterisk
              label={t('REGISTER_COMPANY.FORM.ENTERPRISE_NUMBER.LABEL')}
              name="enterpriseNumber"
              normalize={value => value.replace(/[.,]/g, '')}
              onChange={setAttribute}
              required={isVatOrEnterpriseRequired(values?.enterpriseNumber, values?.vatNumber).enterpriseNumber}
              validation={validationErrors?.enterpriseNumber}
              value={values.enterpriseNumber}
            />
          ) : (
            <br />
          )}
        </fieldset>

        <fieldset>
          <legend className="register-company__heading">{t('REGISTER_COMPANY.FORM.ADDRESS.LABEL')}</legend>

          <GoogleMapsAddressLookup setFormValues={setFormValues} />

          <InputField
            autoComplete="off"
            className={isSmallerThanTablet ? undefined : 'input-75'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.EXTRA_INFO')}
            name="extraInfo"
            onChange={setAttribute}
            validation={validationErrors?.extraInfo}
            value={values?.extraInfo}
          />

          <InputField
            autoComplete="address-line1"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.STREET')}
            name="street"
            onChange={setAttribute}
            required
            validation={validationErrors?.street}
            value={values?.street}
          />

          <InputField
            autoComplete="address-line2"
            className={isSmallerThanTablet ? undefined : 'input-25'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.NUMBER')}
            name="number"
            onChange={setAttribute}
            required
            validation={validationErrors?.number}
            value={values?.number}
          />
          <InputField
            autoComplete="address-line3"
            className={isSmallerThanTablet ? undefined : 'input-25'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.BOX')}
            name="box"
            onChange={setAttribute}
            validation={validationErrors?.box}
            value={values?.box}
          />
          <InputField
            autoComplete="postal-code"
            className={isSmallerThanTablet ? undefined : 'input-25'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.POSTAL_CODE')}
            name="postalCode"
            onChange={setAttribute}
            required
            validation={validationErrors?.postalCode}
            value={values?.postalCode}
          />
          <InputField
            autoComplete="address-level2"
            className={isSmallerThanTablet ? undefined : 'input-50'}
            label={t('REGISTER_COMPANY.FORM.ADDRESS.CITY')}
            name="city"
            onChange={setAttribute}
            required
            validation={validationErrors?.city}
            value={values?.city}
          />
        </fieldset>

        <fieldset>
          {'phoneNumberPrefix' in values && (
            <Phone
              countryIsoCode={countryIsoCode}
              required={isB2B}
              setAttribute={setAttribute}
              validation={validationErrors}
              values={{ phoneNumber, phoneNumberPrefix }}
            />
          )}

          {'mobilePhoneNumberPrefix' in values && (
            <Phone
              countryIsoCode={countryIsoCode}
              isMobilePhoneNumber
              setAttribute={setAttribute}
              validation={validationErrors}
              values={{ mobilePhoneNumber, mobilePhoneNumberPrefix }}
            />
          )}
        </fieldset>

        <div className="register-company__form__actions">
          <Button loading={isLoading} type="submit">
            {t('SHARED.BUTTONS.CONTINUE')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterCompany;

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../_context/CustomerContext';

function addDiscount(shoppingCartId: string, discountCode: string): Promise<void> {
  return HttpClient.post(`shoppingcart/${shoppingCartId}/discountcodes`, {
    discountCode,
  });
}

export function useAddDiscount() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { shoppingCartId } = useCustomerContext();

  return useMutation<void, ApiError, string>(
    'addDiscount',
    discountCode => {
      return addDiscount(shoppingCartId, discountCode);
    },
    {
      onError: (error, discountCode) => {
        toast.error(t(`CART.DISCOUNTS.${error.title || 'DISCOUNTCODE_UNKNOWN'}`, { discountCode }));
      },
      onSuccess: (_data, discountCode) => {
        toast.success(t('CART.DISCOUNTS.DISCOUNTCODE_SUCCESS', { discountCode }));

        queryClient.invalidateQueries(['shoppingCart', shoppingCartId]);
      },
    },
  );
}

import { useQuery } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { TGetVouchersResponse, TVoucherMetadata } from '../_models/voucher';

function getVouchers({ take, orderId, skip }: TVoucherMetadata): Promise<TGetVouchersResponse> {
  return HttpClient.get<TGetVouchersResponse>(
    `Eshop/GetVouchers?orderId=${orderId}&skip=${skip}&take=${take}`,
    {},
    {},
    'vouchers',
  );
}

export function useGetVouchers(metadata: TVoucherMetadata) {
  const { orderId, skip, take } = metadata;
  return useQuery<TGetVouchersResponse, ApiError>(['vouchers', orderId, skip, take], () => getVouchers(metadata), {
    cacheTime: 0,
    onError: () => {
      //Do nothing
    },
  });
}

import { FC, useEffect, useRef, useState } from 'react';

import { useForm, useResponsive } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, Checkbox, InputField } from '@shared';
import { formValidator } from '@utils/formValidation';
import { useTranslation } from 'react-i18next';

import Modal from '../../../modal/Modal';
import { useCheckoutContext } from '../../_context/CheckoutContext';
import { setShippingDetails } from '../../_context/checkoutActions';
import useCheckoutUser from '../../_hooks/useCheckoutUser';
import { TDigitalAddress } from '../../_models/address';
import { useCreateDigitalShippingAddress } from '../../_queries/useCreateDigitalShippingAddress';
import { useUpdateDigitalShippingAddress } from '../../_queries/useUpdateDigitalShippingAddress';

import ShippingAddresses from './shippingAddresses';
import './addressModal.scss';

type TProps = {
  address?: TDigitalAddress;
  closeModal: () => void;
  defaultToNewAddress?: boolean;
};

const DigitalModal: FC<TProps> = ({ defaultToNewAddress = false, closeModal, address }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const { customer, digitalShippingAddresses } = useCheckoutUser();
  const { state, dispatch } = useCheckoutContext();
  const [selectedAddressId, setSelectedAddressId] = useState(state?.digitalAddress?.id);
  const initialRender = useRef(true);

  const initialForm = {
    default: !customer || digitalShippingAddresses.length === 0,
    email: '',
  };
  const [currentAddress, setCurrentAddress] = useState<TDigitalAddress | undefined>(
    address ?? initialForm.default ? initialForm : undefined,
  );
  const isOverviewAvailable = !address && !defaultToNewAddress;
  const { isBiggerThan, isSmallerThan } = useResponsive();
  const { mutateAsync: updateDigitalShippingAddress, isLoading: isUpdatingAddress } = useUpdateDigitalShippingAddress();
  const { mutateAsync: createDigitalShippingAddress, isLoading: isCreatingAddress } = useCreateDigitalShippingAddress();

  function validateForm(values: TDigitalAddress): FormValidationErrors<TDigitalAddress> {
    return {
      email: validator.email(values.email),
    };
  }

  const setDigitalPaymentRecipient = () => {
    const digitalAddress = digitalShippingAddresses.find(({ id }) => selectedAddressId === id);
    dispatch(
      setShippingDetails({
        digitalAddress,
      }),
    );
    closeModal();
  };

  const submitForm = async (values: TDigitalAddress) => {
    let newAddress;

    if (values.id) {
      await updateDigitalShippingAddress(values);
    } else {
      newAddress = await createDigitalShippingAddress(values);
      if (newAddress) {
        setSelectedAddressId(newAddress.id);
        setCurrentAddress(newAddress);
      }
    }

    defaultToNewAddress ? closeModal() : setCurrentAddress(undefined);
  };

  const { submit, values, setAttribute, validationErrors, setFormValues } = useForm<TDigitalAddress>({
    initialForm: currentAddress ?? initialForm,
    submitForm,
    validateForm,
  });

  const resetForm = () => {
    setCurrentAddress(initialForm);
    setFormValues(() => initialForm);
  };

  useEffect(() => {
    defaultToNewAddress ? resetForm() : null;
  }, [defaultToNewAddress]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (digitalShippingAddresses.length > 0) {
        const latestAddress = digitalShippingAddresses[digitalShippingAddresses?.length - 1];
        setSelectedAddressId(latestAddress.id);
      }
    }
  }, [digitalShippingAddresses]);

  return (
    <Modal
      onCancel={() => {
        closeModal();
      }}
    >
      <Modal.Header>
        {isSmallerThan('phone') && (
          <Button
            className="modal__header__navigation"
            hideLabel
            icon={isOverviewAvailable ? 'ArrowLeft' : 'Close'}
            iconSize={1.4}
            onClick={closeModal}
            theme="wrapper"
          >
            {isOverviewAvailable ? t('SHARED.BUTTONS.CANCEL') : t('SHARED.BUTTONS.CLOSE')}
          </Button>
        )}
        <Modal.Title>
          {t(
            `CHECKOUT.OVERVIEW.DIGITAL_SHIPPING_MODAL.${
              currentAddress && currentAddress.id
                ? 'EDIT_SHIPPING_ADDRESS'
                : currentAddress
                ? 'ADD_A_SHIPPING_ADDRESS'
                : 'PICK_A_SHIPPING_ADDRESS'
            }`,
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Content>
        {currentAddress && values ? (
          <form aria-labelledby="modal__title" id="shipping-form" noValidate onSubmit={submit}>
            <InputField
              autoComplete="email"
              autoFocus
              name="email"
              onChange={setAttribute}
              type="email"
              validation={validationErrors.email}
              value={values.email}
            />
            {digitalShippingAddresses?.length > 0 && (
              <Checkbox
                checked={values.default}
                disabled={currentAddress && currentAddress.default}
                name="default"
                onChange={setAttribute}
              >
                {t('CHECKOUT.OVERVIEW.DIGITAL_SHIPPING_MODAL.MAKE_DEFAULT_SHIPPING_ADDRESS')}
              </Checkbox>
            )}
          </form>
        ) : (
          <>
            <ShippingAddresses
              addresses={digitalShippingAddresses ?? []}
              checkedId={selectedAddressId}
              onChange={(address: TDigitalAddress) => {
                setSelectedAddressId(address.id);
              }}
              onEdit={(address: TDigitalAddress) => {
                setFormValues(address);
                setCurrentAddress(address);
              }}
            />
            {digitalShippingAddresses?.length <= 0 && (
              <Button className="shipping-list-add" onClick={resetForm} theme="light-transparent">
                {t('CHECKOUT.OVERVIEW.SHIPPING_MODAL.ADD_ADDRESS')}
              </Button>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Buttons>
        {isBiggerThan('phone') && (
          <Button
            disabled={isCreatingAddress || isUpdatingAddress}
            onClick={
              defaultToNewAddress
                ? closeModal
                : isOverviewAvailable && currentAddress
                ? () => setCurrentAddress(undefined)
                : closeModal
            }
            theme="secondary"
          >
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
        )}
        <Button
          form="shipping-form"
          loading={isCreatingAddress || isUpdatingAddress}
          onClick={currentAddress ? undefined : setDigitalPaymentRecipient}
          type="submit"
        >
          {t('SHARED.BUTTONS.SAVE')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default DigitalModal;

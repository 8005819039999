import { FC } from 'react';

import { useStoreType, useResponsive } from '@hooks';
import { Image, LanguagePicker } from '@shared';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { TLanguage, TSocialMedia } from '../../../general/_models/general';

import BusinessContact from './components/businessContact';
import Navigation from './components/navigation';
import PaymentMethods from './components/paymentMethods';
import SocialMedia from './components/socialMedia';
import TargetSwitch from './components/targetSwitch';
import './footer.scss';

type TProps = {
  languages: TLanguage[];
  logo: string;
  socialMedia: TSocialMedia;
};

const Footer: FC<TProps> = ({ logo, socialMedia, languages }) => {
  const { pathname } = useLocation();
  const { isB2B } = useStoreType();

  const isCheckout = pathname.includes('/checkout');
  const isMyAccount = pathname.includes('/account');
  const variant = classNames('global-footer', {
    'footer--b2b': isB2B,
    'footer--b2c': !isB2B,
    'footer--default': !isCheckout && !isMyAccount,
    'footer--orderflow': isCheckout || isMyAccount,
  });
  const { isSmallerThan } = useResponsive();
  const year = new Date().getFullYear();

  return (
    <footer className={variant}>
      <div className="footer__copyright">
        <Image alt="" src={logo} />
        <span>&copy;&nbsp;{year}</span>
      </div>

      {isCheckout || isMyAccount ? (
        <>
          {isB2B ? <BusinessContact /> : <Navigation />}
          <PaymentMethods />
        </>
      ) : (
        <>
          <SocialMedia socialMedia={socialMedia} />
          <PaymentMethods />
          {isSmallerThan('tablet') ? (
            <>
              <TargetSwitch />
              <LanguagePicker languages={languages} />
            </>
          ) : (
            <hr aria-hidden="true" />
          )}
          <Navigation />
        </>
      )}
    </footer>
  );
};

export default Footer;

import React from 'react';

import './orderStatusIndicator.scss';

type TOrderStatusIndicatorProps = {
  className?: string;
  color: string;
};

const OrderStatusIndicator: React.FC<TOrderStatusIndicatorProps> = ({ className, color }) => {
  const dotStyle = {
    backgroundColor: color,
  };

  return <div className={(className += ' indicator ')} style={dotStyle} />;
};

export default OrderStatusIndicator;

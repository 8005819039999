import { useEffect } from 'react';

import { env } from '../env';

const useHotjar = (isB2B: boolean): void => {
  useEffect(() => {
    //Setup hotjar script
    const scriptId = 'hotjar-script';
    const scriptExists = document.getElementById(scriptId) !== null;

    if (!scriptExists) {
      const scriptContent = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${env.REACT_APP_HOTJAR_SITE_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          r.id='${scriptId}';
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      const hotjarScript = document.createElement('script');
      hotjarScript.async = true;
      hotjarScript.innerHTML = scriptContent;

      document.head.appendChild(hotjarScript);
    }

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.parentNode?.removeChild(existingScript);
      }
    };
  }, [isB2B]);
};

export default useHotjar;

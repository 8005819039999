import { FC, useEffect, useRef } from 'react';

import { Spinner } from '@shared';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect, useLocation, useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { useCheckoutContext } from './_context/CheckoutContext';
import { restore, setOrderId, setTimeout as setTimeoutAction } from './_context/checkoutActions';
import usePollPaymentState from './_hooks/usePollPaymentState';
import { PaymentResultCode, PaymentState } from './_models/confirmation';
import { useSubmitCallbackResult } from './_queries/useSubmitCallbackResult';
import { AUTHORIZED_TIMEOUT } from './_util/constants';

const PaymentCallback: FC = () => {
  let { orderId } = useParams<{ orderId?: string }>();
  if (orderId === ':orderId' || orderId === undefined) {
    orderId = null;
  }
  const { t } = useTranslation();
  const localStorageId = useRef<string | null>(localStorage.getItem('orderId'));
  const { mutate: submitCallbackResult, isSuccess, isError } = useSubmitCallbackResult();
  const { paymentData } = usePollPaymentState(orderId, isSuccess);
  const { state: paymentState, resultCode, orderNumber } = paymentData ?? {};
  const history = useHistory();
  const { search } = useLocation();
  const searchQuery = new URLSearchParams(search);
  const redirectResult = searchQuery.get('redirectResult');
  const { dispatch } = useCheckoutContext();
  const timeout = useRef<ReturnType<typeof setTimeout | undefined>>();

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    submitCallbackResult({
      orderId,
      redirectResult,
    });
    if (localStorage.getItem('order')) {
      dispatch(restore(JSON.parse(localStorage.getItem('order'))));
    }
    localStorage.removeItem('orderId');
    localStorage.removeItem('order');
  }, [dispatch]);

  useEffect(() => {
    if (!resultCode) return;

    switch (resultCode) {
      case PaymentResultCode.REDIRECT_SHOPPER:
        break;
      case PaymentResultCode.AUTHORISATION:
      case PaymentResultCode.AUTHORISED:
      case PaymentResultCode.PENDING:
      case PaymentResultCode.RECEIVED:
        timeout.current = setTimeout(() => {
          dispatch(setTimeoutAction(resultCode));
          history.replace('/checkout/confirmation');
        }, AUTHORIZED_TIMEOUT);
        break;
      case PaymentResultCode.CANCELLED:
        toast.error(t('CHECKOUT.PAYMENT.PAYMENT_CANCELLED'));
        history.replace('/checkout/payment');
        break;
      case PaymentResultCode.REFUSED:
        toast.error(t('CHECKOUT.PAYMENT.PAYMENT_REFUSED'));
        history.replace('/checkout/payment');
        break;
      default:
        toast.error(t('CHECKOUT.PAYMENT.PAYMENT_ERROR'));
        history.replace('/checkout/payment');
        break;
    }
  }, [resultCode]);

  useEffect(() => {
    if (paymentState === PaymentState.COMPLETE && redirectResult) {
      dispatch(setOrderId(orderNumber));
      history.replace('/checkout/confirmation');
    }
  }, [paymentState, redirectResult]);

  if (!localStorageId.current || !orderId) {
    return <Redirect to="/" />;
  } else if (isError) {
    toast.error(t('CHECKOUT.PAYMENT.PAYMENT_ERROR'));
    return <Redirect to="/checkout/payment" />;
  }

  return (
    <div className="checkout-empty">
      <Spinner />
    </div>
  );
};

export default PaymentCallback;

import { FC, useEffect } from 'react';

import { useModal } from '@hooks';
import { Button, FormattedAddress, Icon } from '@shared';
import { useTranslation } from 'react-i18next';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { setShippingDetails } from '../_context/checkoutActions';
import useCheckoutUser from '../_hooks/useCheckoutUser';

import AddressModal from './modal/addressModal';

type TOverviewPhysicalAddressProps = {
  backPath?: string;
};

const OverviewPhysicalAddress: FC<TOverviewPhysicalAddressProps> = ({ backPath }) => {
  const { t } = useTranslation();
  const { isGuest } = useCustomerContext();
  const { physicalShippingAddresses = [] } = useCheckoutUser();
  const { state, dispatch } = useCheckoutContext();
  const [renderModal, showModal] = useModal(modalProps => (
    <AddressModal address={isGuest && state?.physicalAddress} isEdit={isGuest && true} {...modalProps} />
  ));

  const physicalAddress = physicalShippingAddresses?.find(({ id }) => id === state.physicalAddress?.id);

  const getAddressIcon = () => {
    const isPickuppoint = isGuest ? state?.physicalAddress?.isPickupPoint : physicalAddress?.isPickupPoint;
    return isPickuppoint ? 'Pin' : 'AddressHouse';
  };

  useEffect(() => {
    if (state.physicalAddress?.street || !physicalShippingAddresses) return;

    // When no other address was selected, find the default for the user and select it
    const defaultForUser = physicalShippingAddresses?.find(({ default: isDefault }) => isDefault);
    if (!defaultForUser) return;

    dispatch(
      setShippingDetails({
        physicalAddress: defaultForUser,
      }),
    );
  }, [state.physicalAddress?.street, physicalShippingAddresses]);

  return (
    <>
      <section className={physicalShippingAddresses?.length && physicalAddress ? '' : 'noAddress'}>
        <header className="actionable-header">
          <h3 className="h2">
            <Icon className="address-icon" name={getAddressIcon()} size={getAddressIcon() == 'Pin' ? 2 : 2.6} />
            {t('CHECKOUT.OVERVIEW.SHIPPING_ADDRESS')}
          </h3>

          <Button
            hideLabel
            href={isGuest ? backPath : undefined}
            icon="Pencil"
            iconPosition="right"
            isNavLink={physicalAddress?.isPickupPoint}
            onClick={!isGuest ? showModal : undefined}
            theme="plain-link"
          >
            {t('SHARED.BUTTONS.EDIT')}
            <span className="visually-hidden">{t('CHECKOUT.OVERVIEW.SHIPPING_ADDRESS')}</span>
          </Button>
        </header>
        <address>
          {isGuest ? (
            <FormattedAddress isPickupPoint={state?.physicalAddress?.isPickupPoint} {...state.physicalAddress} />
          ) : physicalAddress ? (
            <FormattedAddress isPickupPoint={physicalAddress?.isPickupPoint} {...physicalAddress} />
          ) : (
            t('CHECKOUT.OVERVIEW.NO_ADDRESS')
          )}
        </address>
      </section>
      {renderModal()}
    </>
  );
};

export default OverviewPhysicalAddress;

import { TPhysicalAddress } from '../checkout/_models/address';
import { TInvoiceDetails } from '../checkout/_models/invoice';

export function formatPrice(price: number, currency?: string): string {
  return (price || 0).toLocaleString('nl-BE', {
    currency: currency || 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    style: 'currency',
  });
}

export function formatAddress(address: TPhysicalAddress | TInvoiceDetails): string {
  let formattedAddress = '';

  if (!address) return '';

  if ('name' in address && address?.name) formattedAddress += address.name;
  if ('attn' in address && formattedAddress && address?.attn) formattedAddress += ', ';
  if ('attn' in address && address?.attn) formattedAddress += address.attn;

  if ('vat' in address) formattedAddress += `, ${address.vat}, `;

  if (formattedAddress.length && (address.street || address.number || address.box)) formattedAddress += ', ';
  if (address.street) formattedAddress += address.street;
  if (address.street && address.number) formattedAddress += ' ';
  if (address.number) formattedAddress += address.number;
  if (address.number && address.box) formattedAddress += ' ';
  if (address.box) formattedAddress += address.box;

  if (formattedAddress.length && (address.city || address.postalCode)) formattedAddress += ', ';
  if (address.postalCode) formattedAddress += `${address.postalCode} `;
  if (address.city) formattedAddress += address.city;

  if ('country' in address) {
    if (formattedAddress.length && address.country) formattedAddress += ', ';
    if (address.country) formattedAddress += address.country;
  }

  return formattedAddress.trimEnd();
}

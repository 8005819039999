import { FC } from 'react';

import { Icon } from '@shared';
import { useTranslation } from 'react-i18next';

const BusinessContact: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="footer__support">
      <h2>{t('FOOTER.CONTACT.TITLE')}</h2>
      <div>
        <p>{t('FOOTER.CONTACT.SUBTITLE')}</p>
        <dl>
          <div>
            <dt>
              <Icon height={1.3} label={t('SHARED.GENERAL.EMAIL')} name="Email" size={1.8} />
            </dt>
            <dd>
              <a href={`mailto:${t('SHARED.GENERAL.SUPPORT_EMAIL')}`}>{t('SHARED.GENERAL.SUPPORT_EMAIL')}</a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default BusinessContact;

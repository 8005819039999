import { FC, useEffect, useState } from 'react';

import { useStoreType, useForm, useResponsive } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, Checkbox, ErrorMessage, InputField, Spinner } from '@shared';
import { countries, getCountryCodeFromDomain } from '@utils/countries';
import { formValidator } from '@utils/formValidation';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import useCheckoutUser from '../../checkout/_hooks/useCheckoutUser';
import { useUpdateInvoiceAddress } from '../../checkout/_queries';
import { googleAnalyticsAddShippingInfoEvent } from '../../googleAnalytics/dataLayer';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useSetIsPickupPoint } from '../../shoppingCart/_queries';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { setPoNumber, setShippingDetails } from '../_context/checkoutActions';

import OverviewDigitalAddress from './OverviewDigitalAddress';
import OverviewInvoiceDetails from './OverviewInvoiceDetails';
import OverviewOrderSummary from './OverviewOrderSummary';
import OverviewPhysicalAddress from './OverviewPhysicalAddress';

import './overview.scss';

type TFormValues = {
  addressInValidCountry: boolean;
  invoiceDetails: boolean;
  poNumber: string;
  termsAndConditions: boolean;
};

type TProps = {
  backPath: string;
  onSubmit: () => void;
};

const Overview: FC<TProps> = ({ backPath, onSubmit }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const { isB2B, isB2C } = useStoreType();
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const { containsPhysicalProducts, containsDigitalProducts, isLoading, isGuest, cart } = useCustomerContext();
  const { physicalShippingAddresses, invoiceDetails } = useCheckoutUser();
  const { dispatch, state } = useCheckoutContext();
  const physicalAddress = isGuest
    ? { ...state.physicalAddress }
    : physicalShippingAddresses?.find(({ id }) => id === state.physicalAddress?.id);
  const [wantInvoiceToggle, setWantInvoiceToggle] = useState(false);
  const [wantInvoiceLoading, setWantInvoiceLoading] = useState(false);
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);

  const { mutate: updateInvoiceDetails, isLoading: isUpdateInvoiceDetailsLoading } = useUpdateInvoiceAddress();
  const { mutateAsync: setIsPickupPoint } = useSetIsPickupPoint();

  const initialForm: TFormValues = {
    addressInValidCountry: false,
    invoiceDetails: false,
    poNumber: state?.poNumber,
    termsAndConditions: false,
  };

  function validateForm(values: TFormValues): FormValidationErrors<TFormValues> {
    const termsAndConditions = validator.isChecked(values.termsAndConditions);
    const poNumber = validator.maxLength(values.poNumber, 30);
    const invoiceDetailsResult = wantInvoiceToggle && isB2C ? validator.invoideDetailsValid(invoiceDetails) : undefined;
    const addressCountry = isGuest ? state?.physicalAddress?.country : physicalAddress?.country;
    const isAddressInValidCountry =
      isB2B || !containsPhysicalProducts
        ? true
        : addressCountry?.toLocaleUpperCase() === getCountryCodeFromDomain()?.toUpperCase();

    return {
      addressInValidCountry: {
        isValid: isAddressInValidCountry,
        message: isAddressInValidCountry
          ? null
          : t('ERRORS.VALIDATION.ADDRESS_MUST_BE_IN_SAME_COUNTRY_AS_SHOP', {
              countryName: t(
                countries.find(county => county.value.toUpperCase() === getCountryCodeFromDomain()?.toUpperCase())?.label,
              ),
            }),
      },
      invoiceDetails: {
        isValid: wantInvoiceToggle ? invoiceDetailsResult?.isValid : true,
        message:
          isGuest && wantInvoiceToggle
            ? invoiceDetailsResult?.isValid
              ? null
              : t('ERRORS.VALIDATION.MISSING_INVOICE_DETAILS_VALUES')
            : null,
      },
      poNumber: {
        isValid: poNumber.isValid,
        message: poNumber.isValid ? null : t('ERRORS.VALIDATION.INVALID_PO_NUMBER'),
      },
      termsAndConditions: {
        isValid: termsAndConditions.isValid,
        message: termsAndConditions.isValid ? null : t('ERRORS.VALIDATION.TERMS_AND_CONDITIONS'),
      },
    };
  }

  const submitForm = ({ poNumber }: TFormValues) => {
    if (
      !isGuest &&
      ((containsPhysicalProducts && !state.physicalAddress.street) || (containsDigitalProducts && !state.digitalAddress.email))
    ) {
      toast.error(t('CHECKOUT.OVERVIEW.ADDRESS_REQUIRED'));
      return;
    }
    dispatch(setPoNumber(poNumber));
    onSubmit();
  };

  const { submit, values, setAttribute, validationErrors, setValidationErrorsManually } = useForm({
    initialForm,
    submitForm,
    validateForm,
  });

  if (isLoading) return <Spinner />;

  useEffect(() => {
    if (isB2C) {
      if (physicalShippingAddresses?.length === 1 && !state.physicalAddress?.street && containsPhysicalProducts) {
        const singleAddress = physicalShippingAddresses[0];
        dispatch(setShippingDetails({ physicalAddress: singleAddress }));
      }
    }
  }, [physicalShippingAddresses]);

  useEffect(() => {}, [invoiceDetails, physicalAddress]);

  useEffect(() => {
    googleAnalyticsAddShippingInfoEvent(cart);
  }, []);

  useEffect(() => {
    setWantInvoiceLoading(true);
    dispatch(
      setShippingDetails({
        invoiceDetails: { ...state?.invoiceDetails, wantInvoice: wantInvoiceToggle },
        wantInvoice: wantInvoiceToggle,
      }),
    );
    if (!isGuest && isB2C) updateInvoiceDetails({ ...invoiceDetails, wantInvoice: wantInvoiceToggle });
    setWantInvoiceLoading(false);
  }, [wantInvoiceToggle]);

  useEffect(() => {
    setIsPickupPoint({ isPickupPoint: physicalAddress?.isPickupPoint });
  }, [physicalAddress?.isPickupPoint]);

  return (
    <>
      <div className="order-overview">
        <form id="overview-form" onSubmit={submit}>
          <div className="order-overview__container">
            <div className={'order-overview__details'}>
              <h2 className="h4">{t('CHECKOUT.OVERVIEW.YOUR_DETAILS')}</h2>

              {isB2B && <OverviewInvoiceDetails checkoutTooltipVisible />}
              {containsDigitalProducts && <OverviewDigitalAddress />}
              {containsPhysicalProducts && (
                <>
                  <OverviewPhysicalAddress backPath={backPath} />
                  <ErrorMessage isVisible={!validationErrors.addressInValidCountry?.isValid && hasFormBeenSubmitted}>
                    {validationErrors?.addressInValidCountry?.message}
                  </ErrorMessage>
                </>
              )}

              {isB2C && (
                <>
                  <Checkbox
                    checked={wantInvoiceToggle}
                    className="want-invoice-toggle"
                    name="wantInvoiceToggle"
                    onChange={checked => setWantInvoiceToggle(() => checked)}
                    type="toggle"
                  >
                    {t('CHECKOUT.ADDRESS.INVOICE')}
                  </Checkbox>
                  {wantInvoiceToggle && (
                    <>
                      <OverviewInvoiceDetails checkoutTooltipVisible />
                      {isGuest && (
                        <ErrorMessage isVisible={!validationErrors?.invoiceDetails?.isValid && hasFormBeenSubmitted}>
                          {validationErrors?.invoiceDetails?.message}
                        </ErrorMessage>
                      )}
                    </>
                  )}
                </>
              )}

              {isB2B && (
                <InputField
                  autoComplete="off"
                  className={'po-number-input'}
                  helpText={t('CHECKOUT.OVERVIEW.PO_NUMBER_HELP_TEXT')}
                  helpTextTitle={t('CHECKOUT.OVERVIEW.PO_NUMBER_HELP_TITLE')}
                  label={t('CHECKOUT.OVERVIEW.PO_NUMBER')}
                  name="poNumber"
                  onChange={setAttribute}
                  validation={validationErrors?.poNumber}
                  value={values?.poNumber}
                />
              )}
            </div>

            <div className="order-overview__details">
              <OverviewOrderSummary />
            </div>
          </div>

          <p className="order-overview__disclaimer">
            <Trans i18nKey="CHECKOUT.OVERVIEW.CANCEL_PURCHASE">
              The consumer has the right to cancel the purchase within the statutory withdrawal period of 14 calendar days and
              according to the modalities{' '}
              <Button href={t('CHECKOUT.OVERVIEW.CANCEL_PURCHASE_LINK')} theme="plain-link">
                on this page
              </Button>
              .
            </Trans>
          </p>

          <Checkbox
            checked={values.termsAndConditions}
            className={classNames([
              'order-overview__conditions',
              validationErrors?.termsAndConditions && 'order-overview__conditions-error',
            ])}
            name="termsAndConditions"
            onChange={setAttribute}
            required
            validation={validationErrors.termsAndConditions}
          >
            <Trans i18nKey="CHECKOUT.OVERVIEW.ACCEPT_PRIVACY_POLICY">
              I accept the{' '}
              <Button href={t('CHECKOUT.OVERVIEW.PRIVACY_POLICY_LINK')} stopPropagation theme="plain-link">
                privacy policy
              </Button>{' '}
              and{' '}
              <Button href={t('CHECKOUT.OVERVIEW.SALES_CONDITIONS_LINK')} stopPropagation theme="plain-link">
                sales conditions
              </Button>{' '}
              of Kinepolis
            </Trans>
          </Checkbox>
        </form>

        <div className={isMobile ? 'actions--stacked' : 'actions'}>
          <Button
            className="action__confirm"
            form="overview-form"
            icon="ChevronRight"
            iconPosition="right"
            loading={isUpdateInvoiceDetailsLoading || wantInvoiceLoading}
            onClick={() => {
              setHasFormBeenSubmitted(true);
              const validationResponse = validateForm(values);
              setValidationErrorsManually(validationResponse);
            }}
            type="submit"
          >
            {t('SHARED.BUTTONS.CONTINUE')}
          </Button>
          {backPath && (
            <Button className="action__back" href={backPath} icon="ChevronLeft" theme="light-transparent">
              {t('SHARED.BUTTONS.BACK')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Overview;

import { useState } from 'react';

export const SELECTED_COMPANY_STORAGE_KEY = 'kpic-selected-company-id';

type TCurrentCompany = {
  id: string;
};

const useCurrentCompany = () => {
  const [companyId, setCompanyId] = useState<TCurrentCompany | undefined>(() => {
    try {
      const item = sessionStorage.getItem(SELECTED_COMPANY_STORAGE_KEY);
      return item ? { id: item } : undefined;
    } catch (e) {
      return undefined;
    }
  });

  function setCurrentCompany(id: string) {
    sessionStorage.setItem(SELECTED_COMPANY_STORAGE_KEY, id);
    setCompanyId({ id });
  }

  return {
    companyId,
    setCurrentCompany,
  };
};

export default useCurrentCompany;

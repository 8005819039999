import { FC, useEffect } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Spinner } from '@shared';
import { useHistory } from 'react-router';

import { useLogin } from '../../../general/_queries/useLogin';

const CallBack: FC = () => {
  const { oidcUser } = useReactOidc();
  const history = useHistory();
  const { mutate: login } = useLogin();

  useEffect(() => {
    if (oidcUser) {
      login();
      history.replace(oidcUser?.state?.url || '/');
    }
  }, [oidcUser, history, login]);
  return (
    <div className="page-transition">
      <Spinner />
    </div>
  );
};

export default CallBack;

import { TLanguage } from '../_models/general';

const detectLanguage = (possibleLanguages: TLanguage[], fallbackLanguage: string) => {
  // 1: Exact match of browser preference and options
  const exactLanguage = possibleLanguages.find(language => language.code.replace('_', '-') === navigator.language)?.code;

  // 2: Match between options and all languages in the browser
  const browserLanguage = navigator.languages.find(browserLanguage =>
    possibleLanguages.find(language => language.code === browserLanguage),
  );

  // 3: Match between options and all languages in the browser, without (BE, NL, ...)
  const nonCountrySpecificBrowserLanguage = possibleLanguages.find(language =>
    navigator.languages.find(browserLanguage => language.code.substring(0, 2) === browserLanguage.substring(0, 2)),
  )?.code;

  return exactLanguage || browserLanguage || nonCountrySpecificBrowserLanguage || fallbackLanguage;
};

export default detectLanguage;

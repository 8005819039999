import { FC, useId, useMemo, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useForm, useResponsive, useStoreType } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, InputField, QuantityInput } from '@shared';
import { formValidator } from '@utils/formValidation';
import { formatPrice } from '@utils/formatHelpers';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { useCompanyContext } from '../../company/_context/CompanyContext';
import { TProductDetail } from '../../product/_models/products';
import { useCustomerContext } from '../_context/CustomerContext';
import { TAddToShoppingCartForm, TAddToShoppingCartRequest } from '../_models';
import { useAddToShoppingCart } from '../_queries';
import { MAX_PERSONALIZATON_CHAR, getUnitPriceFromPriceRange } from '../_utils';

import './addToCartForm.scss';

type TProps = {
  product: TProductDetail;
};

const AddToCartForm: FC<TProps> = ({ product }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isSmallerThan, isBiggerThan } = useResponsive();
  const validator = formValidator();
  const { isB2B, isB2C } = useStoreType();
  const { isGuest, cart } = useCustomerContext();
  const { login } = useReactOidc();
  const { push } = useHistory();
  const priceId = useId();
  const companyContext = useCompanyContext();

  const companies = isB2B ? companyContext?.companies : undefined;

  const initialForm = useMemo(
    () => ({
      amount: product.minimumOrderQuantity || 1,
      personalizationText: '',
    }),
    [product],
  );

  const { mutate: addToShoppingCart, isLoading } = useAddToShoppingCart({ addToExistingAmount: true, isToastHidden: false });

  const submitForm = (values: TAddToShoppingCartForm) => {
    const existingAmountInCart = cart?.items.find(item => item?.productPackageId === product.id)?.amount || 0;
    const maxOrderAmount = product?.maximumOrderQuantity - 1;

    if (!values.amount) return;

    if (existingAmountInCart >= maxOrderAmount) {
      toast.info(t('CART.MAX_PRODUCTS_PER_ORDER_REACHED'));
      return;
    } else if (existingAmountInCart + values.amount > maxOrderAmount) {
      const amountToAdd = maxOrderAmount - existingAmountInCart;

      toast.info(t('CART.MAX_PRODUCTS_PER_ORDER_REACHED_PARTIAL', { amountAdded: amountToAdd, maxOrderAmount: maxOrderAmount }));
      addToShoppingCart({ ...values, amount: amountToAdd, product });
      return;
    }

    addToShoppingCart({ ...values, product });
  };

  function validateForm(values: TAddToShoppingCartForm): FormValidationErrors<TAddToShoppingCartRequest> {
    return {
      amount: validator.required(values.amount),
      personalizationText: validator.maxLength(values.personalizationText, MAX_PERSONALIZATON_CHAR),
    };
  }

  function handleLogin() {
    setLoading(true);
    login();
  }

  const { submit, values, setAttribute, validationErrors } = useForm({
    initialForm,
    submitForm,
    validateForm,
  });
  const unitPrice = getUnitPriceFromPriceRange(values.amount, product.priceRanges, product.price);

  const renderAddToChartButton = () => {
    if (isB2B && isGuest)
      return (
        <>
          <Button
            hideLabel={isSmallerThan('phone')}
            icon="Person"
            loading={loading}
            onClick={handleLogin}
            theme={isSmallerThan('phone') ? 'primary' : 'light-transparent'}
          >
            {t('SHARED.GENERAL.LOGIN')}
          </Button>
          <small className="add-to-cart-form__add-to-cart-disclaimer">{t('CART.LOGIN_TO_BUY')}</small>
        </>
      );
    if (isB2B && companies == undefined)
      return (
        <>
          <Button
            hideLabel={isSmallerThan('phone')}
            icon="Company"
            onClick={() => push('/register-company')}
            theme={isSmallerThan('phone') ? 'primary' : 'light-transparent'}
          >
            {t('CART.REGISTER_COMPANY')}
          </Button>
          <small className="add-to-cart-form__add-to-cart-disclaimer">{t('CART.REGISTER_COMPANY_TO_BUY')}</small>
        </>
      );

    return (
      <Button hideLabel={isSmallerThan('phone')} icon="AddToCart" loading={isLoading} type="submit">
        {t('CART.ADD_TO_CART')}
      </Button>
    );
  };

  return (
    <div
      className={classNames('add-to-cart-form', {
        'add-to-cart-form--B2B': isB2B,
        'add-to-cart-form--B2C': isB2C,
      })}
    >
      <h1>{product?.name}</h1>
      {isB2C && (
        <p className="product__order-information">
          {t(`PRODUCT.${product.isDigital ? 'DIGITAL_PRODUCT_DISCLAIMER' : 'PHYSICAL_PRODUCT_DISCLAIMER'}`)}
        </p>
      )}

      <form onSubmit={submit}>
        <h2 className="visually-hidden">
          {t('CART.ADD_TO_CART_DESCRIPTION', {
            productName: product?.name,
          })}
        </h2>

        <div>
          <span className="add-to-cart-form__price" id={priceId}>
            <span className={isB2C || isSmallerThan('phone') ? 'visually-hidden' : undefined}>{t('PRODUCT.UNIT_PRICE')}:</span>
            {unitPrice ? formatPrice(unitPrice) : '-'}
          </span>

          <QuantityInput
            hasButtons={isB2C}
            max={product?.maximumOrderQuantity - 1 || 10}
            min={product.minimumOrderQuantity || 1}
            name="amount"
            onChange={amount => setAttribute(amount, 'amount')}
            priceId={priceId}
            theme={isSmallerThan('phone') ? 'light' : 'dark'}
            unitPrice={unitPrice}
            value={values.amount}
          />

          {isB2B && product.hasPersonalization && isBiggerThan('phone') && (
            <InputField
              autoComplete="off"
              className="add-to-cart-form__price__personalization-text"
              label={t('CART.PERSONALIZATION')}
              name="personalizationText"
              onChange={setAttribute}
              placeholder={t('CART.PERSONALIZATION_PLACEHOLDER')}
              validation={validationErrors.personalizationText}
              value={values.personalizationText}
            />
          )}
        </div>

        {isBiggerThan('phone') && (
          <small className="add-to-cart-form__business-disclaimer">
            {isB2B
              ? product.priceRanges.length > 1
                ? t('CART.PRICE_INFO.WITH_PRICE_RANGE', {
                    discountThreshold: product.priceRanges[1].minimumAmount,
                    minimumOrderQuantity: product.minimumOrderQuantity,
                  })
                : t('CART.PRICE_INFO.WITHOUT_PRICE_RANGE', {
                    minimumOrderQuantity: product.minimumOrderQuantity,
                  })
              : t('CART.DISCOUNTS_AVAILABLE')}
          </small>
        )}
        {renderAddToChartButton()}
      </form>

      {isSmallerThan('phone') && (
        <>
          {isB2B && product.hasPersonalization && (
            <InputField
              autoComplete="off"
              className="add-to-cart-form__price__personalization-text --mobile"
              label={t('CART.PERSONALIZATION')}
              name="personalizationText"
              onChange={setAttribute}
              placeholder={t('CART.PERSONALIZATION_PLACEHOLDER')}
              validation={validationErrors.personalizationText}
              value={values.personalizationText}
            />
          )}
          <small className="add-to-cart-form__business-disclaimer --mobile">
            {isB2B
              ? product.priceRanges.length > 1
                ? t('CART.PRICE_INFO.WITH_PRICE_RANGE', {
                    discountThreshold: product.priceRanges[1].minimumAmount,
                    minimumOrderQuantity: product.minimumOrderQuantity,
                  })
                : t('CART.PRICE_INFO.WITHOUT_PRICE_RANGE', {
                    minimumOrderQuantity: product.minimumOrderQuantity,
                  })
              : t('CART.DISCOUNTS_AVAILABLE')}
          </small>
        </>
      )}
    </div>
  );
};

export default AddToCartForm;

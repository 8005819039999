import { FC, useId } from 'react';

import { Button, Icon, Image } from '@shared';
import { TIconType } from '@shared/icon/Icon';
import { formatPrice } from '@utils/formatHelpers';
import classNames from 'classnames';
import { TProduct } from 'product/_models/products';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import './productCard.scss';

type TContentProps = {
  icon: TIconType;
  label: string;
};

type TProps = {
  product: TProduct;
};

const ProductCard: FC<TProps> = ({ product }) => {
  const { composition, isPopular = false, price, name, picture } = product;
  const { url } = useRouteMatch();
  const contents: TContentProps[] = composition?.map(({ icon, title: label }) => ({
    icon,
    label,
  }));
  const detailUrl = `${url.endsWith('/') ? url : `${url}/`}${product.id}`;
  const { t } = useTranslation();
  const key = useId();

  return (
    <article
      aria-label={name}
      className={classNames('product', 'product--card', {
        'product--popular': isPopular,
      })}
      data-popular-label={isPopular ? t('PRODUCT.POPULAR') : undefined}
    >
      <div className="product--card__image">
        <Image alt="" height={110} src={picture} width={110} />
      </div>
      <div className="product--card__information">
        <h2 className="product--card__title">{name}</h2>
        {contents.length > 0 && (
          <ul aria-label={t('PRODUCT.PRODUCT_CONTENTS')} className="product--card__contents">
            {contents.map(({ icon, label }, index) =>
              label ? (
                <li key={key + index}>
                  <Icon name={`Ci${icon}` as TIconType} size={1.6} />
                  <p>{t(label)}</p>
                </li>
              ) : undefined,
            )}
          </ul>
        )}
        <footer>
          <p className="product--card__price">
            <span className="visually-hidden">{t('PRODUCT.PRICE')} </span>
            {formatPrice(price)} <span className="product--card__vat">{t('PRODUCT.VAT_INCLUDED')}</span>
          </p>
        </footer>
      </div>
      <Button hideLabel href={detailUrl} theme="wrapper">
        <span className="visually-hidden">{t('SHARED.BUTTONS.READ_MORE')} </span>
        {name}
      </Button>
    </article>
  );
};

export default ProductCard;

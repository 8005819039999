import { FC, useEffect, useState } from 'react';

import { useStoreType, useLocalStorage, useResponsive } from '@hooks';
import { Button, Dropdown, Spinner, Markdown, Image, Icon } from '@shared';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams, Link } from 'react-router-dom';

import { useGetGeneralInfo } from '../../general/_queries/useGetGeneralInfo';
import LandingPage from '../../landingPage/LandingPage';
import { TProductSortOption, useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TCategoryName } from '../_models/categories';
import { useGetProductsByCategoryId } from '../_queries/useGetProductsByCategoryId';

import { CrossSell, ProductCard } from './components';

import './productOverview.scss';

const ProductOverview: FC = () => {
  const { t } = useTranslation();
  const { isB2B, isB2C } = useStoreType();
  const { isSmallerThan } = useResponsive();
  const isMobile = isSmallerThan('phone');
  const { productSort, updateProductSort } = useCustomerContext();
  const { category } = useParams<{ category?: TCategoryName }>();
  const [preferredStore, setPreferredStore] = useLocalStorage('StorePreference', undefined);
  const { data: generalInfo } = useGetGeneralInfo();

  const categories = [...generalInfo.storeProductCategories];
  const [selectedCategory, setSelectedCategory] = useState(categories[0] || undefined);

  const {
    data: products,
    refetch: refetchProducts,
    isLoading,
  } = useGetProductsByCategoryId({
    ProductCategoryId: isB2C ? selectedCategory?.id : undefined,
    sortBy: productSort,
  });

  useEffect(() => {
    if (category) {
      const newSelectedCategory = categories?.find(c => c.label === category);

      if (newSelectedCategory && newSelectedCategory.id !== selectedCategory?.id) {
        setSelectedCategory(newSelectedCategory);
        refetchProducts();
      }
    }
  }, [category, selectedCategory?.id, categories]);

  if (isB2C && !category) return <Redirect to={`/products/${categories[0]?.label ?? 'movie-boxes'}`} />;

  const translation = category === 'movie-boxes' ? 'MOVIE_BOXES' : 'DISCOUNT_CARDS';

  const renderTabItems = () => {
    if (isB2C && categories?.length > 1) {
      return (
        <div className="categories">
          {categories?.map(({ id, translationKey, label }) => {
            return (
              <Link key={id} to={`/products/${label}`}>
                <button
                  className={selectedCategory?.id === id ? 'active' : undefined}
                  key={id}
                  onClick={() => setSelectedCategory(categories?.find(category => category?.id == id))}
                >
                  {t(translationKey)} {!isMobile && <Icon name={label == 'movie-boxes' ? 'CiTickets' : 'CiVoucher'} size={2.2} />}
                </button>
              </Link>
            );
          })}
        </div>
      );
    }

    return undefined;
  };

  return (
    <>
      <Helmet>
        <title>{t(`PRODUCT.CATEGORIES.${translation}.TITLE`)}</title>
        <meta content={t('SEO.PRODUCT_OVERVIEW')} name="description" />

        <meta content={t(`PRODUCT.CATEGORIES.${translation}.TITLE`)} property="og:title" />
        <meta content={t('SEO.PRODUCT_OVERVIEW')} property="og:description" />

        <meta content="summary_large_image" name="twitter:card" />
        <meta content={t(`PRODUCT.CATEGORIES.${translation}.TITLE`)} name="twitter:title" />
        <meta content={t('SEO.PRODUCT_OVERVIEW')} name="twitter:description" />
      </Helmet>
      {preferredStore === undefined && <LandingPage setPreferredStore={setPreferredStore} />}

      <div className="selligent-wrapper">
        <div className="top-banner">
          {isB2B && (
            <a href={t(`PRODUCT.BANNERS.LINK_B2B`)} rel="noreferrer" target="_blank">
              <Image alt="topbanner" src={t(`PRODUCT.BANNERS.B2B_IMAGE_URL`)} />
            </a>
          )}
          {isB2C && (
            <a href={t(`PRODUCT.BANNERS.LINK_B2C`)}>
              <Image alt="topbanner" src={t(`PRODUCT.BANNERS.B2C_IMAGE_URL`)} />
            </a>
          )}
        </div>
      </div>

      {renderTabItems()}

      <section aria-label={t('PRODUCT.TITLE')} className="product-overview">
        <header>
          <h1 className="">{t('PRODUCT.TITLE')}</h1>
          <Dropdown
            className="product-overview__sort"
            hasNoEmtyOption
            hideAsterisk
            isSearchable={false}
            label={`${t('PRODUCT.SORT.TITLE')}:`}
            name="sort"
            onChange={value => updateProductSort(value as TProductSortOption)}
            options={
              [
                {
                  label: t('PRODUCT.SORT.PRICE'),
                  value: 'price',
                },
                {
                  label: t('PRODUCT.SORT.RECOMMENDED'),
                  value: 'recommendedOrder',
                },
              ] as { label: string; value: TProductSortOption }[]
            }
            required
            value={productSort}
          />
        </header>

        {isLoading ? (
          <div className="product-list--loading">
            <Spinner />
          </div>
        ) : products?.count > 0 ? (
          <ul className="product-list">
            {products?.data.map(product => (
              <li key={product.id}>
                <ProductCard product={product} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="product-list--empty">{t('PRODUCT.NO_RESULTS')}</div>
        )}
      </section>

      <section>
        {isB2C && <CrossSell category={category} refetchProducts={refetchProducts} setSelectedCategory={setSelectedCategory} />}

        <Markdown>{t(`PRODUCT.CATEGORIES.${translation}.INFORMATION`)}</Markdown>

        <Markdown>{t(`PRODUCT.CATEGORIES.${translation}.INFORMATION_CONT`)}</Markdown>

        <div className="faq">
          <h2 className="h3">{t(`PRODUCT.CATEGORIES.${translation}.FAQ.TITLE`)}</h2>
          <Button
            href={t(`PRODUCT.CATEGORIES.${translation}.FAQ.LINK`)}
            icon="ChevronRight"
            iconPosition="right"
            theme="light-transparent"
          >
            {t(`PRODUCT.CATEGORIES.${translation}.FAQ.BUTTON`)}
          </Button>
        </div>
      </section>
    </>
  );
};

export default ProductOverview;

import { FC, Fragment } from 'react';

import { Button } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import { useTranslation } from 'react-i18next';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

const OverviewOrderSummary: FC = () => {
  const { t } = useTranslation();
  const { cart, containsPhysicalProducts } = useCustomerContext();
  return (
    <>
      <header className="actionable-header">
        <h2 className="h4">{t('CHECKOUT.OVERVIEW.YOUR_ORDER')}</h2>
        <Button href="/cart" icon="ChevronRight" iconPosition="right" theme="plain-link">
          {t('SHARED.BUTTONS.EDIT')} <span className="visually-hidden">{t('CHECKOUT.OVERVIEW.YOUR_ORDER')}</span>
        </Button>
      </header>

      <div className="order-overview__summary">
        {cart && (
          <table className="order-overview__summary-table">
            <caption className="visually-hidden">{t('CHECKOUT.OVERVIEW.ORDER_SUMMARY')}</caption>
            <tbody>
              {cart?.items.map(({ name, pricePerItem, amount, price, isDigital, productPackageId }) => (
                <Fragment key={productPackageId}>
                  <tr className="summary__product">
                    <th className="summary__product-title" colSpan={3}>
                      <span className="h2">{name}</span>
                    </th>
                  </tr>
                  <tr>
                    <td className="summary__product-unit-price">{formatPrice(pricePerItem)}</td>
                    <td className="summary__amount">
                      &times;&nbsp;{amount}&nbsp;<span className="aria-hidden">&nbsp;=</span>
                    </td>
                    <td className="summary__product-total">{formatPrice(price)}</td>
                  </tr>
                  <tr>
                    <td className="summary__product-disclaimer" colSpan={3}>
                      <small>{t(`PRODUCT.${isDigital ? 'DIGITAL_PRODUCT_DISCLAIMER' : 'PHYSICAL_PRODUCT_DISCLAIMER'}`)}</small>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>

            <tfoot>
              <tr className="summary__subtotal">
                <th colSpan={2}>{t('CART.SUBTOTAL')}</th>
                <td>{formatPrice(cart.subtotal)}</td>
              </tr>

              {cart?.discounts !== 0 && (
                <tr className="summary__discount">
                  <th colSpan={2}>
                    {t('CART.DISCOUNT')}
                    {cart?.appliedDiscountCodes?.map((appliedCode, idx) => {
                      return (
                        appliedCode?.code && (
                          <Fragment key={'appliedDiscountFragment' + idx}>
                            <br />
                            <small key={'appliedDiscount' + idx}>
                              {t('CART.DISCOUNTS.CODE_APPLIED', {
                                discountCode: appliedCode?.code,
                              })}
                            </small>
                          </Fragment>
                        )
                      );
                    })}
                  </th>
                  <td>-&nbsp;{formatPrice(cart.discounts)}</td>
                </tr>
              )}
              <tr className="summary__shipping">
                <th colSpan={2} rowSpan={1}>
                  {t('CART.SHIPPING')}
                  <small> &nbsp;{t('CHECKOUT.INCLUDING_VAT')}</small>
                </th>

                {containsPhysicalProducts && !cart?.freeShippingCode && cart.shippingCosts !== 0 ? (
                  <td rowSpan={1}>{formatPrice(cart.shippingCosts)}</td>
                ) : (
                  <>
                    <td>{t('CART.FREE')}</td>
                  </>
                )}
              </tr>

              <tr className="summary__total">
                <th colSpan={2}>{t('CART.TOTAL')}</th>
                <td>{formatPrice(cart.total)}</td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </>
  );
};

export default OverviewOrderSummary;

import { FC, useState, useMemo, useEffect } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useForm, useResponsive, useStoreType } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, Checkbox, Icon, InputField } from '@shared';
import { getCountryCodeFromDomain } from '@utils/countries';
import { formValidator } from '@utils/formValidation';
import { TPickupPoint } from 'checkout/_queries';
import { useTranslation } from 'react-i18next';

import SelectPickupPoint from '../../../checkout/address/SelectPickupPoint';
import { useCompanyContext } from '../../../company/_context/CompanyContext';
import Modal from '../../../modal/Modal';
import { useCustomerContext } from '../../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../../_context/CheckoutContext';
import { setShippingDetails } from '../../_context/checkoutActions';
import useCheckoutUser from '../../_hooks/useCheckoutUser';
import { TPhysicalAddress } from '../../_models/address';
import { useCreatePhysicalShippingAddress } from '../../_queries/useCreatePhysicalShippingAddress';
import { useUpdatePhysicalShippingAddress } from '../../_queries/useUpdatePhysicalShippingAddress';
import AddressFields from '../../address/AddressFields';

import ShippingAddresses from './shippingAddresses';

import './addressModal.scss';

type TProps = {
  address?: TPhysicalAddress;
  closeModal: () => void;
  defaultToNewAddress?: boolean;
  isEdit?: boolean;
};

const AddressModal: FC<TProps> = ({ defaultToNewAddress = false, closeModal, address, isEdit = false }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const { isGuest } = useCustomerContext();
  const { isB2B, isB2C } = useStoreType();
  const { oidcUser } = useReactOidc();

  const { state, dispatch } = useCheckoutContext();
  const { physicalShippingAddresses } = useCheckoutUser();
  const { isBiggerThan, isSmallerThan } = useResponsive();
  const [selectedAddressId, setSelectedAddressId] = useState(state?.physicalAddress?.id);
  const selectedAddress = physicalShippingAddresses?.find(({ id }) => id === selectedAddressId);
  const [isNewAddressAdded, setNewAddressAdded] = useState(false);
  const [addPickupPoint, setAddPickupPoint] = useState(false);
  const isOverviewAvailable = !address && !defaultToNewAddress;
  const { selectedCompany } = useCompanyContext();
  const { mutateAsync: updatePhysicalShippingAddress, isLoading: isUpdatingAddress } = useUpdatePhysicalShippingAddress();
  const { mutateAsync: createPhysicalShippingAddress, isLoading: isCreatingAddress } = useCreatePhysicalShippingAddress();

  const initialForm: TPhysicalAddress = useMemo(
    () =>
      isEdit
        ? {
            attn: selectedAddress?.attn || '',
            box: selectedAddress?.box || '',
            city: selectedAddress?.city || '',
            country: getCountryCodeFromDomain().toUpperCase(),
            default: isGuest || physicalShippingAddresses?.length === 0,
            isPickupPoint: isB2C ? selectedAddress?.isPickupPoint : false,
            name: selectedAddress?.name || '',
            number: selectedAddress?.number || '',
            phoneNumber: selectedAddress?.phoneNumber || '',
            phoneNumberPrefix: selectedAddress?.phoneNumberPrefix || '+32',
            pickupPointId: isB2C ? selectedAddress?.pickupPointId : '',
            postalCode: selectedAddress?.postalCode || '',
            street: selectedAddress?.street || '',
          }
        : {
            attn: '',
            box: '',
            city: '',
            country: getCountryCodeFromDomain().toUpperCase(),
            default: false,
            isPickupPoint: false,
            name: isB2B
              ? selectedCompany?.name
              : oidcUser?.profile?.given_name.trim() + ' ' + oidcUser?.profile?.family_name.trim() ?? '',
            number: '',
            phoneNumber: '',
            phoneNumberPrefix: '+32',
            pickupPointId: '',
            postalCode: '',
            street: '',
          },
    [selectedAddress],
  );
  const [currentAddress, setCurrentAddress] = useState<TPhysicalAddress | undefined>(
    address || initialForm.default ? initialForm : undefined,
  );

  function validateForm(values: TPhysicalAddress): FormValidationErrors<TPhysicalAddress> {
    return {
      attn: validator.attn(values.attn),
      box: validator.box(values.street, values.box, values.number),
      city: validator.city(values.city),
      country: validator.required(values.country),
      name: isB2B ? validator.companyName(values.name) : validator.nameLength(values.name),
      number: validator.streetNumber(values.street, values.box, values.number),
      phoneNumber: validator.phoneNumber(values.phoneNumber, values.phoneNumberPrefix),
      postalCode: validator.postalCode(values.postalCode),
      street: validator.street(values.street, values.box, values.number),
    };
  }

  const resetForm = () => {
    setCurrentAddress(initialForm);
    setFormValues(() => initialForm);
  };

  useEffect(() => {
    defaultToNewAddress ? resetForm() : null;
  }, [defaultToNewAddress]);

  useEffect(() => {
    if (isNewAddressAdded) {
      const lastAddedId = physicalShippingAddresses[physicalShippingAddresses?.length - 1]?.id;
      if (lastAddedId) {
        setSelectedAddressId(lastAddedId);
      }
      setNewAddressAdded(false);
    }
  }, [physicalShippingAddresses]);

  const submitForm = async (values: TPhysicalAddress) => {
    try {
      if (isGuest) {
        dispatch(
          setShippingDetails({
            physicalAddress: values,
          }),
        );
        closeModal();
        return;
      }

      if (currentAddress) {
        if (values.id) {
          await updatePhysicalShippingAddress(values);
        } else {
          await createPhysicalShippingAddress(values);
          setNewAddressAdded(true);
        }
        isOverviewAvailable ? setCurrentAddress(undefined) : closeModal();
      } else {
        dispatch(
          setShippingDetails({
            physicalAddress: values,
          }),
        );
        closeModal();
      }

      dispatch(setShippingDetails({ physicalAddress: selectedAddress }));
    } catch (e) {
      console.log(e);
      // Do nothing.
    }
  };

  const { submit, values, setAttribute, validationErrors, setFormValues } = useForm<TPhysicalAddress>({
    initialForm: currentAddress ?? initialForm,
    submitForm,
    validateForm,
  });

  const pickupPointAddresses = physicalShippingAddresses?.filter(address => address.isPickupPoint) ?? [];
  const nonPickupPointAddresses = physicalShippingAddresses?.filter(address => !address.isPickupPoint) ?? [];

  const handlePickupPointSelect = (pickupPoint: TPickupPoint) => {
    setFormValues(currentValues => ({
      ...currentValues,
      city: pickupPoint?.Address?.city.toLowerCase(),
      isPickupPoint: true,
      name: values?.name,
      number: pickupPoint?.Address?.number,
      pickupPointId: pickupPoint?.id,
      postalCode: pickupPoint?.Address?.zipcode,
      street: pickupPoint?.Address?.street.toLowerCase(),
    }));
  };

  return (
    <Modal onCancel={closeModal}>
      <Modal.Header>
        {isSmallerThan('phone') && (
          <Button
            className="modal__header__navigation"
            hideLabel
            icon={isOverviewAvailable ? 'ArrowLeft' : 'Close'}
            iconSize={1.4}
            onClick={currentAddress && values ? () => setCurrentAddress(undefined) : closeModal}
            theme="wrapper"
          >
            {isOverviewAvailable ? t('SHARED.BUTTONS.CANCEL') : t('SHARED.BUTTONS.CLOSE')}
          </Button>
        )}
        <Modal.Title>
          {t(
            `CHECKOUT.OVERVIEW.SHIPPING_MODAL.${
              currentAddress && currentAddress.id
                ? 'EDIT_SHIPPING_ADDRESS'
                : currentAddress
                ? 'ADD_A_SHIPPING_ADDRESS'
                : 'PICK_A_SHIPPING_ADDRESS'
            }`,
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Content>
        {currentAddress && values ? (
          <>
            {addPickupPoint ? (
              <>
                <InputField
                  autoComplete="name"
                  className={`pickup-point-name ${isBiggerThan('phone') && 'half-size'}`}
                  label={t('CHECKOUT.YOUR_DETAILS.LAST_NAME')}
                  name="name"
                  onChange={setAttribute}
                  required
                  validation={validationErrors?.name}
                  value={values?.name}
                />
                <SelectPickupPoint onPickupPointSelect={handlePickupPointSelect} prefillAddress={values} />
              </>
            ) : (
              <>
                <form aria-labelledby="modal__title" id="shipping-form" onSubmit={submit}>
                  <AddressFields
                    autoFocus
                    setAttribute={setAttribute}
                    setFormValues={setFormValues}
                    validationErrors={validationErrors}
                    values={{
                      ...initialForm,
                      ...values,
                    }}
                  />
                  {physicalShippingAddresses?.length >= 1 && (
                    <Checkbox
                      checked={values?.default}
                      disabled={currentAddress && currentAddress.default}
                      name="default"
                      onChange={setAttribute}
                    >
                      {t('CHECKOUT.OVERVIEW.SHIPPING_MODAL.MAKE_DEFAULT_SHIPPING_ADDRESS')}
                    </Checkbox>
                  )}
                </form>
              </>
            )}
          </>
        ) : (
          <>
            {isB2C && (
              <>
                <h2>
                  <Icon className="title-icon" name="Pin" />
                  {t('CHECKOUT.PICKUP_POINT.PICKUP_POINT_TAB_TITLE')}
                </h2>
                {pickupPointAddresses?.length ? (
                  <ShippingAddresses
                    addresses={pickupPointAddresses}
                    checkedId={selectedAddressId}
                    disableEdit
                    key="pickup-points"
                    onChange={(address: TPhysicalAddress) => {
                      setFormValues(address);
                      setSelectedAddressId(address.id);
                    }}
                  />
                ) : (
                  <div className="no-addresses"> {t('CHECKOUT.PICKUP_POINT.NO_PICKUP_POINTS_FOUND')}</div>
                )}
                <Button
                  className="shipping-list-add"
                  onClick={() => {
                    setAddPickupPoint(true);
                    resetForm();
                  }}
                  theme="light-transparent"
                >
                  {t('CHECKOUT.PICKUP_POINT.ADD_PICKUP_POINT')}
                </Button>
              </>
            )}

            <h2>
              <Icon className="title-icon" name="AddressHouse" size={2.5} />
              {t('CHECKOUT.PICKUP_POINT.PHYSICAL_ADDRESS_TAB_TITLE')}
            </h2>
            {nonPickupPointAddresses?.length ? (
              <ShippingAddresses
                addresses={nonPickupPointAddresses}
                checkedId={selectedAddressId}
                key="shipping-addresses"
                onChange={(address: TPhysicalAddress) => {
                  setFormValues(address);
                  setSelectedAddressId(address.id);
                }}
                onEdit={(address: TPhysicalAddress) => {
                  setFormValues(address);
                  setCurrentAddress(address);
                  setSelectedAddressId(address.id);
                }}
              />
            ) : (
              <div className="no-addresses">{t('CHECKOUT.OVERVIEW.SHIPPING_MODAL.NO_ADDRESSES_FOUND')} </div>
            )}
            <Button
              className="shipping-list-add"
              onClick={() => {
                setAddPickupPoint(false);
                resetForm();
              }}
              theme="light-transparent"
            >
              {t('CHECKOUT.OVERVIEW.SHIPPING_MODAL.ADD_ADDRESS')}
            </Button>
          </>
        )}
      </Modal.Content>
      <Modal.Buttons>
        {isBiggerThan('phone') && (
          <Button
            disabled={isCreatingAddress || isUpdatingAddress}
            onClick={isOverviewAvailable && currentAddress ? () => setCurrentAddress(undefined) : closeModal}
            theme="secondary"
          >
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
        )}
        <Button form="shipping-form" loading={isCreatingAddress || isUpdatingAddress} onClick={submit} type="submit">
          {t('SHARED.BUTTONS.SAVE')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default AddressModal;

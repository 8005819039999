import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import fallbackImg from '../../_assets/images/logo.svg';

import './image.scss';

type TProps = {
  alt?: string;
  className?: string;
  height?: number;
  src: string;
  width?: number;
};

const Image: FC<TProps> = ({ alt = '', className, height, src, width }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleError = () => {
    setImageSrc(fallbackImg);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <img
      alt={alt}
      className={classNames('image', className, { ['empty-image']: !src })}
      height={height}
      onError={handleError}
      src={imageSrc}
      width={width}
    />
  );
};

export default Image;

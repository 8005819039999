import { FC, useEffect } from 'react';

import { useResponsive, useStoreType } from '@hooks';
import { Button } from '@shared';
import { useTranslation } from 'react-i18next';

import useCheckoutUser from '../../../checkout/_hooks/useCheckoutUser';
import useInvoiceForm from '../../../checkout/_hooks/useInvoiceForm';
import { useCompanyContext } from '../../../company/_context/CompanyContext';
import Modal from '../../../modal/Modal';
import InvoiceFields from '../../address/InvoiceFields';

import './invoiceModal.scss';

type TProps = {
  closeModal: () => void;
};

const InvoiceModal: FC<TProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { isBiggerThan, isSmallerThan } = useResponsive();
  const { isB2B } = useStoreType();
  const { invoiceDetails } = useCheckoutUser();
  const { selectedCompany } = useCompanyContext();
  const company = isB2B ? selectedCompany : undefined;
  const { isLoading, isSuccess, isUpdatingInvoiceAddress, setAttribute, submit, validationErrors, values, setFormValues } =
    useInvoiceForm({
      invoiceDetails: { ...invoiceDetails, wantInvoice: true },
      onSubmitSuccess: async () => closeModal(),
      selectedCompany: company,
    });

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (isSuccess) closeModal();
  }, [isSuccess, closeModal]);

  return (
    <Modal onCancel={handleCloseModal}>
      <Modal.Header>
        {isSmallerThan('phone') && (
          <Button
            className="modal__header__navigation"
            hideLabel
            icon="Close"
            iconSize={1.4}
            onClick={closeModal}
            theme="wrapper"
          >
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
        )}
        <Modal.Title>{t('CHECKOUT.OVERVIEW.INVOICE_MODAL.EDIT_INVOICE_DETAILS')}</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <form aria-labelledby="modal__title" id="shipping-form" onSubmit={submit}>
          <InvoiceFields
            setAttribute={setAttribute}
            setFormValues={setFormValues}
            validationErrors={validationErrors}
            values={values}
          />
        </form>
      </Modal.Content>
      <Modal.Buttons>
        {isBiggerThan('phone') && (
          <Button onClick={handleCloseModal} theme="secondary">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
        )}
        <Button loading={isUpdatingInvoiceAddress || isLoading} onClick={submit}>
          {t('SHARED.BUTTONS.SAVE')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

export default InvoiceModal;

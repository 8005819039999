import { FC } from 'react';

import { Button, Icon, Radio } from '@shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TAdyenData } from '../_models/payment';
import './payByLink.scss';

type TProps = {
  canPayLater?: boolean;
  checked: boolean;
  isPending?: boolean;
  onSelect?: (checked: boolean) => void;
  onSubmit?: (adyenData: TAdyenData) => Promise<void>;
};

const PAYMENT_METHOD = 'paybylink';

const PayByLink: FC<TProps> = ({ onSubmit, onSelect, isPending, checked, canPayLater = false}) => {
  const { t } = useTranslation();
  const paymentType = canPayLater ? "PAY_BY_LINK_LATER" : "PAY_BY_LINK";

  return (
    <div className={classNames('delayed-payment', isPending && 'delayed-payment--loading')}>
      <h1>{t(`CHECKOUT.PAYMENT.${paymentType}.TITLE`)}</h1>
      <div className="delayed-payment__control">
        <Radio
          checked={checked}
          className="delayed-payment__control__check"
          name="payByLink"
          onChange={checked => {
            onSelect(checked);
          }}
        >
          <div className="delayed-payment__control__radio">
            <Icon name="PayByLink" size={2.7} />
            <p className="delayed-payment__control__radio__text">{t(`CHECKOUT.PAYMENT.${paymentType}.BUTTON`)}</p>
          </div>
        </Radio>
        {checked && (
          <div className="delayed-payment__control__info">
            <p>{t(`CHECKOUT.PAYMENT.${paymentType}.INFO`)}</p>
            <Button
              className="delayed-payment__control__button"
              loading={isPending}
              onClick={() => onSubmit({ paymentMethod: { type: PAYMENT_METHOD } })}
              theme="primary"
            >
              {t(`CHECKOUT.PAYMENT.${paymentType}.BUTTON`)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayByLink;

import * as Sentry from '@sentry/browser';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';

import App from './App';
import translation from './_translations/en.json';
import { Config } from './config';
import * as serviceWorker from './serviceWorker';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/controller/controller.min.css';
import './index.scss';

SwiperCore.use([Pagination, Mousewheel]);

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);
if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}
const preferredLanguage = localStorage.getItem('preferredLanguage');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl_BE',
    lng: preferredLanguage,
    resources: {
      nl_BE: {
        translation,
      },
    },
    returnEmptyString: false,
  });

createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={Config.getQueryClient()}>
    <App />
  </QueryClientProvider>,
);

serviceWorker.unregister();

import { useStoreType } from '@hooks';
import { ApiError, HttpClient } from '@http';
import { TPaymentMethodResponse } from 'checkout/_models/payment';
import { useQuery } from 'react-query';

// TODO: Remove JSON.parse (something changed in BE or Adyen, this is a dirty hack to solve it)
function getPaymentMethods(): Promise<string> {
  return HttpClient.get<string>('payments');
}

export function useGetPaymentMethods() {
  const { isB2B } = useStoreType();
  return useQuery<string, ApiError, TPaymentMethodResponse>(['product', isB2B], () => getPaymentMethods(), {
    select: data => JSON.parse(data),
  });
}

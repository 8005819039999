import { FC } from 'react';

import { useStoreType, useModal } from '@hooks';
import { Button, FormattedAddress, Icon, Tooltip } from '@shared';
import { countries } from '@utils/countries';
import { useTranslation } from 'react-i18next';

import { useCheckoutContext } from '../../checkout/_context/CheckoutContext';
import useCheckoutUser from '../../checkout/_hooks/useCheckoutUser';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

import InvoiceModal from './modal/invoiceModal';

type TProps = {
  checkoutTooltipVisible?: boolean;
  className?: string;
};

const OverviewInvoiceDetails: FC<TProps> = ({ className: classname, checkoutTooltipVisible = false }) => {
  const { t } = useTranslation();
  const { isGuest, customer } = useCustomerContext();
  const { invoiceDetails } = useCheckoutUser();
  const { state } = useCheckoutContext();
  const { isB2B, isB2C } = useStoreType();
  const { selectedCompany } = useCompanyContext();
  const hasInvoiceDetails = isB2B || isB2C ? (isGuest ? state?.invoiceDetails : invoiceDetails) : false;
  const [renderModal, showModal] = useModal(modalProps => <InvoiceModal {...modalProps} />);

  const getCountry = () => {
    return isB2B
      ? countries.find(country => country.value == selectedCompany?.invoiceDetails?.countryIsoCode)?.value
      : countries.find(country => country.value == invoiceDetails?.countryIsoCode)?.value;
  };

  return (
    <>
      <section className={classname && classname}>
        <header className="actionable-header">
          <h3 className="h2">
            <Icon className="invoice-icon" name="Invoices" size={2} />
            {t('CHECKOUT.OVERVIEW.INVOICE_DETAILS')}
            {checkoutTooltipVisible && (
              <Tooltip
                iconSize={1.3}
                text={t('CHECKOUT.OVERVIEW.INVOICE_DETAILS_HELP_TEXT')}
                title={t('CHECKOUT.OVERVIEW.INVOICE_DETAILS_HELP_TITLE')}
              />
            )}
          </h3>

          <Button hideLabel icon="Pencil" iconPosition="right" onClick={showModal} theme="plain-link">
            {t('SHARED.BUTTONS.EDIT')} <span className="visually-hidden">{t('CHECKOUT.OVERVIEW.INVOICE_DETAILS')}</span>
          </Button>
        </header>

        {hasInvoiceDetails ? (
          <FormattedAddress
            country={getCountry()}
            email={isB2C ? (isGuest ? state?.invoiceDetails?.email : customer?.email) : selectedCompany.invoiceDetails?.email}
            enterpriseNumber={invoiceDetails?.enterpriseNumber || selectedCompany?.enterpriseNumber || ''}
            phoneNumber={
              isB2C
                ? isGuest
                  ? state?.invoiceDetails?.phoneNumber
                  : invoiceDetails?.phoneNumber
                : selectedCompany.invoiceDetails?.phoneNumber
            }
            phoneNumberPrefix={
              isB2C
                ? isGuest
                  ? state?.invoiceDetails?.phoneNumberPrefix
                  : invoiceDetails?.phoneNumberPrefix
                : selectedCompany.invoiceDetails?.phoneNumberPrefix
            }
            vat={invoiceDetails?.vat || selectedCompany?.vatNumber || ''}
            {...(isB2C ? (isGuest ? state?.invoiceDetails : invoiceDetails) : selectedCompany?.invoiceDetails)}
          />
        ) : (
          <p>{t('CHECKOUT.OVERVIEW.NO_INVOICE')}</p>
        )}
      </section>

      {renderModal()}
    </>
  );
};

export default OverviewInvoiceDetails;

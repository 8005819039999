import { ApiError, HttpClient } from '@http';
import i18n from 'i18next';
import { useQuery } from 'react-query';

import { TGeneralInfo } from '../_models/general';
import detectLanguage from '../_util/detectLanguage';
import { setBranding } from '../setBranding';

import { useGetConfiguration } from './useGetConfiguration';

async function getGeneralInfo(): Promise<TGeneralInfo> {
  const data = await HttpClient.get<TGeneralInfo>('store', undefined, {
    storeUrl: window.location.href,
  });
  // Prepend current store to array of storeLinks
  data.storeLinks.unshift({
    id: data.storeId,
    name: data.name,
    translationKey: data.translationKey,
    type: data.type,
    webshopHost: data.webshopHost,
  });
  return data;
}

export function useGetGeneralInfo() {
  const { data: config } = useGetConfiguration();
  return useQuery<TGeneralInfo, ApiError>(['general'], () => getGeneralInfo(), {
    cacheTime: 1000 * 60 * 60,
    enabled: !!config,
    onSuccess: data => {
      setBranding(data);
      sessionStorage.setItem('kpic-store-id', data.storeId);

      if (!localStorage.getItem('i18nextLng')) {
        const language = detectLanguage(data.languages, data.languages[0]?.code);

        i18n.changeLanguage(language);
        localStorage.setItem('i18nextLng', i18n.language);
      } else {
        i18n.changeLanguage(localStorage.getItem('i18nextLng'));
      }
    },
  });
}

import { useStoreType } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCompanyContext } from '../../company/_context/CompanyContext';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TPhysicalAddress } from '../_models/address';

function prepareBody(address: TPhysicalAddress) {
  // rename countryIsoCode to country
  const { country: countryIsoCode, ...newBody } = address;
  return { ...newBody, countryIsoCode };
}

function updateB2CPhysicalShippingAdress(newAddress: TPhysicalAddress, userId: string): Promise<null> {
  const { id: addressId, ...body } = newAddress;
  return HttpClient.put(`customers/${userId}/physical-shipping-addresses/${addressId}`, prepareBody(body));
}

function updateB2BPhysicalShippingAdress(address: TPhysicalAddress, companyId: string): Promise<null> {
  const { id: addressId, ...body } = address;
  return HttpClient.put(`b2b/companies/${companyId}/contact-person/physical-shipping-addresses/${addressId}`, prepareBody(body));
}

export function useUpdatePhysicalShippingAddress() {
  const queryClient = useQueryClient();
  const { isB2C } = useStoreType();
  const { selectedCompany } = useCompanyContext();
  const { customer: { id: userId } = {} } = useCustomerContext();

  return useMutation<null, ApiError, TPhysicalAddress>(
    'updatePhysicalShippingAdresses',
    body => (isB2C ? updateB2CPhysicalShippingAdress(body, userId) : updateB2BPhysicalShippingAdress(body, selectedCompany?.id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
      },
    },
  );
}

import { FC, ReactNode } from 'react';

import { Button } from '@shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './orderDetailItem.scss';

type TProps = {
  children: ReactNode;
  className?: string;
  isTablet: boolean;
  isVisible: boolean;
  title: string;
  toggleVisibility: () => void;
};

const OrderDetailItem: FC<TProps> = ({ isVisible, toggleVisibility, isTablet, children, title, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('order-detail-item', className, {
        ['order-detail-item--closed']: !isVisible,
        ['order-detail-item--open']: isVisible,
      })}
    >
      {isTablet ? (
        <Button
          className="order-detail-item-toggle"
          icon="ChevronDown"
          iconPosition="right"
          iconSize={3}
          onClick={toggleVisibility}
          theme="plain-link"
        >
          <h2>{t(title)}</h2>
        </Button>
      ) : (
        <h2 className="order-detail-item__header">{t(title)}</h2>
      )}
      {children}
    </div>
  );
};

export default OrderDetailItem;

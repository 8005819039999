import { useStoreType } from '@hooks';
import { useMutation, useQueryClient } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { TDigitalAddress } from '../_models/address';

function createB2CDigitalShippingAddress(address: TDigitalAddress, userId: string): Promise<void> {
  return HttpClient.post(`customers/${userId}/digital-shipping-addresses`, address);
}

function createB2BDigitalShippingAddress(address: TDigitalAddress, contactPersonId: string): Promise<void> {
  return HttpClient.post(`b2b/companies/${contactPersonId}/contact-person/digital-shipping-address`, address);
}

export function useCreateDigitalShippingAddress() {
  const queryClient = useQueryClient();
  const { isB2C } = useStoreType();
  const { customer: { id: userId } = {} } = useCustomerContext();

  return useMutation<void, ApiError, TDigitalAddress>(
    'updateDigitalShippingAddress',
    body => (isB2C ? createB2CDigitalShippingAddress(body, userId) : createB2BDigitalShippingAddress(body, userId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
      },
    },
  );
}

import { ChangeEvent, FC, FocusEventHandler, KeyboardEventHandler, MutableRefObject, ReactNode, useId, useState } from 'react';

import ErrorMessage from '@shared/errorMessage/ErrorMessage';
import Icon from '@shared/icon/Icon';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useInputError } from '../../../_hooks';
import { useGetGeneralInfo } from '../../../general/_queries/useGetGeneralInfo';
import InputWrapper, { InputWrapperProps } from '../InputWrapper';
import './inputField.scss';

export type InputFieldProps = InputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  disabledIcon?: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  inputRef?: MutableRefObject<HTMLInputElement>;
  normalize?: (value: string) => string | number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: (value: string | number, name: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  pattern?: string;
  placeholder?: string;
  showDisabledIcon?: boolean;
  type?: 'email' | 'number' | 'password' | 'text';
  value?: string;
};

const InputField: FC<InputFieldProps> = ({
  autoComplete,
  autoFocus,
  disabledIcon = <Icon name="Disabled" size={1.6} />,
  showDisabledIcon = true,
  icon,
  iconPosition = 'end',
  normalize,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  type = 'text',
  value,
  pattern,
  inputRef,
  ...wrapperProps
}) => {
  const { disabled, validation, name } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const inputFieldId = useId();
  const { t } = useTranslation();

  const { data: generalInfo } = useGetGeneralInfo();
  const [foundBannedCharacters, setFoundBannedCharacters] = useState<string[]>([]);

  const formatBannedCharacters = (chars: string[]): string => {
    return chars?.map(char => ` ${char} `).join(' ');
  };

  return (
    <InputWrapper id={inputFieldId} {...wrapperProps} showError={showError || foundBannedCharacters?.length > 0}>
      {icon && iconPosition == 'start' && <div className="icon-start">{icon}</div>}
      <input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classnames('input', { error: showError })}
        disabled={disabled}
        id={inputFieldId}
        name={name}
        onBlur={onBlur}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          const bannedCharsFound = generalInfo?.bannedCharacters?.filter(char => value?.includes(char));
          setFoundBannedCharacters(bannedCharsFound);

          let normalizedValue = type === 'number' ? parseFloat(value) : value;
          if (normalize) normalizedValue = normalize(value);
          onChange(normalizedValue, name);
          setDirty();
        }}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        pattern={pattern}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        value={value}
      />
      {icon && iconPosition == 'end' && <div className="icon-end">{icon}</div>}
      {disabled && showDisabledIcon && <div className="icon-end disabled-icon">{disabledIcon}</div>}
      <ErrorMessage isVisible={foundBannedCharacters?.length > 0}>
        {t('ERRORS.VALIDATION.BANNED_INPUT_CHARACTERS_PRESENT', {
          bannedCharacters: formatBannedCharacters(foundBannedCharacters),
          interpolation: { escapeValue: false },
        })}
      </ErrorMessage>
    </InputWrapper>
  );
};

export default InputField;

import { FC, useState } from 'react';

import { Button } from '@shared';
import { FileType, downloadFileFromBase64 } from '@utils/fileHelpers';
import { useTranslation } from 'react-i18next';

import { useGetBase64Vouchers } from '../../_queries/useDownloadVouchers';

type DownloadVouchersButtonProps = {
  orderId: string;
  orderNumber: string;
};

const DownloadVouchersButton: FC<DownloadVouchersButtonProps> = ({ orderId, orderNumber }) => {
  const [languageCode, countryIsoCode] = localStorage.getItem('i18nextLng').split('_');
  const { refetch, isLoading } = useGetBase64Vouchers(orderId, countryIsoCode, languageCode);
  const { t } = useTranslation();
  const [creatingExcel, setCreatingExcel] = useState(false);

  const handleDownload = async () => {
    setCreatingExcel(true);
    const { data: base64Excel } = await refetch();
    if (base64Excel) {
      await downloadFileFromBase64(base64Excel, `Kinepolis_${orderNumber}_Vouchers`, FileType.Excel);
    }
    setCreatingExcel(false);
  };

  return (
    <Button className="download-vouchers" loading={isLoading || creatingExcel} onClick={handleDownload} theme="primary">
      {t('CUSTOMER_PORTAL.ORDERS.DETAIL.DOWNLOAD_All')}
    </Button>
  );
};

export default DownloadVouchersButton;

import { FC } from 'react';

import { useResponsive } from '@hooks';
import { Button, Icon } from '@shared';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './multistep.scss';

type TProps = {
  allowBack?: boolean;
  currentStep?: number;
  label: string;
  steps: {
    label: string;
    path?: string;
  }[];
};

const Multistep: FC<TProps> = ({ allowBack = true, label, currentStep = 1, steps }) => {
  const { t } = useTranslation();
  const { isSmallerThan } = useResponsive();

  return (
    <ul aria-label={label} className="multistep">
      {steps.map(({ label, path }, index) => {
        const isCurrent = currentStep - 1 === index;
        const isCompleted = currentStep > index && !isCurrent;
        const numberClass = classnames('multistep__number', {
          'multistep__number--complete': isCompleted,
          'multistep__number--current': isCurrent && (!path || !allowBack),
        });

        const step = (
          <>
            {isCompleted && <span className="visually-hidden">{t('CHECKOUT.STEPS.COMPLETED')}</span>}
            {isCurrent && !path && <span className="visually-hidden">{t('CHECKOUT.STEPS.CURRENT')}</span>}
            <span className="multistep__number-wrapper">
              <span aria-hidden="true" className={numberClass}>
                {index + 1}
                <span className="multistep__length">
                  <span aria-label={t('CHECKOUT.STEPS.OF')}>/</span>
                  <span>{steps.length}</span>
                </span>
              </span>
              <Icon className="multistep__complete" name="Complete" size={2.4} />
            </span>
            <span
              className={classnames('multistep__label', {
                h1: isSmallerThan('tablet'),
              })}
            >
              {t(label)}
            </span>
          </>
        );

        return (
          <li className={classnames({ multistep__current: isCurrent })} key={label}>
            {currentStep > index && path && allowBack ? (
              <Button aria-current={isCurrent ? 'step' : undefined} href={path} theme="wrapper">
                {step}
              </Button>
            ) : (
              step
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Multistep;

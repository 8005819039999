import { trimObjectValues } from '@utils/objectHelpers';

import { TDetails } from '../_models/details';

import { TCheckoutAddress, TCheckoutState } from './checkoutReducer';

export enum CheckoutAction {
  RESET = 'CHECKOUT_RESET',
  RESTORE = 'CHECKOUT_RESTORE',
  SET_DETAILS = 'CHECKOUT_SET_DETAILS',
  SET_ORDER_ID = 'CHECKOUT_SET_ORDER_ID',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_PO_NUMBER = 'CHECKOUT_SET_PO_NUMBER',
  SET_SHIPPING_DETAILS = 'CHECKOUT_SET_SHIPPING_DETAILS',
  SET_STEP = 'CHECKOUT_STEP',
  SET_TIMEOUT = 'CHECKOUT_SET_TIMEOUT',
}

export type TCheckoutActions =
  | ResetAction
  | RestoreAction
  | SetDetailsAction
  | SetOrderIdAction
  | SetPoNumberAction
  | SetShippingDetailsAction
  | SetStepAction
  | SetTimeoutAction
  | SetPaymentMethodAction;

type RestoreAction = {
  payload: TCheckoutState;
  type: CheckoutAction.RESTORE;
};

type ResetAction = {
  type: CheckoutAction.RESET;
};

type SetPaymentMethodAction = {
  payload: string;
  type: CheckoutAction.SET_PAYMENT_METHOD;
};

type SetDetailsAction = {
  payload: TDetails;
  type: CheckoutAction.SET_DETAILS;
};

type SetOrderIdAction = {
  payload: string;
  type: CheckoutAction.SET_ORDER_ID;
};

type SetPoNumberAction = {
  payload: string;
  type: CheckoutAction.SET_PO_NUMBER;
};

type SetShippingDetailsAction = {
  payload: TCheckoutAddress;
  type: CheckoutAction.SET_SHIPPING_DETAILS;
};

type SetStepAction = {
  payload: number;
  type: CheckoutAction.SET_STEP;
};

type SetTimeoutAction = {
  payload: string;
  type: CheckoutAction.SET_TIMEOUT;
};

export function restore(payload: TCheckoutState): RestoreAction {
  return {
    payload,
    type: CheckoutAction.RESTORE,
  };
}

export function reset(): ResetAction {
  return {
    type: CheckoutAction.RESET,
  };
}

export function setDetails(payload: TDetails): SetDetailsAction {
  return {
    payload,
    type: CheckoutAction.SET_DETAILS,
  };
}

export function setPaymentMethod(payload: string): SetPaymentMethodAction {
  return {
    payload,
    type: CheckoutAction.SET_PAYMENT_METHOD,
  };
}

export function setOrderId(payload: string): SetOrderIdAction {
  return {
    payload,
    type: CheckoutAction.SET_ORDER_ID,
  };
}

export function setPoNumber(payload: string): SetPoNumberAction {
  return {
    payload,
    type: CheckoutAction.SET_PO_NUMBER,
  };
}

export function setStep(payload: number): SetStepAction {
  return {
    payload,
    type: CheckoutAction.SET_STEP,
  };
}

export function setShippingDetails(payload: TCheckoutAddress): SetShippingDetailsAction {
  const trimmedValues = trimObjectValues(payload);

  return {
    payload: trimmedValues,
    type: CheckoutAction.SET_SHIPPING_DETAILS,
  };
}

export function setTimeout(resultCode: string): SetTimeoutAction {
  return {
    payload: resultCode.toLowerCase(),
    type: CheckoutAction.SET_TIMEOUT,
  };
}

import { TValidatorResponse } from '@utils/formValidation';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ApiError, HttpClient } from '../../_http';

type TBody = {
  countryCode: string;
  enterpriseNumber: string;
  vatNumber: string;
};
type TResponse = {
  isCountryValid: boolean;
  isEnterpriseNumberValid: boolean;
  isVatNumberValid: boolean;
};

type TValidateVatAndEnterpriseResponse = {
  enterpriseValidation: TValidatorResponse;
  vatValidation: TValidatorResponse;
};

function getValidationMessage(
  vatOrEnterprise: 'enterprise' | 'vat',
  body: TBody,
  response: TResponse,
  required: boolean,
): string {
  if (body?.enterpriseNumber?.length == 0 && body?.vatNumber?.length == 0) 'ERRORS.VALIDATION.VAT_OR_ENTERPRISE_REQUIRED';

  if (vatOrEnterprise == 'vat') {
    if (response?.isVatNumberValid && response?.isCountryValid) {
      return null;
    }

    return 'ERRORS.VALIDATION.VAT';
  }

  if (vatOrEnterprise == 'enterprise') {
    if (response?.isEnterpriseNumberValid || !required) return null;
    return 'ERRORS.VALIDATION.INVALID_ENTERPRISE_NUMBER';
  }
}

function validateVatAndEnterpriseNumber(body: TBody): Promise<TResponse> {
  return HttpClient.post<TResponse>('validation/vat', body);
}

export function useValidateVatAndEnterpriseNumber() {
  const { t } = useTranslation();

  return useMutation<TValidateVatAndEnterpriseResponse, ApiError, TBody>(
    async body => {
      const data = await validateVatAndEnterpriseNumber({
        countryCode: body?.countryCode.toString(),
        enterpriseNumber: body?.enterpriseNumber?.toString(),
        vatNumber: body?.vatNumber?.toString(),
      });

      const isVatRequired = body?.vatNumber?.length !== 0;
      const isEnterpriseRequired = body?.enterpriseNumber?.length !== 0;

      const enterpriseValidation: TValidatorResponse = {
        isValid: data.isEnterpriseNumberValid,
        message: t(getValidationMessage('enterprise', body, data, isEnterpriseRequired)),
      };
      const vatValidation: TValidatorResponse = {
        isValid: data.isVatNumberValid && data?.isCountryValid,
        message: t(getValidationMessage('vat', body, data, isVatRequired)),
      };

      return { enterpriseValidation, vatValidation };
    },
    { onError: () => {} },
  );
}

import { useMemo } from 'react';

import { getPathname } from '@utils/linkHelpers';

import { useGetGeneralInfo } from '../general/_queries/useGetGeneralInfo';

const useStoreType = () => {
  const { data: { type, webshopHost } = {} } = useGetGeneralInfo();

  const businessPathName = useMemo(() => getPathname(webshopHost), [webshopHost]);
  const isB2B = useMemo(() => type === 'B2B', [type]);
  const isB2C = useMemo(() => type === 'B2C', [type]);

  return {
    businessPathName,
    isB2B,
    isB2C,
  };
};

export default useStoreType;

import React from 'react';

import { TColorVars } from '_types/colors';

import './chip.scss';

type TChipProps = {
  chipColor?: TColorVars;
  fontSize?: string;
  label: string;
  remove?: () => void;
  textColor?: TColorVars;
  textOpacity?: number;
};

const Chip: React.FC<TChipProps> = ({ chipColor, label, fontSize, remove, textOpacity, textColor }) => (
  <div className="chip" style={{ backgroundColor: chipColor && `var(--${chipColor})` }}>
    <span
      className={remove && 'chip-label'}
      style={{
        color: textColor && `var(--${textColor})`,
        fontSize: fontSize && fontSize,
        opacity: textOpacity && `${textOpacity}%`,
      }}
    >
      {label}
    </span>
    {remove && (
      <button className="chip-remove-btn" onClick={remove}>
        x
      </button>
    )}
  </div>
);

export default Chip;

import { FC, useEffect } from 'react';

import { useStoreType, useToggle } from '@hooks';
import { Button, Image, Tooltip } from '@shared';
import Icon, { TIconType } from '@shared/icon/Icon';
import { sortAlphabetically } from '@utils/arrayHelpers';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useCompanyContext } from '../../../company/_context/CompanyContext';
import { TLanguage, TSocialMedia } from '../../../general/_models/general';
import { useGetGeneralInfo } from '../../../general/_queries/useGetGeneralInfo';
import { useCustomerContext } from '../../../shoppingCart/_context/CustomerContext';

import './menu.scss';

type TProps = {
  languages: TLanguage[];
};
type TSocialListItem = {
  icon: TIconType;
  label: string;
  link: string;
  name: keyof TSocialMedia;
};

const Menu: FC<TProps> = ({ languages }) => {
  const { t } = useTranslation();
  const { isB2B, isB2C } = useStoreType();
  const { selectedCompany } = useCompanyContext();
  const [isOpen, toggleMenu] = useToggle(false);
  const { customer } = useCustomerContext();
  const isAdmin = isB2B ? customer?.role === 'Admin' : isB2C;
  const { data: generalInfo } = useGetGeneralInfo();
  const categories = generalInfo?.storeProductCategories;

  const socialListItems: TSocialListItem[] = [
    {
      icon: 'Facebook',
      label: t('FOOTER.SOCIAL_MEDIA.FACEBOOK.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.FACEBOOK.LINK'),
      name: 'facebook',
    },
    {
      icon: 'Instagram',
      label: t('FOOTER.SOCIAL_MEDIA.INSTAGRAM.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.INSTAGRAM.LINK'),
      name: 'instagram',
    },
    {
      icon: 'Youtube',
      label: t('FOOTER.SOCIAL_MEDIA.YOUTUBE.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.YOUTUBE.LINK'),
      name: 'youtube',
    },
    {
      icon: 'LinkedIn',
      label: t('FOOTER.SOCIAL_MEDIA.LINKEDIN.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.LINKEDIN.LINK'),
      name: 'linkedin',
    },
    {
      icon: 'TwitterX',
      label: t('FOOTER.SOCIAL_MEDIA.TWITTER.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.TWITTER.LINK'),
      name: 'twitter',
    },
    {
      icon: 'TikTok',
      label: t('FOOTER.SOCIAL_MEDIA.TIKTOK.LABEL'),
      link: t('FOOTER.SOCIAL_MEDIA.TIKTOK.LINK'),
      name: 'tiktok',
    },
  ];

  useEffect(() => {
    document.body.classList[isOpen ? 'add' : 'remove']('scroll-lock');

    return () => {
      document.body.classList.remove('scroll-lock');
    };
  }, [isOpen]);

  return (
    <>
      <Button
        aria-controls="mobile-menu"
        aria-expanded="false"
        aria-haspopup="true"
        className="global-header__content__menu"
        hideLabel
        icon="Menu"
        iconSize={2.4}
        onClick={toggleMenu}
        theme="plain-link"
      >
        {t('SHARED.NAVIGATION.TOGGLE_MENU')}
      </Button>

      {isOpen && (
        <nav className="global-nav">
          <div className="controls-wrapper">
            <Button hideLabel icon="Close" onClick={toggleMenu} theme="plain-link">
              {t('SHARED.NAVIGATION.CLOSE_MENU')}
            </Button>
            <div className="logo-wrapper">
              <Image alt="Home" className="logo" src={generalInfo?.brandingInfo?.logos?.headerLogo?.url} />
            </div>
          </div>
          {isB2C && (
            <ul>
              <div className="categories">
                {categories?.map(({ id, translationKey, label }) => {
                  return (
                    <li key={id}>
                      <Button
                        href={`/products/${label || 'movie-boxes'}`}
                        icon={label == 'movie-boxes' ? 'CiTickets' : 'CiVoucher'}
                        onClick={toggleMenu}
                        theme="plain-link"
                      >
                        {t(translationKey)}
                      </Button>
                    </li>
                  );
                })}
              </div>
            </ul>
          )}

          {isB2B && selectedCompany && (
            <ul>
              <li className="selected-company">
                <p>{selectedCompany.name}</p>
                <small>
                  <div>{isAdmin ? t('SHARED.GENERAL.IS_ADMIN') : t('SHARED.GENERAL.IS_NOT_ADMIN')}</div>
                  <Tooltip iconSize={1.1} text={isAdmin ? t('SHARED.GENERAL.ADMIN_INFO') : t('SHARED.GENERAL.NOT_ADMIN_INFO')} />
                </small>
              </li>
            </ul>
          )}
          <ul>
            {sortAlphabetically('type', generalInfo?.storeLinks).map(storeLink => (
              <li key={`mobile-menu-storeLink-${storeLink.id}`}>
                <a className="store-link" href={storeLink?.webshopHost + '/products'}>
                  <Icon name={storeLink?.type == 'B2B' ? 'Company' : 'Person'} size={1.6} /> {storeLink.name}
                </a>
              </li>
            ))}
          </ul>
          <ul>
            <li>
              <a href={t('SHARED.NAVIGATION.KINEPOLIS.LINK')} rel="noreferrer" target="_blank">
                {t('SHARED.NAVIGATION.KINEPOLIS.LABEL')}
              </a>
            </li>
            <li>
              <a href={t('PRODUCT.CATEGORIES.MOVIE_BOXES.FAQ.LINK')} rel="noreferrer" target="_blank">
                {t('SHARED.NAVIGATION.FAQ.LABEL')}
              </a>
            </li>
          </ul>
          <ul className="language-picker">
            {languages.map(language => (
              <li key={language.code}>
                <Button
                  onClick={() => {
                    i18next.changeLanguage(language.code);
                    localStorage.setItem('preferredLanguage', language.code);
                  }}
                  theme="plain-link"
                >
                  {language.name}
                </Button>
              </li>
            ))}
          </ul>
          <ul>
            <div className="socials">
              {socialListItems
                .filter(item => generalInfo?.brandingInfo?.socialMedia?.[item.name])
                .map(({ icon, label, link }) => (
                  <Button hideLabel href={link} icon={icon} iconSize={2.8} key={link} theme="plain-link">
                    {label}
                  </Button>
                ))}
            </div>
          </ul>
        </nav>
      )}
    </>
  );
};

export default Menu;

import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useStoreType } from '@hooks';
import { ApiError, HttpClient } from '@http';
import { TB2BInvoiceDetails } from 'checkout/_models/invoice';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { TCompanyDetails } from '../_models';

type GetCompanyDetailsResponseInvoiceDetails = TB2BInvoiceDetails & {
  streetName: string;
  streetNumber: string;
  streetNumberSuffix: string;
  zipCode: string;
};

type TGetCompanyDetailsResponse = TCompanyDetails & {
  domainName: string;
  invoiceDetails: GetCompanyDetailsResponseInvoiceDetails;
};

async function getCompanyDetails(id: string): Promise<TCompanyDetails> {
  if (!id) return;

  const response = await HttpClient.get<TGetCompanyDetailsResponse>(`b2b/companies/${id}`);

  const { streetName, streetNumber, streetNumberSuffix, zipCode, ...invoiceDetailsRest } = response.invoiceDetails;

  const mappedInvoiceDetails: TB2BInvoiceDetails = {
    ...invoiceDetailsRest,
    box: streetNumberSuffix,
    number: streetNumber,
    postalCode: zipCode,
    street: streetName,
  };

  return {
    ...response,
    invoiceDetails: mappedInvoiceDetails,
  };
}

export function useGetCompanyDetails(companyId: string) {
  const { isB2B } = useStoreType();
  const { oidcUser } = useReactOidc();
  const history = useHistory();

  return useQuery<TCompanyDetails, ApiError>(['companies', companyId], () => getCompanyDetails(companyId), {
    enabled: isB2B && !!oidcUser,
    onError: (error: ApiError) => {
      if (error?.statusCode === 403) return;
      if (error?.statusCode === 404) {
        history.push('b2b/register-company');
        return;
      }
    },
  });
}

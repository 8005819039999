import { FC } from 'react';

import { Button, Markdown } from '@shared';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import './registerCompany.scss';

const RegisterCompanyResult: FC = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const getTranslationModifier = () => {
    if (pathname.includes('/success')) return 'SUCCESS';
    if (pathname.includes('/exists')) return 'EXISTS';
    if (pathname.includes('/not-authorized')) return 'NOT_AUTHORIZED';
  };

  return (
    <div className="register-company__result">
      <h1>{t(`REGISTER_COMPANY.RESULT.${getTranslationModifier()}.TITLE`)}</h1>

      {/* Render 5 body paragraphs at most */}
      {[...Array(5)].map((_, i) => {
        const translationKey = `REGISTER_COMPANY.RESULT.${getTranslationModifier()}.BODY_${i}`;

        if (i18n.exists(translationKey)) {
          return (
            <p key={`result-body-${i}`}>
              <Markdown>{t(translationKey)}</Markdown>
            </p>
          );
        }
      })}

      <Button className="register-company__result__button" href="/" theme="light-transparent">
        {t('REGISTER_COMPANY.RESULT.RETURN')}
      </Button>
    </div>
  );
};

export default RegisterCompanyResult;

import React, { useState, ReactNode } from 'react';

import './tabBar.scss';
import Button from '@shared/button/Button';

type Tab = {
  tabContent: ReactNode;
  tabTitle: string;
};

type TabsProps = {
  tabSelectedByDefault?: number;
  tabs: Tab[];
};

const TabBar: React.FC<TabsProps> = ({ tabs, tabSelectedByDefault }) => {
  const [activeTab, setActiveTab] = useState(tabSelectedByDefault <= tabs?.length - 1 ? tabSelectedByDefault : 0);

  return (
    <div className="tabs__wrapper">
      <ul className="buttons__wrapper" style={{ listStyleType: 'none', padding: 0 }}>
        {tabs.map((tab, index) => (
          <Button
            className={`tab__button ${activeTab === index ? 'selected' : ''}`}
            icon={activeTab === index ? 'ActiveRadio' : 'InactiveRadio'}
            key={index}
            onClick={() => setActiveTab(index)}
            theme="light-transparent"
          >
            {tab.tabTitle}
          </Button>
        ))}
      </ul>
      <div className="tab__content">{tabs[activeTab].tabContent}</div>
    </div>
  );
};

export default TabBar;

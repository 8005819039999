import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useForm, useStoreType } from '@hooks';
import { FormValidationErrors, SubmitFormFunction } from '@hooks/useForm';
import { getCountryCodeFromDomain } from '@utils/countries';
import { formValidator } from '@utils/formValidation';
import { selectLanguageAndCountry } from '@utils/languageHelper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCheckoutContext } from '../../checkout/_context/CheckoutContext';
import { setShippingDetails } from '../../checkout/_context/checkoutActions';
import { TCheckoutAddress } from '../../checkout/_context/checkoutReducer';
import { useCompanyContext } from '../_context/CompanyContext';
import { RegisterCompanyStatus, TCompanyPayload } from '../_models';
import { useGetCompanies, useRegisterCompanyQuery } from '../_queries';

import { useValidateVatAndEnterpriseNumber } from './../_queries/useValidateVatAndEnterpriseNumber';

const useRegisterCompany = () => {
  const validator = formValidator();
  const { mutateAsync: validateVatAndEnterprise } = useValidateVatAndEnterpriseNumber();

  const { push: navigate } = useHistory();
  const { mutateAsync: registerCompany, error: registerCompanyError } = useRegisterCompanyQuery();
  const { refetch: refetchCompanies } = useGetCompanies();
  const { setCurrentCompany } = useCompanyContext();
  const { isoCountryCode, possibleLanguage } = selectLanguageAndCountry();
  const { t } = useTranslation();
  const { oidcUser } = useReactOidc();
  const { isB2B } = useStoreType();
  const { dispatch } = useCheckoutContext();

  const initialForm: TCompanyPayload = {
    box: '',
    city: '',
    companyEmail: oidcUser?.profile?.email || '',
    companyName: '',
    countryIsoCode: getCountryCodeFromDomain().toUpperCase() ?? '',
    enterpriseNumber: '',
    extraInfo: '',
    kinepolisCountry: isoCountryCode ?? '',
    language: possibleLanguage ?? '',
    mobilePhoneNumber: '',
    mobilePhoneNumberPrefix: '+32',
    number: '',
    phoneNumber: '',
    phoneNumberPrefix: '+32',
    postalCode: '',
    street: '',
    vatNumber: '',
  };

  function isVatOrEnterpriseRequired(enterpriseNumber: string, vat: string) {
    const isEnterpriseFilled = Boolean(enterpriseNumber);
    const isVatFilled = Boolean(vat);

    return {
      enterpriseRequired: isEnterpriseFilled && isVatFilled,
      vatRequired: isVatFilled || !isEnterpriseFilled,
    };
  }

  async function validateForm(values: TCompanyPayload): Promise<FormValidationErrors<TCompanyPayload>> {
    const { enterpriseValidation, vatValidation } = await validateVatAndEnterprise({
      countryCode: values?.countryIsoCode,
      enterpriseNumber: values?.enterpriseNumber,
      vatNumber: values?.vatNumber,
    });

    const { enterpriseRequired, vatRequired } = isVatOrEnterpriseRequired(values?.enterpriseNumber, values?.vatNumber);

    return {
      box: validator.box(values?.street, values?.box, values?.number),
      city: validator.city(values?.city),
      companyEmail: validator.companyEmail(values?.companyEmail),
      companyName: validator.companyName(values?.companyName),
      countryIsoCode: validator.required(values?.countryIsoCode),
      enterpriseNumber: enterpriseRequired ? enterpriseValidation : undefined,
      extraInfo: validator.maxLength(values?.extraInfo, 30),
      mobilePhoneNumber: validator.phoneNumber(values?.mobilePhoneNumber, values?.mobilePhoneNumberPrefix),
      number: validator.streetNumber(values.street, values?.box, values?.number),
      phoneNumber: validator.phoneNumber(values?.phoneNumber, values?.phoneNumberPrefix, isB2B),
      postalCode: validator.postalCode(values?.postalCode),
      street: validator.street(values.street, values?.box, values?.number),
      vatNumber: vatRequired ? vatValidation : undefined,
    };
  }

  const submitForm: SubmitFormFunction<TCompanyPayload> = async values => {
    try {
      const { isExistingCompany, companyId, status } = await registerCompany(values);
      await refetchCompanies();
      setCurrentCompany(companyId);

      dispatch(
        setShippingDetails({
          digitalAddress: {
            default: true,
            email: values?.companyEmail,
          },
        } as TCheckoutAddress),
      );

      if (status === RegisterCompanyStatus.NotAuthorized) {
        navigate('register-company/not-authorized');
      } else if (isExistingCompany) {
        navigate('register-company/exists');
      } else {
        navigate('register-company/success');
      }
    } catch (e) {
      registerCompanyError?.statusCode == 500 && toast.error(t('ERRORS.ADD_COMPANY'));
    }
  };

  return useForm<TCompanyPayload, TCompanyPayload>({
    initialForm,
    submitForm,
    validateForm,
  });
};

export default useRegisterCompany;

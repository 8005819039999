import { FC } from 'react';

import { Button, Image } from '@shared';
import { useTranslation } from 'react-i18next';

import logo from '../../../_assets/images/logo.svg';
import './notFound.scss';

const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="not-found">
        <span className="not-found__title">{t('SHARED.NAVIGATION.PAGE_NOT_FOUND')}</span>
        <Button className="home-button" href="/" isNavLink>
          {t('SHARED.BUTTONS.BACK_TO_SHOP')}
        </Button>
      </div>
      <div className="backdrop">
        <Image className="backdrop__logo" src={logo} />
      </div>
    </>
  );
};

export default NotFound;

import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { useForm } from '@hooks';
import { FormValidationErrors } from '@hooks/useForm';
import { Button, InputField } from '@shared';
import { formValidator } from '@utils/formValidation';
import { useTranslation } from 'react-i18next';

import { TAddToShoppingCartForm } from '../_models';
import { MAX_PERSONALIZATON_CHAR } from '../_utils';
import './editPersonalization.scss';

type TFormType = Pick<TAddToShoppingCartForm, 'personalizationText'>;
type TProps = {
  onSubmit: (value: string) => Promise<void>;
  value?: string;
};

const EditPersonalizationText: FC<TProps> = ({ value: initialValue, onSubmit }) => {
  const { t } = useTranslation();
  const validator = formValidator();
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.code === 'Escape') {
      event.preventDefault();
      setValue(initialValue);
      setIsEditing(false);
    }
  }

  function validateForm(values: TFormType): FormValidationErrors<TFormType> {
    return {
      personalizationText: validator.combine(
        validator.required(values.personalizationText),
        validator.maxLength(values.personalizationText, MAX_PERSONALIZATON_CHAR),
      ),
    };
  }

  async function submitForm(form: TFormType) {
    if (isEditing) {
      const trimmedValue = form.personalizationText.trim();
      setValue(trimmedValue);
      setIsSubmitting(true);

      await onSubmit(trimmedValue);
      setIsSubmitting(false);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef.current]);

  const { submit, values, setAttribute, validationErrors } = useForm({
    initialForm: {
      personalizationText: initialValue,
    },
    submitForm,
    validateForm,
  });

  return (
    <form className="edit-personalization" onSubmit={submit}>
      {!isEditing ? (
        <span>
          {t('CART.PROVIDED_BY')}: {value}
        </span>
      ) : (
        <>
          <span className="edit-personalization__prefix">{t('CART.PROVIDED_BY')}:</span>
          <InputField
            autoComplete="off"
            className="edit-personalization__input"
            inputRef={inputRef}
            name="personalizationText"
            onChange={setAttribute}
            onKeyDown={handleKeyDown}
            validation={validationErrors.personalizationText}
            value={values.personalizationText}
          />
        </>
      )}
      <Button
        className="edit-personalization__button"
        disabled={isSubmitting}
        icon={isEditing ? 'CiCheck' : 'Pencil'}
        theme="secondary"
        type="submit"
      >
        {t('SHARED.BUTTONS.EDIT')}
      </Button>
    </form>
  );
};

export default EditPersonalizationText;

import { FC } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

const Toastify: FC = () => (
  <ToastContainer
    bodyClassName="toast-body"
    className="toast-container"
    closeOnClick
    draggable
    draggablePercent={70}
    hideProgressBar
    limit={5}
    newestOnTop
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    position="top-right"
    progressClassName="toast-progress"
    toastClassName="toast"
  />
);

export default Toastify;

export enum FileType {
  Excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  MP4 = 'video/mp4',
  PDF = 'application/pdf',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  Text = 'text/plain',
}

export const downloadFileFromBase64 = async (base64String: string, fileName: string, fileType: FileType): Promise<void> => {
  const byteCharacters = window.atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  const blobUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');

  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);

  downloadLink.click();
  document.body.removeChild(downloadLink);

  URL.revokeObjectURL(blobUrl);
};

import { useStoreType } from '@hooks';
import { ApiError, HttpClient } from '@http';
import i18next from 'i18next';
import { useQuery } from 'react-query';

import { TProductDetail } from '../_models/products';

function getProduct(id: string): Promise<TProductDetail> {
  return HttpClient.get<TProductDetail>(`products/${id}`);
}

export function useGetProduct(id: string) {
  const { isB2B } = useStoreType();
  return useQuery<TProductDetail, ApiError>(['product', id, isB2B], () => getProduct(id), {
    select: product => {
      const translations = product.translations.filter(translation => translation.languageCode === i18next.language);
      return {
        ...product,
        extraInfo: product.extraInfo.map(compositionItem => {
          return {
            ...compositionItem,
            content: translations.find(translation => translation.key === compositionItem.content)?.value,
            title: translations.find(translation => translation.key === compositionItem.title)?.value,
          };
        }),
        name: translations.find(translation => translation.key === 'product.name')?.value || product.name,
        translations,
        whatsInside: product.whatsInside.map(compositionItem => {
          return {
            ...compositionItem,
            content: translations.find(translation => translation.key === compositionItem.content)?.value,
            title: translations.find(translation => translation.key === compositionItem.title)?.value,
          };
        }),
      };
    },
  });
}

import i18n from 'i18next';

type TCountry = {
  label: string;
  phonePrefix: string;
  punctuationPositionsVat: number[];
  regexCompanyNumber: RegExp;
  regexVAT: RegExp;
  value: string;
  vatPrefix: string;
};

export const getTranslatedCountryName = (countryIsoCode: string): string => {
  const countryLabel = countries.find(country => country.value == countryIsoCode)?.label;
  return countryLabel ? i18n.t(countryLabel) : '';
};

export const getCountries = () => {
  return countries.map(country => ({ label: i18n.t(country.label), value: country.value }));
};

export const getCountryCodeFromDomain = (): string => {
  const domain = window.location.hostname;
  const match = domain.match(/\.([a-z]{2})$/);
  return match[1];
};

export const formatVatOrBusinessNumber = (input: string, countryCode: string, vatOrBusiness: 'vat' | 'business') => {
  const country = countries.find(c => c.value === countryCode);

  if (!country) return input;

  const regex = vatOrBusiness === 'vat' ? country.regexVAT : country.regexCompanyNumber;

  if (!regex) return input;

  const alphanumericInput = input.replace(/[^a-zA-Z0-9]/g, '');

  const prefix = alphanumericInput.match(/[a-zA-Z]+/g)?.join('') || '';
  const numbers = alphanumericInput.match(/\d+/g)?.join('') || '';

  let formattedNumbers = '';

  if (vatOrBusiness === 'vat' && country.punctuationPositionsVat) {
    let position = 0;
    for (let i = 0; i < numbers.length; i++) {
      if (position < country.punctuationPositionsVat.length && i === country.punctuationPositionsVat[position]) {
        formattedNumbers += '.';
        position++;
      }
      formattedNumbers += numbers[i];
    }
  } else {
    formattedNumbers = numbers;
  }

  return prefix + ' ' + formattedNumbers;
};

export const countries: TCountry[] = [
  {
    label: 'SHARED.COUNTRIES.AUSTRIA',
    phonePrefix: '+43',
    punctuationPositionsVat: null,
    regexCompanyNumber: /FN\s\d{6}[a-zA-Z]/,
    regexVAT: /^((AT)?U[0-9]{8)$/i,
    value: 'AT',
    vatPrefix: 'AT',
  },
  {
    label: 'SHARED.COUNTRIES.BELGIUM',
    phonePrefix: '+32',
    punctuationPositionsVat: [4, 7],
    regexCompanyNumber: /\d{10}/,
    regexVAT: /^((BE)?0[0-9]{9})$/i,
    value: 'BE',
    vatPrefix: 'BE',
  },
  {
    label: 'SHARED.COUNTRIES.BULGARIA',
    phonePrefix: '+359',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{9}/,
    regexVAT: /^((BG)?[0-9]{9,10})$/i,
    value: 'BG',
    vatPrefix: 'BG',
  },
  {
    label: 'SHARED.COUNTRIES.CROATIA',
    phonePrefix: '+385',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{11}/,
    regexVAT: /^((HR)?[0-9]{11})$/i,
    value: 'HR',
    vatPrefix: 'HR',
  },
  {
    label: 'SHARED.COUNTRIES.CYPRUS',
    phonePrefix: '+357',
    punctuationPositionsVat: null,
    regexCompanyNumber: /HE\d{6}/,
    regexVAT: /^((CY)?[0-9]{8}[A-Z])$/i,
    value: 'CY',
    vatPrefix: 'CY',
  },
  {
    label: 'SHARED.COUNTRIES.CZECH_REPUBLIC',
    phonePrefix: '+420',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{8}/,
    regexVAT: /^((CZ)?[0-9]{8,10})$/i,
    value: 'CZ',
    vatPrefix: 'CZ',
  },
  {
    label: 'SHARED.COUNTRIES.DENMARK',
    phonePrefix: '+45',
    punctuationPositionsVat: [2, 4, 6],
    regexCompanyNumber: /\d{8}/,
    regexVAT: /^((DK)?[0-9]{8})$/i,
    value: 'DK',
    vatPrefix: 'DK',
  },
  {
    label: 'SHARED.COUNTRIES.GERMANY',
    phonePrefix: '+49',
    punctuationPositionsVat: null,
    regexCompanyNumber: /HR[AB]\s\d{5}/,
    regexVAT: /^((DE)?[0-9]{9})$/i,
    value: 'DE',
    vatPrefix: 'DE',
  },
  {
    label: 'SHARED.COUNTRIES.ESTONIA',
    phonePrefix: '+372',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{8}/,
    regexVAT: /^((EE)?[0-9]{9})$/i,
    value: 'EE',
    vatPrefix: 'EE',
  },
  {
    label: 'SHARED.COUNTRIES.GREECE',
    phonePrefix: '+30',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{10}/,
    regexVAT: /^((EL)?[0-9]{9} )$/i,
    value: 'GR',
    vatPrefix: 'EL',
  },
  {
    label: 'SHARED.COUNTRIES.SPAIN',
    phonePrefix: '+34',
    punctuationPositionsVat: null,
    regexCompanyNumber: /B\d{8}/,
    regexVAT: /^(ES[A-Z][0-9]{7}(?:[0-9]|[A-Z]))$/i,
    value: 'ES',
    vatPrefix: 'ES',
  },
  {
    label: 'SHARED.COUNTRIES.FINLAND',
    phonePrefix: '+358',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{7}-\d{1}/,
    regexVAT: /^((FI)?[0-9]{8})$/i,
    value: 'FI',
    vatPrefix: 'FI',
  },
  {
    label: 'SHARED.COUNTRIES.FRANCE',
    phonePrefix: '+33',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{9}/,
    regexVAT: /^((FR)?[0-9A-Z]{2}[0-9]{9})$/i,
    value: 'FR',
    vatPrefix: 'FR',
  },
  {
    label: 'SHARED.COUNTRIES.UNITED_KINGDOM',
    phonePrefix: '+44',
    punctuationPositionsVat: [3, 6],
    regexCompanyNumber: /^(OC|LP|SC|NI|[a-zA-Z]{2})?\d{6,8}$/,
    regexVAT:
      /^((GB)?([GDHA]?[0-9]{3} ?[0-9]{4} ?[0-9]{2}|(GB)?[GDHA]?[0-9]{3} ?[0-9]{4} ?[0-9]{2} ?[0-9]{3}|(GB)?[0-9]{9} ?[0-9]{3}|(GB)?[0-9]{12}|(GB)?GD[0-9]{3}))$/i,
    value: 'GB',
    vatPrefix: 'GB',
  },

  {
    label: 'SHARED.COUNTRIES.HUNGARY',
    phonePrefix: '+36',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{2}-\d{2}-\d{6}/,
    regexVAT: /^((HU)?[0-9]{8})$/i,
    value: 'HU',
    vatPrefix: 'HU',
  },
  {
    label: 'SHARED.COUNTRIES.IRELAND',
    phonePrefix: '+353',
    punctuationPositionsVat: null,
    regexCompanyNumber: /([a-zA-Z]{1,2})?\d{6}/,
    regexVAT: /^((IE)?[0-9]{7}[A-Z]{1,2}|(IE)?[0-9][A-Z][0-9]{5}[A-Z])$/i,
    value: 'IE',
    vatPrefix: 'IE',
  },
  {
    label: 'SHARED.COUNTRIES.ITALY',
    phonePrefix: '+39',
    punctuationPositionsVat: null,
    regexCompanyNumber: /REA\s\d{7}/,
    regexVAT: /^((IT)?[0-9]{11})$/i,
    value: 'IT',
    vatPrefix: 'IT',
  },
  {
    label: 'SHARED.COUNTRIES.LITHUANIA',
    phonePrefix: '+370',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{9}/,
    regexVAT: /^((LT)?([0-9]{9}|[0-9]{12}))$/i,
    value: 'LT',
    vatPrefix: 'LT',
  },
  {
    label: 'SHARED.COUNTRIES.LUXEMBOURG',
    phonePrefix: '+352',
    punctuationPositionsVat: null,
    regexCompanyNumber: /B\d{6}/,
    regexVAT: /^((LU)?[0-9]{8})$/i,
    value: 'LU',
    vatPrefix: 'LU',
  },
  {
    label: 'SHARED.COUNTRIES.LATVIA',
    phonePrefix: '+371',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{11}/,
    regexVAT: /^((LV)?[0-9]{11})$/i,
    value: 'LV',
    vatPrefix: 'LV',
  },
  {
    label: 'SHARED.COUNTRIES.MALTA',
    phonePrefix: '+356',
    punctuationPositionsVat: null,
    regexCompanyNumber: /C\d{5}/,
    regexVAT: /^((MT)?[0-9]{8})$/i,
    value: 'MT',
    vatPrefix: 'MT',
  },
  {
    label: 'SHARED.COUNTRIES.NETHERLANDS',
    phonePrefix: '+31',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{8}/,
    regexVAT: /^((NL)?[0-9]{9}B[0-9]{2})$/i,
    value: 'NL',
    vatPrefix: 'NL',
  },
  {
    label: 'SHARED.COUNTRIES.POLAND',
    phonePrefix: '+48',
    punctuationPositionsVat: null,
    regexCompanyNumber: /KRS\s\d{6}/,
    regexVAT: /^((PL)?[0-9]{10})$/i,
    value: 'PL',
    vatPrefix: 'PL',
  },
  {
    label: 'SHARED.COUNTRIES.PORTUGAL',
    phonePrefix: '+351',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{9}/,
    regexVAT: /^((PT)?[0-9]{9})$/i,
    value: 'PT',
    vatPrefix: 'PT',
  },
  {
    label: 'SHARED.COUNTRIES.ROMANIA',
    phonePrefix: '+40',
    punctuationPositionsVat: null,
    regexCompanyNumber: /J\d{2}\d{3}\d{4}/,
    regexVAT: /^((RO)?[0-9]{2,10})$/i,
    value: 'RO',
    vatPrefix: 'RO',
  },
  {
    label: 'SHARED.COUNTRIES.SWEDEN',
    phonePrefix: '+46',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{6}-\d{4}/,
    regexVAT: /^((SE)?[0-9]{12})$/i,
    value: 'SE',
    vatPrefix: 'SE',
  },
  {
    label: 'SHARED.COUNTRIES.SLOVENIA',
    phonePrefix: '+386',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{10}/,
    regexVAT: /^((SI)?[0-9]{8})$/i,
    value: 'SI',
    vatPrefix: 'SI',
  },
  {
    label: 'SHARED.COUNTRIES.SLOVAKIA',
    phonePrefix: '+421',
    punctuationPositionsVat: null,
    regexCompanyNumber: /\d{8}/,
    regexVAT: /^((SK)?[0-9]{10})$/i,
    value: 'SK',
    vatPrefix: 'SK',
  },
];

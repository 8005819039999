import i18next from 'i18next';
import { useQuery } from 'react-query';

import { ApiError, HttpClient } from '../../_http';
import { TShoppingCart } from '../_models';

function getShoppingCart(shoppingCartId: string): Promise<TShoppingCart> {
  return HttpClient.get<TShoppingCart>(`shoppingcart/${shoppingCartId}`);
}

export function useGetShoppingCart({ shoppingCartId, enabled = true }: { enabled?: boolean; shoppingCartId: string }) {
  return useQuery<TShoppingCart, ApiError>(['shoppingCart', shoppingCartId], () => getShoppingCart(shoppingCartId), {
    enabled,
    select: cart => {
      return {
        ...cart,
        items: cart.items.map(item => ({
          ...item,
          name:
            item.translations
              .filter(translation => translation.languageCode === i18next.language)
              .find(translation => translation.key === 'product.name')?.value || item.name,
        })),
      };
    },
  });
}

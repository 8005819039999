import { FC } from 'react';

import { useStoreType } from '@hooks';
import { Button } from '@shared';
import { useTranslation } from 'react-i18next';

const TargetSwitch: FC = () => {
  const { t } = useTranslation();
  const { isB2B } = useStoreType();

  return (
    <ul className="footer__target-switcher">
      <li>
        <Button href={t('SHARED.NAVIGATION.KINEPOLIS.LINK')} theme="plain-link">
          {t('SHARED.NAVIGATION.KINEPOLIS.LABEL')}
        </Button>
      </li>
      <li>
        <a href={isB2B ? '/../B2C/products' : '/../B2B/products'}>{t(`SHARED.NAVIGATION.${isB2B ? 'B2C' : 'B2B'}`)}</a>
      </li>
    </ul>
  );
};
export default TargetSwitch;

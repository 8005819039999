import { ApiError, HttpClient } from '@http';
import { useMutation } from 'react-query';

import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';

function login(shoppingCartId: string): Promise<void> {
  return HttpClient.post('login', { shoppingCartId });
}

export function useLogin() {
  const { shoppingCartId } = useCustomerContext();
  return useMutation<void, ApiError>(['login'], () => login(shoppingCartId), {});
}

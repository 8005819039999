import { CSSProperties, FC } from 'react';

import { useTranslation } from 'react-i18next';

import './productHeader.scss';

interface Style extends CSSProperties {
  '--background'?: string;
}

type TProps = {
  isDigital: boolean;
  picture: string;
  title: string;
};

const ProductHeader: FC<TProps> = ({ title, isDigital, picture }) => {
  const { t } = useTranslation();
  return (
    <header className="product--detail__header">
      <h1>{title}</h1>
      <figure
        className="fill-width"
        style={
          {
            '--background': `url(${picture})`,
          } as Style
        }
      >
        <img alt={title} height="auto" src={picture} width="226" />
      </figure>
      <p className="product__order-information">
        {t(`PRODUCT.${isDigital ? 'DIGITAL_PRODUCT_DISCLAIMER' : 'PHYSICAL_PRODUCT_DISCLAIMER'}`)}
      </p>
    </header>
  );
};

export default ProductHeader;

import { FC, useEffect, useCallback, useRef } from 'react';

import { HttpSortDirection } from '@http';
import { Button, Markdown, Spinner } from '@shared';
import { useTranslation } from 'react-i18next';

import { useGetOrderDetail, useGetOrders } from '../../customerPortal/_queries';
import { googleAnalyticsPurchaseEvent } from '../../googleAnalytics/dataLayer';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { reset } from '../_context/checkoutActions';

import './confirmation.scss';

const Confirmation: FC = () => {
  const { t } = useTranslation();
  const { updateShoppingCartId } = useCustomerContext();
  const { dispatch, state } = useCheckoutContext();
  const isPayByLink = state.paymentMethod === 'paybylink';
  const { containsPhysicalProducts } = useCustomerContext();
  const containsPhysicalProductsInitialRef = useRef<boolean | undefined>(undefined);

  const {
    data: orders,
    refetch: refetchOrders,
    isLoading: ordersLoading,
  } = useGetOrders({
    skip: 0,
    sortBy: 'dateOfOrder',
    sortDirection: HttpSortDirection.Descending,
    take: 1,
  });

  const {
    data: orderDetail,
    refetch: refetchOrderDetail,
    isLoading: orderDetailLoading,
  } = useGetOrderDetail(orders?.data[0]?.id);

  useEffect(() => {
    containsPhysicalProductsInitialRef.current = containsPhysicalProducts;
    updateShoppingCartId();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (orderDetail) {
      googleAnalyticsPurchaseEvent(orderDetail, containsPhysicalProductsInitialRef.current);
    } else {
      refetchOrderDetail();
      refetchOrders();
    }
  }, [orderDetail, orders?.data[0]?.id, refetchOrderDetail]);

  const renderResponse = useCallback(() => {
    switch (state.timeout?.toLowerCase()) {
      case 'authorisation':
      case 'authorised':
      case 'received':
        return (
          <>
            <p>
              <Markdown>{t('CHECKOUT.CONFIRMATION.SYNC_ERROR.SOMETHING_WENT_WRONG')}</Markdown>
            </p>
            <p>
              <Markdown>{t('CHECKOUT.CONFIRMATION.SYNC_ERROR.CONTACT')}</Markdown>
            </p>
          </>
        );
      case 'pending':
        return (
          <>
            <p>
              <Markdown>{t('CHECKOUT.CONFIRMATION.PAYMENT_PENDING.PAYMENT_NOT_COMPLETE')}</Markdown>
            </p>
            <p>
              <Markdown>{t('CHECKOUT.CONFIRMATION.PAYMENT_PENDING.CONTACT')}</Markdown>
            </p>
          </>
        );
    }
  }, [state.timeout, t]);

  if (orderDetailLoading || ordersLoading)
    return (
      <div className="order-confirmation">
        <Spinner />
      </div>
    );

  return (
    <div className="order-confirmation">
      {!state.orderId && !isPayByLink ? (
        renderResponse()
      ) : isPayByLink ? (
        <>
          <p>{t('CHECKOUT.CONFIRMATION.PAY_BY_LINK.ORDER_CREATED_SUCCESSFULLY')}</p>
          <p>
            {t('CHECKOUT.CONFIRMATION.PAY_BY_LINK.ORDER_NUMBER')}: <strong>{state.orderId}</strong>
          </p>
          {isPayByLink && <Markdown>{t('CHECKOUT.CONFIRMATION.PAY_BY_LINK.DISCLAIMER')}</Markdown>}

          <Markdown>{t('CHECKOUT.CONFIRMATION.PAY_BY_LINK.CONFIRMATION_MYKINEPOLIS')}</Markdown>
        </>
      ) : (
        <>
          <p>{t('CHECKOUT.CONFIRMATION.SUCCESSFUL.ORDER_PROCESSED_SUCCESSFULLY')}</p>
          <p>
            {t('CHECKOUT.CONFIRMATION.SUCCESSFUL.ORDER_NUMBER')}: <strong>{state.orderId}</strong>
          </p>

          <Markdown>{t('CHECKOUT.CONFIRMATION.SUCCESSFUL.CONFIRMATION_EMAIL')}</Markdown>
        </>
      )}

      <div className="actions actions--stacked">
        <Button href="/" theme="light-transparent">
          {t('SHARED.BUTTONS.BACK_TO_SHOP')}
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;

import { FC } from 'react';

import { Button, Icon } from '@shared';
import { formatPrice } from '@utils/formatHelpers';
import { useTranslation } from 'react-i18next';
import { TUnusedDiscount } from 'shoppingCart/_models';

import { useCustomerContext } from '../_context/CustomerContext';
import { useRemoveDiscount } from '../_queries';

const unusedDiscountCodes: FC = () => {
  const { t } = useTranslation();
  const { mutate: removeDiscount } = useRemoveDiscount();
  const { cart, isLoading } = useCustomerContext();

  if (isLoading) return null;

  const { unusedDiscountCodes = [] } = cart;

  const getUnusedDiscountCodeMessage = (unusedDiscountCode: TUnusedDiscount) => {
    if (unusedDiscountCode.amountNotReachedDigitalProducts && unusedDiscountCode.amountNotReachedPhysicalProducts)
      return t('CART.DISCOUNTS.DISCOUNTCODE_LOW', {
        amount: formatPrice(unusedDiscountCode.amount),
        code: unusedDiscountCode.code,
      });

    if (unusedDiscountCode.amountNotReachedDigitalProducts)
      return t('CART.DISCOUNTS.DISCOUNTCODE_LOW_DIGITAL', {
        amount: formatPrice(unusedDiscountCode.amount),
        code: unusedDiscountCode.code,
      });

    if (unusedDiscountCode.amountNotReachedPhysicalProducts)
      return t('CART.DISCOUNTS.DISCOUNTCODE_LOW_PHYSICAL', {
        amount: formatPrice(unusedDiscountCode.amount),
        code: unusedDiscountCode.code,
      });

    if (unusedDiscountCode.exceededMaxTimesUsed)
      return t('CART.DISCOUNTS.DISCOUNTCODE_EXHAUSTED', {
        code: unusedDiscountCode.code,
      });

    if (unusedDiscountCode.combinedError)
      return t('CART.DISCOUNTS.DISCOUNTCODE_COMBINATION', {
        code: unusedDiscountCode.code,
      });

    if (unusedDiscountCode.isExpired)
      return t('CART.DISCOUNTS.DISCOUNTCODE_EXPIRED', {
        code: unusedDiscountCode.code,
      });

    return undefined;
  };

  return unusedDiscountCodes.length > 0 ? (
    <div aria-live="polite" className="shopping-cart-overview__unsatisfied-coupon">
      <Icon name="FilledAlert" />
      <ul>
        {unusedDiscountCodes.map(unusedDiscountCode => (
          <li key={unusedDiscountCode.discountId}>
            <span>{getUnusedDiscountCodeMessage(unusedDiscountCode)}</span>
            <Button
              icon="Trash"
              onClick={() => {
                removeDiscount(unusedDiscountCode.discountId);
              }}
              theme="light-transparent"
            >
              {t('SHARED.BUTTONS.DELETE')}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default unusedDiscountCodes;

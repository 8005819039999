import { useStoreType } from '@hooks';

import { useCompanyContext } from '../../company/_context/CompanyContext';
import { TCompanyDetails } from '../../company/_models';
import { TContactPerson, TCustomer } from '../../general/_models/customer';
import { useCustomerContext } from '../../shoppingCart/_context/CustomerContext';
import { useCheckoutContext } from '../_context/CheckoutContext';
import { TDigitalAddress, TPhysicalAddress } from '../_models/address';
import { TInvoiceDetails } from '../_models/invoice';

type TUseCheckoutUser = {
  customer: TCustomer | TContactPerson;
  digitalShippingAddresses: TDigitalAddress[];
  invoiceDetails: TInvoiceDetails;
  physicalShippingAddresses: TPhysicalAddress[];
  selectedCompany: TCompanyDetails;
};

const useCheckoutUser = (): TUseCheckoutUser => {
  const { isB2B } = useStoreType();
  const { customer, isGuest } = useCustomerContext();
  const { selectedCompany } = useCompanyContext();
  const { state } = useCheckoutContext();

  return {
    customer,
    digitalShippingAddresses: isGuest ? [state.digitalAddress] : customer?.digitalAddresses,
    invoiceDetails: isGuest
      ? state.invoiceDetails
      : isB2B
      ? selectedCompany?.invoiceDetails
      : (customer as TCustomer)?.invoiceDetails,
    physicalShippingAddresses: isGuest ? [state.physicalAddress] : customer?.physicalAddresses,
    selectedCompany,
  };
};

export default useCheckoutUser;
